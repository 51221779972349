import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, message, Select, Skeleton } from 'antd'
import ReactQuill from 'react-quill'

import DefaultCard from '../../ui/cards/DefaultCard'

import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'
import styles from '../listings/properties/form/property-image.module.scss'
import { RiAddFill } from 'react-icons/ri'

const MODULES = {
  toolbar: [
    [{ header: [2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
}

const FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]

const ArticleForm = ({
  visible,
  setVisibility,
  article,
  reloadData = (f) => f,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [fieldsData, setFieldsData] = useState({
    companies: [],
    categories: [],
    websites: [],
  })
  const [fieldsDataLoading, setFieldsDataLoading] = useState(true)

  useEffect(() => {
    setFieldsDataLoading(true)
    if (article && article.get_poster) {
      setImage(article.get_poster)
    }
    axiosRequest('api/v1/property-field-data', 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          const { websites, categories, companies } = res.data
          setFieldsData({ categories, companies, websites })
          setFieldsDataLoading(false)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }, [article])

  const onFilesUpload = (e) => {
    message.loading({
      content: 'Please, wait...',
      key: 'uploadImages',
      duration: 60,
    })
    if (e.target.files) {
      Array.from(e.target.files).forEach((file) => {
        const isJpgOrPng =
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/webp'
        if (!isJpgOrPng) {
          message.warn({
            content: 'You can only upload JPG/PNG files',
            key: 'uploadImages',
          })
          return
        }
        // TODO max 20 MB
        const isLt2M = file.size / 1024 / 1024 < 20
        if (!isLt2M) {
          message.warn({
            content: 'Image must be smaller than 20MB!',
            key: 'uploadImages',
          })
          return
        }
        const formData = new FormData()
        formData.append('file', file)
        axiosRequest('api/v1/upload/image', 'post', formData, true)
          .then((res) => {
            if (res.data && res.data.success) {
              setImage(res.data.file)
              message.success({ content: 'Done', key: 'uploadImages' })
            }
          })
          .catch(() => {
            message.error({
              content:
                'Image not uploaded. Try again or contact customers support.',
              key: 'uploadImages',
            })
          })
      })
    }
    e.target.value = ''
  }

  const handleFormSubmit = (values) => {
    if (!image || !image.id) {
      message.error('Main article image is missing')
      return null
    }
    if (!values.post) {
      message.error('Article body is missing')
      return null
    }
    setLoading(true)
    let url = 'api/v1/articles'
    let method = 'post'
    if (article && article.slug) {
      url = `api/v1/articles/${article.slug}`
      method = 'patch'
    }
    axiosRequest(url, method, { ...values, postPoster: image.id }, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
          form.resetFields()
          setImage(null)
          setVisibility()
        }
      })
      .catch((e) => {
        reloadData()
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Drawer
        title="Property Form"
        visible={visible}
        destroyOnClose
        onClose={() => {
          setVisibility()
          form.resetFields()
          setImage(null)
        }}
        className="property-form-drawer"
      >
        {fieldsDataLoading ? (
          <Skeleton active />
        ) : (
          <div className="default-form">
            <Form
              initialValues={article}
              layout="vertical"
              requiredMark={false}
              onFinish={handleFormSubmit}
              form={form}
            >
              <div className="row">
                <div className="col-12 col-lg-8">
                  <DefaultCard title="Article">
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="post"
                      label="Post Body"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <ReactQuill
                        theme="snow"
                        formats={FORMATS}
                        modules={MODULES}
                      />
                    </Form.Item>
                    <Form.Item
                      name="postPreview"
                      label="Post Preview"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <Input.TextArea
                        rows={6}
                        cols={6}
                        style={{ minHeight: '150px' }}
                        placeholder="Post preview text. Will be visible in posts list pages"
                      />
                    </Form.Item>
                  </DefaultCard>
                </div>
                <div className="col-12 col-lg-4">
                  <DefaultCard title="Additional">
                    <Form.Item
                      name="website"
                      label="Website"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <Select allowClear showSearch optionFilterProp="children">
                        {fieldsData.websites.map((website) => (
                          <Select.Option
                            key={`website_${website.id}`}
                            value={website.id}
                          >
                            {website.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="isPublished"
                      label="Status"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Published</Select.Option>
                        <Select.Option value={false}>Draft</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="seoDescription"
                      label="SEO Description"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Up to 250 characters. It's required for Google Search"
                        rows={6}
                        cols={6}
                        style={{ minHeight: '150px' }}
                      />
                    </Form.Item>
                    <Form.Item label="Post Main Image">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                          <div className={styles.uploaderWrapper}>
                            <input
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={(e) => onFilesUpload(e)}
                            />
                            <div className={styles.uploader}>
                              <div className={styles.icon}>
                                <RiAddFill />
                              </div>
                              <div className={styles.placeholder}>
                                Drag or select an image
                              </div>
                            </div>
                          </div>
                        </div>
                        {image && (
                          <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className={styles.image}>
                              <img src={image.medium} alt={image.name} />
                            </div>
                          </div>
                        )}
                      </div>
                    </Form.Item>
                  </DefaultCard>
                  <DefaultCard className="mt-4 text-end">
                    <Button
                      className="btn-primary btn-primary__simple me-4"
                      onClick={(e) => {
                        e.preventDefault()
                        setVisibility()
                        form.resetFields()
                        setImage(null)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={loading}
                      htmlType="submit"
                      className="btn-primary"
                    >
                      Publish Article
                    </Button>
                  </DefaultCard>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Drawer>
    </>
  )
}

export default ArticleForm
