import React from 'react'
import { Helmet } from 'react-helmet-async'

import DefaultCard from '../components/ui/cards/DefaultCard'
import PageTitle from '../components/ui/title/PageTitle'

const DashboardPage = () => {
  return (
    <>
      <Helmet title="Dashboard" />
      <PageTitle title="Dashboard" />
      <DefaultCard>
        <p className="mb-0">Dashboard under development...</p>
      </DefaultCard>
    </>
  )
}

export default DashboardPage
