import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Modal } from 'antd'

import FormBuilder from '../../../ui/forms/FormBuilder'

import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'

import US_STATES from '../../../../data/us-stated'

const ClientForm = ({ initialData, visibility, setVisibility, reloadData }) => {
  const [loading, setLoading] = useState(false)
  const [categoriesLoading, setCategoriesLoading] = useState(true)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    if (visibility) {
      setCategoriesLoading(true)
      axiosRequest('api/v1/listings/categories', 'get', null, true)
        .then((res) => {
          const options = []
          _.forEach(res.data.categories, (category) => {
            return options.push({ value: category.id, label: category.name })
          })
          setCategories(options)
        })
        .catch((e) => axiosErrorHelper(e))
        .finally(() => setCategoriesLoading(false))
    }
  }, [visibility])

  const handleFormSubmit = (values) => {
    const data = { customer: {}, categories: values.categories }
    data.customer.fullName = values.fullName
    data.customer.email = values.email
    data.customer.phone = values.phone
    data.customer.phone2 = values.phone2
    data.customer.isWholesaler = values.isWholesaler
    data.customer.state = values.state ? values.state.toString() : null
    data.customer.city = values.city
    let url = 'api/v1/crm/customers'
    let method = 'post'
    if (initialData && initialData.id) {
      url = `api/v1/crm/customers/${initialData.id}`
      method = 'patch'
    }
    setLoading(true)
    axiosRequest(url, method, data, true)
      .then(() => {
        reloadData()
        setVisibility({ visible: false, data: {} })
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      title="Customers Form"
      visible={visibility}
      onCancel={() => setVisibility({ visible: false, data: {} })}
      destroyOnClose
      footer={false}
    >
      <FormBuilder
        loading={loading}
        onFinish={handleFormSubmit}
        formProps={{
          layout: 'vertical',
          requiredMarks: false,
          initialValues: initialData,
        }}
        fields={[
          {
            label: 'Name',
            name: 'fullName',
            required: true,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Customer name',
            className: 'col-12',
          },
          {
            label: 'Email',
            name: 'email',
            required: true,
            fieldType: 'input',
            type: 'email',
            placeholder: 'Customer email',
            className: 'col-12',
          },
          {
            label: 'Phone',
            name: 'phone',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Customer phone',
            className: 'col-12',
          },
          {
            label: 'Additional Phone',
            name: 'phone2',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Additional phone',
            className: 'col-12',
          },
          {
            label: 'Wholesaler',
            name: 'isWholesaler',
            required: true,
            fieldType: 'select',
            type: 'text',
            select: {
              isMultiple: false,
              loading: false,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
            },
            placeholder: 'Customer Wholesaler?',
            className: 'col-12',
          },
          {
            label: 'Categories',
            name: 'categories',
            required: false,
            fieldType: 'select',
            type: 'text',
            select: {
              isMultiple: true,
              loading: categoriesLoading,
              options: categories,
            },
            placeholder: 'Choose customer categories',
            className: 'col-12',
          },
          {
            label: 'State',
            name: 'state',
            required: false,
            fieldType: 'select',
            type: 'text',
            select: {
              isMultiple: true,
              loading: categoriesLoading,
              options: _.map(US_STATES, (state) => {
                return { value: state.name, label: state.name }
              }),
            },
            placeholder: 'Choose customer categories',
            className: 'col-12',
          },
          {
            label: 'City',
            name: 'city',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: '',
            className: 'col-12',
          },
        ]}
      />
    </Modal>
  )
}

export default ClientForm
