import DashboardPage from '../pages/Dashboard'
import CompaniesPage from '../pages/Companies'
import WebsitesPage from '../pages/Websites'
import ActiveSkillPage from '../pages/companies/ActiveSkill'
import UsersPage from '../pages/Users'
import CategoriesPage from '../pages/listings/Categories'
import PropertiesPage from '../pages/listings/Properties'
import MailingsListPage from '../pages/mailings/MailingsList'
import CreateUpdateMailing from '../pages/mailings/CreateUpdateMailing'
import ArticlesPage from '../pages/articles'

const appRoutes = [
  {
    path: '/',
    Component: DashboardPage,
  },
  {
    path: '/companies',
    Component: CompaniesPage,
  },
  {
    path: '/categories',
    Component: CategoriesPage,
  },
  {
    path: '/properties/:slug',
    Component: PropertiesPage,
  },
  {
    path: '/websites',
    Component: WebsitesPage,
  },
  {
    path: '/articles',
    Component: ArticlesPage,
  },
  {
    path: '/companies/activeskill',
    Component: ActiveSkillPage,
  },
  {
    path: '/users/:slug',
    Component: UsersPage,
  },
  {
    path: '/mailings',
    Component: MailingsListPage,
  },
  {
    path: '/mailings/:slug',
    Component: CreateUpdateMailing,
  },
]

export default appRoutes
