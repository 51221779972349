import React from 'react'

import styles from './default-card.module.scss'

const DefaultCard = ({ children, title = null, subTitle = null, className = '', titleData = null }) => {
  return (
    <div className={`${styles.card} ${className}`}>
      {title && (
        <div className={styles.cardHeader}>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div className={`${styles.cardTitle} ms-0`}>
              <h3>{title}</h3>
              {subTitle && <h5>{subTitle}</h5>}
            </div>
            {titleData && <div>{titleData}</div>}
          </div>
        </div>
      )}
      <div className={styles.cardContent}>{children}</div>
    </div>
  )
}

export default DefaultCard
