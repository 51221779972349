const leadSources = [
  'Craigslist',
  'OfferUp',
  'Facebook',
  'Referral',
  'Website',
  'ActiveSkill',
  'LandCentury',
  'Sign',
  'Other',
]

export default leadSources
