import React from 'react'

import priceFormat from '../../../../../../helpers/proce-format'

import styles from './styles.module.scss'

const Details = ({ property }) => {
  const renderMissingLabel = () => {
    return <span className="danger-color">Missing</span>
  }

  return (
    <div className={styles.details}>
      {/*<div className={styles.head}>*/}
      {/*  <h1>*/}
      {/*    {property.name}*/}
      {/*    {property.propertyNumber && <sup className="ms-5">{property.propertyNumber}</sup>}*/}
      {/*    {property.isPublished && (*/}
      {/*      <sup className={`ms-3 ${property.isPublished ? 'success-color' : 'warning-color'}`}>*/}
      {/*        {property.isPublished ? 'Published' : 'Not Published'}*/}
      {/*      </sup>*/}
      {/*    )}*/}
      {/*    {property.featured && <sup className="ms-3 success-color">Featured</sup>}*/}
      {/*    {property.showcase && <sup className="ms-3 primary-color">Showcase</sup>}*/}
      {/*    {property.isSold && <sup className="ms-3 success-color">Sold</sup>}*/}
      {/*  </h1>*/}
      {/*</div>*/}
      <div className={styles.propertyFields}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-4">
              <div className={styles.label}>Company</div>
              <div className={styles.value}>{property.company ? property.company.name : renderMissingLabel()}</div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Categories</div>
              <div className={styles.value}>
                {property.categories && property.categories.length > 0 ? (
                  <p className="span-coma">
                    {property.categories.map((category) => (
                      <span key={category.id}>{category.name}</span>
                    ))}
                  </p>
                ) : (
                  renderMissingLabel()
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Websites</div>
              <div className={styles.value}>
                {property.websites && property.websites.length > 0 ? (
                  <p className="span-coma">
                    {property.websites.map((website) => (
                      <span key={website.id}>{website.name}</span>
                    ))}
                  </p>
                ) : (
                  renderMissingLabel()
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Address</div>
              <div className={styles.value}>
                {property.country && property.stateRegion && property.city && property.street && property.zip ? (
                  <span>
                    {property.street}, {property.city}, {property.county + ', ' || ''} {property.stateRegion},{' '}
                    {property.country}, {property.zip}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>GPS</div>
              <div className={styles.value}>
                {property.lat && property.lng ? (
                  <>
                    Lat: {property.lat} Lng: {property.lng}
                  </>
                ) : (
                  renderMissingLabel()
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Parcel Number</div>
              <div className={styles.value}>{property.parcelNumber || renderMissingLabel()}</div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Size</div>
              <div className={styles.value}>
                {property.info && property.info.sizeAcres && property.info.sizeSqFt ? (
                  <span>
                    {property.info.sizeAcres} Acres <br /> {property.info.sizeSqFt} SqFt
                  </span>
                ) : (
                  renderMissingLabel()
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Price</div>
              <div className={styles.value}>{property.price ? priceFormat(property.price) : renderMissingLabel()}</div>
            </div>
            {property.isOwnerFinance && (
              <div className="mb-4">
                <div className={styles.label}>Owner Finance Price</div>
                <div className={styles.value}>
                  {property.ownerFinancePrice ? priceFormat(property.ownerFinancePrice) : renderMissingLabel()}
                </div>
              </div>
            )}
            <div className="mb-4">
              <div className={styles.label}>Taxes</div>
              <div className={styles.value}>
                {property.info && property.info.taxes ? property.info.taxes : renderMissingLabel()}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-4">
              <div className={styles.label}>Zoning</div>
              <div className={styles.value}>
                {property.info && property.info.zoning ? property.info.zoning : renderMissingLabel()}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Utilities</div>
              <div className={styles.value}>
                {property.info && property.info.utilities ? property.info.utilities : renderMissingLabel()}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Deed Type</div>
              <div className={styles.value}>
                {property.info && property.info.deedType ? property.info.deedType : renderMissingLabel()}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Road Access</div>
              <div className={styles.value}>
                {property.info && property.info.roadAccess ? property.info.roadAccess : renderMissingLabel()}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Legal</div>
              <div className={styles.value}>
                {property.info && property.info.legal ? property.info.legal : renderMissingLabel()}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>HOA</div>
              <div className={styles.value}>
                {property.info && property.info.hoa ? property.info.hoa : renderMissingLabel()}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Mobile Homes Allowed</div>
              <div className={styles.value}>
                {property.info && property.info.mobilesHomesAllowed
                  ? property.info.mobilesHomesAllowed
                  : renderMissingLabel()}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Tax Collector Office</div>
              <div className={styles.value}>
                {property.info && property.info.taxCollectorOffice ? (
                  <a href={property.info.taxCollectorOffice} target="_blank" rel="noreferrer nofollow noopener">
                    {property.info.taxCollectorOffice}
                  </a>
                ) : (
                  renderMissingLabel()
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className={styles.label}>Property Appraiser Office</div>
              <div className={styles.value}>
                {property.info && property.info.propertyAppraiserOffice ? (
                  <a href={property.info.propertyAppraiserOffice} target="_blank" rel="noreferrer nofollow noopener">
                    {property.info.propertyAppraiserOffice}
                  </a>
                ) : (
                  renderMissingLabel()
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-0">
              <div className={styles.label}>Description</div>
              <div className={styles.value}>
                {property.description ? (
                  <div dangerouslySetInnerHTML={{ __html: property.description }} />
                ) : (
                  renderMissingLabel()
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
