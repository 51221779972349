import React, { useEffect, useState } from 'react'
import { Modal, TreeSelect } from 'antd'

import FormBuilder from '../../../ui/forms/FormBuilder'
import ImageUpload from '../../../ui/uploads/ImageUpload'

import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'

const CategoryForm = ({ initialData, categoriesData, expandedCategoriesData, visible, setVisibility, reloadData }) => {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)

  useEffect(() => {
    setImage(null)
    if (initialData && initialData.slug && initialData.image && initialData.image.id) {
      setImage(initialData.image)
    }
  }, [initialData])

  const handleFormSubmit = (values) => {
    const categoryData = values
    if (!categoryData.parent) {
      categoryData.parent = null
    }
    if (image && image.id) {
      categoryData.image = image.id
    } else {
      categoryData.image = null
    }
    setLoading(true)
    let url = 'api/v1/listings/categories'
    let method = 'post'
    if (initialData && initialData.slug) {
      url = `api/v1/listings/categories/${initialData.slug}`
      method = 'patch'
    }
    axiosRequest(url, method, categoryData, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
          setVisibility(false, {})
          setImage(null)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const setCategoryImage = (newImage) => {
    setImage(newImage)
  }

  return (
    <Modal
      title="Category Form"
      visible={visible}
      onCancel={() => {
        setImage(null)
        setVisibility(false, {})
      }}
      destroyOnClose
      footer={false}
    >
      <FormBuilder
        onFinish={handleFormSubmit}
        loading={loading}
        formProps={{
          layout: 'vertical',
          requiredMarks: false,
          initialValues: initialData,
        }}
        fields={[
          {
            label: 'Name',
            name: 'name',
            required: true,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Category name',
            className: 'col-12',
          },
          {
            label: 'Parent Category',
            name: 'parent',
            required: false,
            fieldType: 'render',
            type: 'text',
            placeholder: 'Category parent',
            className: 'col-12',
            render: () => (
              <TreeSelect
                allowClear
                treeData={categoriesData}
                treeLine
                treeCheckable={false}
                treeDefaultExpandAll
                treeNodeFilterProp="key"
                treeNodeLabelProp="title"
              />
            ),
          },
          {
            label: 'Description',
            name: 'description',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Category description',
            className: 'col-12',
          },
          {
            label: 'SEO Title',
            name: 'seoTitle',
            required: false,
            fieldType: 'textarea',
            type: 'text',
            placeholder: 'Category seo title',
            className: 'col-12',
          },
          {
            label: 'SEO Description',
            name: 'seoDescription',
            required: false,
            fieldType: 'textarea',
            type: 'text',
            placeholder: 'Category seo description',
            className: 'col-12',
          },
        ]}
      >
        <ImageUpload images={image ? [image] : []} setImages={setCategoryImage} isMultiple={false} imageClassName="col-12" />
      </FormBuilder>
    </Modal>
  )
}

export default CategoryForm
