import React from 'react'

import styles from './styles.module.scss'

const Customer = ({ lead }) => {
  console.log(lead)
  return (
    <div className={styles.leadDetailsBlock}>
      <div className={styles.blockWrapper}>
        <h3>Customer Information</h3>
        <div className="row">
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Full Name</p>
            <p className={styles.value}>{lead.customer && lead.customer.fullName}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Email</p>
            <p className={styles.value}>{lead.customer && lead.customer.email ? lead.customer.email : 'No email'}</p>
          </div>
          <div className="col-12 mb-4">
            <p className={styles.label}>Phone</p>
            <p className={styles.value}>{lead.customer && lead.customer.phone ? lead.customer.phone : 'No phone'}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Lead Source</p>
            <p className={styles.value}>{lead.leadSource || ''}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Website</p>
            <p className={styles.value}>
              {lead.website ? (
                <a
                  href={`${lead.website.domain}/p/${
                    lead.property && lead.property.propertyNumber ? lead.property.propertyNumber : 'none'
                  }`}
                  target="_blank"
                  rel="noreferrer nofollow noopener"
                >
                  {lead.website.name}
                </a>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-12">
            <p className={styles.label}>Message</p>
            <p className={styles.value}>{lead.customerMessage || '-'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customer
