import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Pagination, Popconfirm, Skeleton } from 'antd'
import { RiAddFill, RiDeleteBin3Fill, RiEditFill } from 'react-icons/ri'

import PageTitle from '../../components/ui/title/PageTitle'

import checkPermission from '../../helpers/check-permission'
import ArticleForm from '../../components/pages/articles/ArticleForm'
import axiosRequest from '../../helpers/axios-request.helper'
import convertJsonToUrlParams from '../../helpers/convert-json-to-url-params'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import moment from 'moment/moment'
import Table from '../../components/ui/table/Table'
import DefaultCard from '../../components/ui/cards/DefaultCard'

const mapStateToProps = ({ users }) => ({ users })

const ArticlesPage = ({ users }) => {
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({ data: [], total: 0 })
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState({ page: 1, perPage: 20 })
  const [articleForm, setArticleForm] = useState({
    visible: false,
    initialData: {},
  })

  const getData = () => {
    setLoading(true)
    axiosRequest(
      `api/v1/articles${convertJsonToUrlParams(filters)}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        setData({ total: res.data.posts.total, data: res.data.posts.data })
      })
      .catch((e) => axiosErrorHelper(e))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleDelete = (slug) => {
    axiosRequest(`api/v1/articles/${slug}`, 'delete', null, true)
      .then(() => getData())
      .catch((e) => axiosErrorHelper(e))
  }

  return (
    <>
      <Helmet title="Articles" />
      <PageTitle
        title={`Articles (${data.total})`}
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/articles', name: 'Articles' },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'blog:create') && (
              <Button
                icon={<RiAddFill />}
                className="btn-primary fw-500"
                onClick={() =>
                  setArticleForm({
                    visible: true,
                    initialData: { isPublished: true },
                  })
                }
              >
                Add New Article
              </Button>
            )}
          </>
        }
      />
      <DefaultCard>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Table
              columns={[
                {
                  key: 'name',
                  name: 'Name',
                  headClassName: null,
                  itemClassName: null,
                  render: (item) => (
                    <>
                      <p className="mb-0">
                        <strong>{item.title}</strong>
                      </p>
                    </>
                  ),
                },
                {
                  key: 'website',
                  name: 'Website',
                  headClassName: null,
                  itemClassName: null,
                  render: (item) => (
                    <a
                      href={`${item.get_website.domain}/blog/${item.slug}`}
                      target="_blank"
                      rel="noreferrer nofollow noopener"
                    >
                      {item.get_website.name}
                    </a>
                  ),
                },
                {
                  key: 'isPublished',
                  name: 'Status',
                  headClassName: null,
                  itemClassName: null,
                  render: (item) => (
                    <>
                      {item.isPublished ? (
                        <strong className="success-color">Published</strong>
                      ) : (
                        <strong className="danger-color">Draft</strong>
                      )}
                    </>
                  ),
                },
                {
                  key: 'date',
                  name: 'Date',
                  headClassName: null,
                  itemClassName: null,
                  render: (item) => (
                    <>
                      <p className="mb-1">
                        <span>
                          <small>Created at</small>{' '}
                          {moment(item.created_at).format('YYYY-MM-DD')}
                        </span>
                      </p>
                      <p className="mb-0">
                        <span>
                          <small>Last update</small>{' '}
                          {moment(item.updated_at).format('YYYY-MM-DD')}
                        </span>
                      </p>
                    </>
                  ),
                },
                {
                  key: 'actions',
                  name: 'Actions',
                  headClassName: null,
                  itemClassName: null,
                  render: (item) => (
                    <>
                      {checkPermission(permissions, 'crm:edit') ? (
                        <Button
                          className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                          onClick={() => {
                            const lead = item
                            if (lead.depositAmount) {
                              lead.depositAmount = lead.depositAmount / 100
                            }
                            setArticleForm({ visible: true, initialData: item })
                          }}
                        >
                          <RiEditFill />
                        </Button>
                      ) : null}
                      {checkPermission(permissions, 'crm:delete') ? (
                        <Popconfirm
                          title={`Are you really want to permanently delete this article?`}
                          onConfirm={() => handleDelete(item.slug)}
                          okButtonProps={{ className: `btn-sm btn-danger` }}
                          cancelButtonProps={{ className: `btn-sm` }}
                        >
                          <Button className="me-2 btn-sm btn-danger btn-danger__simple no-svg-padding">
                            <RiDeleteBin3Fill />
                          </Button>
                        </Popconfirm>
                      ) : null}
                    </>
                  ),
                },
              ]}
              data={data.data}
            />
            {filters.perPage < data.total && (
              <Pagination
                className="mt-4"
                size="small"
                current={filters.page}
                pageSize={filters.perPage}
                total={data.total}
                showSizeChanger={false}
                onChange={(page) => setFilters({ ...filters, page })}
              />
            )}
          </>
        )}
      </DefaultCard>
      <ArticleForm
        visible={articleForm.visible}
        setVisibility={() =>
          setArticleForm({ visible: false, initialData: {} })
        }
        article={articleForm.initialData}
        reloadData={getData}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(ArticlesPage))
