import React, { useState } from 'react'
import { Button, Drawer, Form, Input, Select } from 'antd'

import leadSources from '../../../../data/lead-sources'

const LeadsFilters = ({ visible, setVisibility, filters, setFilters, statuses }) => {
  const [form] = Form.useForm()
  const [leadFilters, setLeadFilters] = useState({})

  const handleFilters = () => {
    form.setFieldsValue({ ...leadFilters })
    setFilters({ ...filters, ...leadFilters })
  }

  const clearFilters = () => {
    clearFields()
    setFilters({ page: filters.page, perPage: filters.perPage })
    setLeadFilters({})
  }

  const clearFields = () => {
    form.setFieldsValue({ lead: null, property: null, manager: null, customer: null, status: null, leadStatus: null })
  }

  return (
    <Drawer
      title="Filters"
      visible={visible}
      destroyOnClose
      width={370}
      onClose={() => {
        setVisibility(false)
      }}
      className="property-form-drawer drawer-width-400"
      footer={
        <>
          <div className="row">
            <div className="col-6">
              <Button className="w-100" onClick={() => clearFilters()}>
                Clear
              </Button>
            </div>
            <div className="col-6">
              <Button className="btn-primary w-100" onClick={() => handleFilters()}>
                Submit
              </Button>
            </div>
          </div>
        </>
      }
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="default-form"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleFilters()
          }
        }}
      >
        <Form layout="vertical" requiredMark={false} initialValues={filters} form={form}>
          <Form.Item name="lead" label="Find by lead name">
            <Input
              allowClear
              placeholder="Search by lead name or description..."
              onChange={(e) => setLeadFilters({ ...leadFilters, lead: e.target.value })}
            />
          </Form.Item>
          <Form.Item name="property" label="Find by property">
            <Input
              allowClear
              placeholder="Search by property..."
              onChange={(e) => setLeadFilters({ ...leadFilters, property: e.target.value })}
            />
          </Form.Item>
          <Form.Item name="customer" label="Find by customer">
            <Input
              allowClear
              placeholder="Search by customer name, email, phone..."
              onChange={(e) => setLeadFilters({ ...leadFilters, customer: e.target.value })}
            />
          </Form.Item>
          <Form.Item name="manager" label="Find by manager">
            <Input
              allowClear
              placeholder="Search by manager..."
              onChange={(e) => setLeadFilters({ ...leadFilters, manager: e.target.value })}
            />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Find leads by their status..."
              onChange={(val) => setLeadFilters({ ...leadFilters, status: val })}
            >
              {statuses.map((status) => (
                <Select.Option key={status.value} value={status.value}>
                  {status.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="leadStatus" label="State">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Filters leads by their state (open, closed)..."
              onChange={(val) => setLeadFilters({ ...leadFilters, leadStatus: val })}
            >
              <Select.Option value="open">Open</Select.Option>
              <Select.Option value="closed">Closed</Select.Option>
              <Select.Option value="won">Won</Select.Option>
              <Select.Option value="lost">Lost</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="leadSource" label="Lead Source">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Filters leads by their state (open, closed)..."
              onChange={(val) => setLeadFilters({ ...leadFilters, leadSource: val })}
            >
              {leadSources.map((source) => (
                <Select.Option key={source} value={source}>
                  {source}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  )
}

export default LeadsFilters
