import React, { useEffect, useState } from 'react'
import { Drawer, Skeleton } from 'antd'

import Details from './property-details/Details'
import DefaultCard from '../../../../ui/cards/DefaultCard'
import Notes from './property-details/Notes'
import Images from './property-details/Images'
import LeadsTable from '../../../crm/leads/LeadsTable'

import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

import styles from './property-details/styles.module.scss'
import PropertiesFiles from './property-details/Files'

const PropertyDetails = ({ users, slug = null, visible, setVisibility }) => {
  const { permissions, user } = users
  const [title, setTitle] = useState('Loading...')
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  const getData = () => {
    if (slug) {
      setLoading(true)
      axiosRequest(`api/v1/listings/properties/${slug}`, 'get', null, true)
        .then((res) => {
          if (res.data && res.data.success) {
            setData(res.data.property)
            setTitle(`${res.data.property.name} | ${res.data.property.propertyNumber || ''}`)
            setLoading(false)
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  return (
    <Drawer
      title={title}
      visible={visible}
      destroyOnClose
      onClose={() => {
        setVisibility({ visible: false, slug: null })
      }}
      className="property-form-drawer"
    >
      {loading || !data ? (
        <div className="p-5">
          <Skeleton active />
        </div>
      ) : (
        <div className={styles.propertyDetails}>
          <div className="row">
            <div className="col-12 col-lg-4 mb-4">
              <DefaultCard title="Property Details" className="h-100">
                <Details property={data} />
              </DefaultCard>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <DefaultCard title="Images" className="mb-4">
                <Images images={data.images} />
              </DefaultCard>
              <div className="mb-4">
                <PropertiesFiles reloadData={getData} files={data.files || []} propertyId={data.id} uploadType="property" />
              </div>
              <DefaultCard
                title="Map View"
                className="mb-4"
                titleData={
                  <a
                    href={`http://maps.google.com/maps?daddr=${encodeURIComponent(`${data.lat},${data.lng}`)}`}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    Google Maps
                  </a>
                }
              >
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.lat},${data.lng}&markers=color:blue|label:S|${data.lat},${data.lng}&maptype=roadmap&key=AIzaSyD6LR9HaDBmbyl4U01555d5sMU-pTrWLeI&size=800x300&scale=2&zoom=12`}
                  alt=""
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </DefaultCard>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <Notes user={user} notes={data.notes || []} propertyId={data.id} reloadData={getData} />
            </div>
            {data.leads && data.leads.length > 0 && (
              <div className="col-12 mb-4">
                <DefaultCard title="Leads">
                  <LeadsTable
                    leads={data.leads}
                    reloadData={getData}
                    user={user}
                    allowDelete={false}
                    allowEdit={false}
                    permissions={permissions}
                  />
                </DefaultCard>
              </div>
            )}
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default PropertyDetails
