import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Modal, Tabs } from 'antd'

import FormBuilder from '../../ui/forms/FormBuilder'
import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'

const WebsiteForm = ({ initialData, activeSkillInitialData = {}, title = null, visible, setVisibility, reloadData }) => {
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState({ loading: false, companies: [] })

  useEffect(() => {
    setCompanies({ ...companies, loading: true })
    axiosRequest(`api/v1/companies?page=1&perPage=1000&skipPermissionsCheck=1`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          const companiesArr = _.map(res.data.companies.data, (company) => {
            return { label: company.name, value: company.id }
          })
          setCompanies({ companies: companiesArr, loading: false })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])

  const handleFormSubmit = (values) => {
    setLoading(true)
    let url = 'api/v1/websites'
    let method = 'post'
    if (initialData && initialData.id) {
      url = `api/v1/websites/${initialData.id}`
      method = 'patch'
    }
    axiosRequest(url, method, values, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
          setVisibility(false, {})
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleActiveSkillFormSubmit = (values) => {
    if (initialData && initialData.id) {
      setLoading(true)
      axiosRequest(`api/v1/websites/active-skill/${initialData.id}`, 'post', { ...values }, true)
        .then((res) => {
          if (res.data && res.data.success) {
            reloadData()
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Modal
      title={title || 'Websites Form'}
      visible={visible}
      onCancel={() => setVisibility(false, {})}
      destroyOnClose
      footer={false}
    >
      <Tabs>
        <Tabs.TabPane tab="Settings" key="1">
          <FormBuilder
            onFinish={handleFormSubmit}
            loading={loading}
            formProps={{
              layout: 'vertical',
              requiredMarks: false,
              initialValues: initialData,
            }}
            fields={[
              {
                label: 'Name',
                name: 'name',
                required: true,
                fieldType: 'input',
                type: 'text',
                placeholder: 'Website name',
                className: 'col-12',
              },
              {
                label: 'URL',
                name: 'domain',
                required: true,
                fieldType: 'input',
                type: 'text',
                placeholder: 'https://example.com',
                className: 'col-12',
              },
              {
                label: 'Company',
                name: 'company',
                required: true,
                fieldType: 'select',
                placeholder: 'Related company',
                className: 'col-12',
                select: {
                  isMultiple: false,
                  loading: companies.loading,
                  options: companies.companies,
                },
              },
              {
                label: 'Active?',
                name: 'isActive',
                required: true,
                fieldType: 'select',
                placeholder: 'Is website available?',
                className: 'col-12',
                select: {
                  isMultiple: false,
                  loading: false,
                  options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ],
                },
              },
            ]}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="ActiveSkill" key="2">
          <FormBuilder
            onFinish={handleActiveSkillFormSubmit}
            loading={loading}
            formProps={{
              layout: 'vertical',
              requiredMarks: false,
              initialValues: activeSkillInitialData,
            }}
            fields={[
              {
                label: 'API Key',
                name: 'apiKey',
                required: true,
                fieldType: 'input',
                type: 'text',
                placeholder: 'ActiveSkill API key',
                className: 'col-12',
              },
              {
                label: 'Sync Enabled?',
                name: 'syncEnabled',
                required: true,
                fieldType: 'select',
                placeholder: 'ActiveSkill sync enabled?',
                className: 'col-12',
                select: {
                  isMultiple: false,
                  loading: false,
                  options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ],
                },
              },
              {
                label: 'PPL Enabled?',
                name: 'pplEnabled',
                required: true,
                fieldType: 'select',
                placeholder: 'ActiveSkill PPL enabled?',
                className: 'col-12 col-md-6',
                select: {
                  isMultiple: false,
                  loading: false,
                  options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ],
                },
              },
              {
                label: 'PPC Enabled?',
                name: 'ppcEnabled',
                required: true,
                fieldType: 'select',
                placeholder: 'ActiveSkill PPC enabled?',
                className: 'col-12 col-md-6',
                select: {
                  isMultiple: false,
                  loading: false,
                  options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ],
                },
              },
            ]}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}

export default WebsiteForm
