import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Form, Input, Select, AutoComplete, notification } from 'antd'

import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'
import { history } from '../../../index'
import US_STATES from '../../../data/us-stated'

const MailingForm = ({
  slug = 'create',
  dataLoading,
  mailingData,
  formData,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [categoriesLoading, setCategoriesLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [formProperties, setFormProperties] = useState([])
  const [formCustomers, setFormCustomers] = useState([])
  const [autocompleteOptions, setAutocompleteOptions] = useState([])
  const [customersAutocompleteOptions, setCustomersAutocompleteOptions] =
    useState([])

  useEffect(() => {
    setCategoriesLoading(true)
    axiosRequest('api/v1/listings/categories', 'get', null, true)
      .then((res) => {
        const options = []
        _.forEach(res.data.categories, (category) => {
          return options.push({ value: category.id, label: category.name })
        })
        setCategories(options)
      })
      .catch((e) => axiosErrorHelper(e))
      .finally(() => setCategoriesLoading(false))
    const data = mailingData
    form.setFieldsValue({ ...data })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailingData])

  useEffect(() => {
    if (formCustomers.length === 0) {
      setFormCustomers(mailingData.emails)
    }
    setFormProperties(mailingData.properties)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailingData, formProperties])

  const onFinish = (values) => {
    let url = 'api/v1/mailings'
    let method = 'post'
    if (slug !== 'create') {
      url = `api/v1/mailings/${slug}`
      method = 'patch'
    }
    if (!formProperties || formProperties.length === 0) {
      notification.error({
        message: 'Error',
        description: 'Please, add at least one property',
      })
      return null
    }
    if (!formCustomers || formCustomers.length === 0) {
      notification.error({
        message: 'Error',
        description: 'Please, add at least one customer',
      })
      return null
    }
    setLoading(true)
    values.properties = _.map(formProperties, (v) => v.key)
    values.emails = _.map(formCustomers, (v) => {
      return { customer: v.key, email: 'no-email@email.com' }
    })
    axiosRequest(url, method, values, true)
      .then(() => {
        history.push('/mailings')
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onPropertySearch = (q) => {
    axiosRequest(
      `api/v1/listings/properties/actions/autocomplete?q=${q}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        setAutocompleteOptions(
          res.data && res.data.properties
            ? _.map(res.data.properties, (v) => {
                return { key: v.key, value: v.key, label: v.label }
              })
            : [],
        )
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const onPropertySelect = (v, l) => {
    form.resetFields(['addListing'])
    const newData = formProperties
    newData.push({ key: l.key, label: l.label })
    setFormProperties([...newData])
  }

  const onPropertyRemove = (key) => {
    let newArray = formProperties
    newArray = _.remove(newArray, (v) => v.key === key)
    setFormProperties(newArray)
  }

  const onCustomerSearch = (q) => {
    axiosRequest(
      `api/v1/crm/customers/actions/autocomplete?q=${q}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        setCustomersAutocompleteOptions(
          res.data && res.data.customers
            ? _.map(res.data.customers, (v) => {
                return { key: v.key, value: v.key, label: v.label }
              })
            : [],
        )
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const onCustomerSelect = (v, l) => {
    form.resetFields(['addCustomer'])
    const newCustomersData = formCustomers
    newCustomersData.push({ key: l.key, label: l.label })
    setFormCustomers([...newCustomersData])
  }

  const onCustomerRemove = (key) => {
    console.log(key)
    let newCustomersArray = formCustomers
    newCustomersArray = _.remove(newCustomersArray, (v) => {
      return v.key !== parseInt(key, 10)
    })
    console.log(newCustomersArray)
    setFormCustomers([...newCustomersArray])
  }

  const selectCustomerBy = (by, value) => {
    axiosRequest(
      `api/v1/crm/customers?page=1&perPage=5000&${by}=${value}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          form.resetFields([
            'customerCategory',
            'customerState',
            'customerStatus',
          ])
          let newCustomers = []
          newCustomers = _.map(res.data.data.data.data, (c) => {
            return { key: c.id, label: c.fullName }
          })
          setFormCustomers([...formCustomers, ...newCustomers])
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  return (
    <div className="default-form">
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        initialValues={mailingData}
      >
        <Form.Item
          name="name"
          label="Name/Title"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder="Input message name/title" />
        </Form.Item>
        <Form.Item
          name="website"
          label="Website"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select loading={dataLoading} placeholder="Select website">
            {formData && formData.websites
              ? formData.websites.map((website) => (
                  <Select.Option key={website.id} value={website.id}>
                    {website.name}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          name="preBody"
          label="Additional message to the client (optional)"
          rules={[{ required: false, message: 'This field is required' }]}
        >
          <Input.TextArea
            autoSize={{ minRows: 5, maxRows: 12 }}
            placeholder="Input additional message to the client"
          />
        </Form.Item>
        <div className="row mb-4">
          <Form.Item
            name="addCustomer"
            label="Add customer"
            className="col-12 col-md-6 col-lg-4 col-xl-6 mb-4 mb-md-0"
          >
            <AutoComplete
              allowClear
              options={customersAutocompleteOptions}
              onSearch={onCustomerSearch}
              placeholder="Search customer by name, email or phone..."
              onSelect={onCustomerSelect}
            />
          </Form.Item>
          <Form.Item
            name="customerCategory"
            label="Add customers by category"
            className="col-12 col-md-6 col-lg-4 col-xl-2 mb-4 mb-md-0"
          >
            <Select
              allowClear
              showSearch
              loading={categoriesLoading}
              onSelect={(v) => selectCustomerBy('category', v)}
              placeholder="Search customer by category..."
            >
              {categories &&
                categories.map((category) => (
                  <Select.Option
                    key={`customerCategory_${category.value}`}
                    value={category.value}
                  >
                    {category.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="customerState"
            label="Add customers by state"
            className="col-12 col-md-6 col-lg-4 col-xl-2 mb-4 mb-md-0"
          >
            <Select
              allowClear
              showSearch
              loading={categoriesLoading}
              onSelect={(v) => selectCustomerBy('state', v)}
              placeholder="Search customer by state..."
            >
              {US_STATES &&
                US_STATES.map((state) => (
                  <Select.Option
                    key={`customerState_${state.name}`}
                    value={state.name}
                  >
                    {state.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="customerStatus"
            label="Add customers by status"
            className="col-12 col-md-6 col-lg-4 col-xl-2 mb-4 mb-md-0"
          >
            <Select
              allowClear
              showSearch
              loading={categoriesLoading}
              onSelect={(v) => {
                if (v === 'all') {
                  selectCustomerBy('status', v)
                }
                if (v === 'wholesalers') {
                  selectCustomerBy('onlyWholesalers', v)
                }
              }}
              placeholder="Search customer by status..."
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="wholesalers">Wholesalers</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item>
          {formCustomers.map((customer, i) => (
            <div key={`customer_${customer.key}_${i}`} className="mb-2">
              {customer.label}{' '}
              <Button
                className="btn-xsm btn-danger btn-danger__light"
                onClick={() => onCustomerRemove(customer.key)}
              >
                Delete
              </Button>
            </div>
          ))}
        </Form.Item>
        <Form.Item name="addListing" label="Add listing">
          <AutoComplete
            allowClear
            options={autocompleteOptions}
            onSearch={onPropertySearch}
            placeholder="Select listing by property ID, name, city or state..."
            onSelect={onPropertySelect}
          />
        </Form.Item>
        <Form.Item>
          {formProperties.map((property) => (
            <div key={`property_${property.key}`} className="mb-2">
              {property.label}{' '}
              <Button
                className="btn-xsm btn-danger btn-danger__light"
                onClick={() => onPropertyRemove(property.key)}
              >
                Delete
              </Button>
            </div>
          ))}
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            loading={loading}
            htmlType="submit"
            className="w-100 btn-primary"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default MailingForm
