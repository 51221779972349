import React from 'react'
import { message } from 'antd'
import { RiAddFill, RiDeleteBin2Fill } from 'react-icons/ri'

import styles from './image-upload.module.scss'

import axiosRequest from '../../../helpers/axios-request.helper'
import _ from 'lodash'

const ImageUpload = ({
  images = [],
  isMultiple = false,
  setImages = (f) => f,
  imageClassName = 'col-12 col-md-6 col-lg-6',
}) => {
  const onFilesUpload = (e) => {
    message.loading({ content: 'Please, wait...', key: 'uploadImages', duration: 60 })
    if (e.target.files) {
      Array.from(e.target.files).forEach((file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'
        if (!isJpgOrPng) {
          message.warn({ content: 'You can only upload JPG/PNG files', key: 'uploadImages' })
          return
        }
        // TODO max 20 MB
        const isLt2M = file.size / 1024 / 1024 < 20
        if (!isLt2M) {
          message.warn({ content: 'Image must be smaller than 20MB!', key: 'uploadImages' })
          return
        }
        const formData = new FormData()
        formData.append('file', file)
        axiosRequest('api/v1/upload/image', 'post', formData, true)
          .then((res) => {
            if (res.data && res.data.success) {
              const newImagesArr = images
              newImagesArr.push(res.data.file)
              if (isMultiple) {
                setImages([...newImagesArr])
              } else {
                setImages(res.data.file)
              }
              message.success({ content: 'Done', key: 'uploadImages' })
            }
          })
          .catch(() => {
            message.error({ content: 'Image not uploaded. Try again or contact customers support.', key: 'uploadImages' })
          })
      })
    }
    e.target.value = ''
  }

  const handleDeleteImage = (id) => {
    if (isMultiple) {
      const newImagesArr = images
      const findIndex = _.findIndex(images, (data) => data.id === id)
      if (findIndex >= 0) {
        newImagesArr.splice(findIndex, 1)
        setImages([...newImagesArr])
      }
    } else {
      setImages(null)
    }
  }

  return (
    <div className={styles.imageUploader}>
      <div className={styles.uploaderWrapper}>
        <input type="file" accept="image/*" multiple={isMultiple} onChange={(e) => onFilesUpload(e)} />
        <div className={styles.uploader}>
          <div className={styles.icon}>
            <RiAddFill />
          </div>
          <div className={styles.placeholder}>Drag or select an image</div>
        </div>
      </div>
      <div className="row">
        {images.map((image) => (
          <div className={`${imageClassName} mb-4`} key={image.id}>
            <div className={styles.image}>
              <img src={image.medium} alt={image.name} />
              <div className={styles.controls}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={() => handleDeleteImage(image.id)} className={styles.delete}>
                  <RiDeleteBin2Fill />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImageUpload
