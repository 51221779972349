import React, { useState } from 'react'
import { Modal } from 'antd'

import FormBuilder from '../../../../../ui/forms/FormBuilder'

import axiosRequest from '../../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../../helpers/axios-error.helper'

const Notes = ({ propertyId, note = {}, visible = false, setVisibility = (f) => f, reloadData = (f) => f }) => {
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = (values) => {
    let url = 'api/v1/listings/properties/data/notes'
    let method = 'post'
    if (note && note.id) {
      url = `api/v1/listings/properties/data/notes/${note.id}`
      method = 'patch'
    }
    setLoading(true)
    axiosRequest(url, method, { ...values, property: propertyId }, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
          setVisibility({ visible: false, note: {} })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      title="Note Form"
      width={720}
      visible={visible}
      onCancel={() => setVisibility({ visible: false, note: {} })}
      destroyOnClose
      footer={false}
    >
      <FormBuilder
        onFinish={handleFormSubmit}
        loading={loading}
        formProps={{
          layout: 'vertical',
          requiredMarks: false,
          initialValues: note,
        }}
        fields={[
          {
            label: 'Note',
            name: 'note',
            required: true,
            fieldType: 'quill',
            type: 'text',
            placeholder: 'Lead note...',
            className: 'col-12',
          },
        ]}
      />
    </Modal>
  )
}

export default Notes
