import React from 'react'
import _ from 'lodash'
import { Modal, Checkbox, notification } from 'antd'
import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'

const UsersPermissionsForm = ({ visible, setVisibility, userId, initialData, reloadData }) => {
  const updatePermission = (key, permission) => {
    axiosRequest(`api/v1/users/permissions/${userId}`, 'patch', { userId, permission, permissionId: key }, true)
      .then((res) => {
        if (res.data && res.data.success) {
          notification.success({ message: 'Success', description: 'User permissions successfullly updated' })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const buildPermissionsUi = () => {
    const permissionsData = {}
    _.forEach(initialData, (permission) => {
      if (!permissionsData[permission.category]) {
        permissionsData[permission.category] = []
      }
      permissionsData[permission.category].push(permission)
    })
    return (
      <>
        {Object.entries(permissionsData).map(([key, value]) => (
          <div className="mb-3">
            <h4 className="text-capitalize text-center mb-3">{key}</h4>
            <div className="row">
              {value.map((permission) => (
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-1" key={permission.id}>
                  <Checkbox
                    defaultChecked={permission.permission}
                    onChange={(e) => {
                      updatePermission(permission.id, e.target.checked)
                    }}
                  >
                    {permission.help}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <Modal
      title="Users Permissions"
      visible={visible}
      onCancel={() => {
        reloadData()
        setVisibility(false, null, {})
      }}
      footer={false}
      destroyOnClose
      width={1024}
    >
      {buildPermissionsUi()}
    </Modal>
  )
}

export default UsersPermissionsForm
