import React from 'react'
import { Image } from 'antd'

const Images = ({ images = [] }) => {
  return (
    <div className="row">
      {images.map((image) => (
        <div key={image.id} className="col-12 col-lg-6 col-xxl-4 mb-4">
          <Image src={image.large} />
        </div>
      ))}
    </div>
  )
}

export default Images
