import React, { useState } from 'react'
import moment from 'moment'
import { RiCheckFill, RiCloseFill, RiEditFill, RiDeleteBin3Fill, RiEyeFill } from 'react-icons/ri'

import Table from '../../../ui/table/Table'
import checkPermission from '../../../../helpers/check-permission'
import { Button, Popconfirm } from 'antd'
import priceFormat from '../../../../helpers/proce-format'
import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'
import LeadDetails from './LeadDetails'

const LeadsTable = ({
  user,
  leads,
  reloadData,
  permissions,
  allowDetails = true,
  allowEdit = true,
  allowDelete = true,
  setVisibility = (f) => f,
  setFilters = (f) => f,
}) => {
  const [leadDetails, setLeadDetails] = useState({ visible: false, id: null })

  const deleteLead = (id) => {
    axiosRequest(`api/v1/crm/leads/${id}`, 'delete', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  return (
    <>
      <Table
        columns={[
          {
            key: 'name',
            name: 'Name',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                <p className="mb-1">
                  <strong>
                    {item.property ? (
                      <span>
                        {item.property.name} {item.property.propertyNumber ? <sup>{item.property.propertyNumber}</sup> : ''}
                      </span>
                    ) : (
                      item.name
                    )}{' '}
                    {item.isClosed ? (
                      <sup className={`ms-2 fw-normal ${item.isSuccess ? 'success-color' : 'danger-color'}`}>Closed</sup>
                    ) : (
                      <sup className="ms-2 fw-normal warning-color">Open</sup>
                    )}
                  </strong>
                </p>
                <p className="mb-0">
                  <small>
                    <strong className="primary-color">{item.manager ? item.manager.name : '-'}</strong>
                  </small>
                </p>
              </>
            ),
          },
          {
            key: 'leadSource',
            name: 'Source',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <Button className="btn-link" onClick={() => setFilters('property', item.property.name)}>
                {item.leadSource ? <span>{item.leadSource}</span> : '-'}
              </Button>
            ),
          },
          {
            key: 'customer',
            name: 'Customer',
            headClassName: null,
            itemClassName: null,
            render: (item) => <span>{item.customer ? item.customer.fullName : '-'}</span>,
          },
          {
            key: 'leadStatus',
            name: 'Status',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                {!item.isClosed ? (
                  <p className="mb-2 primary-color">
                    <strong>{item.status ? item.status.name : '-'}</strong>
                  </p>
                ) : (
                  <p className="mb-2">
                    {item.isClosed ? (
                      <span className={item.isSuccess ? 'success-color' : 'danger-color'}>
                        <span className="f-size-2">{item.isSuccess ? <RiCheckFill /> : <RiCloseFill />}</span>
                      </span>
                    ) : (
                      <span className="primary-color">Active</span>
                    )}
                  </p>
                )}
              </>
            ),
          },
          {
            key: 'isDeposited',
            name: 'Deposit',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                {item.isDepositSubmitted ? (
                  <strong className="success-color">{priceFormat(item.depositAmount || 0, true)}</strong>
                ) : (
                  'No'
                )}
              </>
            ),
          },
          {
            key: 'date',
            name: 'Date',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                <p className="mb-1">
                  <span>
                    <small>Created at</small> {moment(item.created_at).format('YYYY-MM-DD')}
                  </span>
                </p>
                <p className="mb-0">
                  <span>
                    <small>Last update</small> {moment(item.updated_at).format('YYYY-MM-DD')}
                  </span>
                </p>
              </>
            ),
          },
          {
            key: 'actions',
            name: 'Actions',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                {allowDetails && (
                  <Button
                    className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                    onClick={() => {
                      setLeadDetails({ visible: true, id: item.id })
                    }}
                  >
                    <RiEyeFill />
                  </Button>
                )}
                {allowEdit && checkPermission(permissions, 'crm:edit') ? (
                  <Button
                    className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                    onClick={() => {
                      const lead = item
                      if (lead.depositAmount) {
                        lead.depositAmount = lead.depositAmount / 100
                      }
                      setVisibility(true, lead)
                    }}
                  >
                    <RiEditFill />
                  </Button>
                ) : null}
                {allowDelete && checkPermission(permissions, 'crm:delete') ? (
                  <Popconfirm
                    title={`Are you really want to permanently delete this lead?`}
                    onConfirm={() => deleteLead(item.id)}
                    okButtonProps={{ className: `btn-sm btn-danger` }}
                    cancelButtonProps={{ className: `btn-sm` }}
                  >
                    <Button className="me-2 btn-sm btn-danger btn-danger__simple no-svg-padding">
                      <RiDeleteBin3Fill />
                    </Button>
                  </Popconfirm>
                ) : null}
              </>
            ),
          },
        ]}
        data={leads}
      />
      <LeadDetails user={user} visible={leadDetails.visible} setVisibility={setLeadDetails} id={leadDetails.id} />
    </>
  )
}

export default LeadsTable
