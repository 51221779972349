import React, { useState } from 'react'
import moment from 'moment'
import { Button, Popconfirm } from 'antd'
import { RiAddFill, RiDeleteBin3Fill, RiEditFill } from 'react-icons/ri'

import DefaultCard from '../../../../../ui/cards/DefaultCard'
import NoteForm from './NoteForm'

import axiosRequest from '../../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../../helpers/axios-error.helper'

import styles from '../../../../crm/leads/leads-notes/notes.module.scss'

const Notes = ({ user, propertyId, reloadData, notes = [] }) => {
  const [noteForm, setNoteForm] = useState({ visible: false, note: {} })

  const handleDeleteNote = (id) => {
    axiosRequest(`api/v1/listings/properties/data/notes/${id}`, 'delete', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  return (
    <>
      <DefaultCard
        title="Notes"
        className="h-100"
        titleData={
          <Button
            icon={<RiAddFill />}
            className="btn-sm btn-primary fw-500 me-3"
            onClick={() => {
              setNoteForm({ visible: true, note: {} })
            }}
          >
            Add Note
          </Button>
        }
      >
        <div className={styles.notes}>
          {notes.map((note) => (
            <div key={note.id} className={styles.note}>
              <div className={styles.info}>
                <div className={styles.data}>
                  <strong>{moment(note.created_at).format('DD MMM, YYYY')}</strong> by <strong>{note.user.name}</strong>
                </div>
                <div className={styles.actions}>
                  {user.id === note.user.id && (
                    <>
                      <Button
                        className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                        onClick={() => {
                          setNoteForm({ visible: true, note })
                        }}
                      >
                        <RiEditFill />
                      </Button>
                      <Popconfirm
                        title={`Are you really want to delete this note?`}
                        onConfirm={() => handleDeleteNote(note.id)}
                        okButtonProps={{ className: `btn-sm btn-danger` }}
                        cancelButtonProps={{ className: `btn-sm` }}
                      >
                        <Button className="me-2 btn-sm btn-danger btn-danger__simple no-svg-padding">
                          <RiDeleteBin3Fill />
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.noteDescription} dangerouslySetInnerHTML={{ __html: note.note }} />
            </div>
          ))}
        </div>
      </DefaultCard>
      <NoteForm
        note={noteForm.note}
        propertyId={propertyId}
        setVisibility={setNoteForm}
        visible={noteForm.visible}
        reloadData={reloadData}
      />
    </>
  )
}

export default Notes
