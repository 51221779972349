import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Button, Pagination, Popconfirm, Spin } from 'antd'
import { RiAddFill, RiCheckFill, RiCloseFill, RiEditFill, RiDeleteBin3Fill, RiRestartFill } from 'react-icons/ri'

import PageTitle from '../components/ui/title/PageTitle'
import DefaultCard from '../components/ui/cards/DefaultCard'
import Table from '../components/ui/table/Table'
import WebsiteForm from '../components/pages/websites/WebsiteForm'

import axiosRequest from '../helpers/axios-request.helper'
import axiosErrorHelper from '../helpers/axios-error.helper'
import checkPermission from '../helpers/check-permission'

const mapStateToProps = ({ users }) => ({ users })

const WebsitesPage = ({ users }) => {
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({ page: 1, perPage: 30 })
  const [data, setData] = useState({ data: [], total: 0 })
  const [websitesFormModal, setWebsitesFormModal] = useState({ visible: false, initialData: {}, activeSkillInitialData: {} })

  const getData = () => {
    setLoading(true)
    axiosRequest(`api/v1/websites?page=${filters.page}&perPage=${filters.perPage}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.websites) {
          setLoading(false)
          setData({ data: res.data.websites.data, total: res.data.websites.total })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleDeleteWebsite = (website, forceDelete = false) => {
    axiosRequest(
      `api/v1/websites/${website.id}?${!forceDelete ? `restore=${website.deleted_at ? 1 : 0} ` : ''}${
        forceDelete ? 'forceDelete=1' : ''
      }`,
      'delete',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          getData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const websitesModalHandler = (isVisible, data, asData = {}) => {
    setWebsitesFormModal({ visible: isVisible, initialData: data, activeSkillInitialData: asData })
  }

  return (
    <>
      <Helmet title="Websites" />
      <PageTitle
        title={`Websites (${data.total})`}
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/companies', name: 'Companies' },
          { path: '/websites', name: 'Websites' },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'websites:create') && (
              <Button
                icon={<RiAddFill />}
                className="btn-primary fw-500"
                onClick={() => {
                  websitesModalHandler(true, {}, {})
                }}
              >
                Add Website
              </Button>
            )}
          </>
        }
      />
      <DefaultCard>
        <Spin spinning={loading}>
          <Table
            columns={[
              {
                key: 'name',
                name: 'Name',
                headClassName: null,
                itemClassName: null,
                render: (item) => <strong>{item.name}</strong>,
              },
              {
                key: 'company',
                name: 'Company',
                headClassName: null,
                itemClassName: null,
                render: (item) => <strong>{item.get_company ? item.get_company.name : ''}</strong>,
              },
              {
                key: 'domain',
                name: 'URL',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <a href={item.domain} target="_blank" rel="noreferrer noopener nofollow">
                    <span className="dark-color">{item.domain}</span>
                  </a>
                ),
              },
              {
                key: 'token',
                name: 'API Token',
                headClassName: null,
                itemClassName: null,
                render: (item) => <span className="primary-color">{item.accessToken}</span>,
              },
              {
                key: 'status',
                name: 'Status',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {item.isActive ? (
                      <span className="f-size-2 success-color">
                        <RiCheckFill />
                      </span>
                    ) : (
                      <span className="f-size-2 danger-color">
                        <RiCloseFill />
                      </span>
                    )}
                  </>
                ),
              },
              {
                key: 'createdAt',
                name: 'Created At',
                headClassName: null,
                itemClassName: null,
                render: (item) => moment(item.created_at).format('YYYY-MM-DD'),
              },
              {
                key: 'actions',
                name: 'Actions',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {checkPermission(permissions, 'websites:edit') && (
                      <Button
                        className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                        onClick={() => {
                          websitesModalHandler(true, item, item.active_skill || {})
                        }}
                      >
                        <RiEditFill />
                      </Button>
                    )}
                    {checkPermission(permissions, 'websites:delete') && (
                      <>
                        <Popconfirm
                          title={`Are you really want to ${item.deleted_at ? 'restore' : 'delete'} this website?`}
                          onConfirm={() => handleDeleteWebsite(item)}
                          okButtonProps={{ className: `btn-sm ${item.deleted_at ? 'btn-warning' : 'btn-danger'}` }}
                          cancelButtonProps={{ className: `btn-sm` }}
                        >
                          <Button
                            className={`btn-sm no-svg-padding ${
                              item.deleted_at ? 'btn-warning btn-warning__simple' : 'btn-danger btn-danger__simple'
                            }`}
                          >
                            {item.deleted_at ? <RiRestartFill /> : <RiDeleteBin3Fill />}
                          </Button>
                        </Popconfirm>
                        {item.deleted_at && (
                          <Popconfirm
                            title={`Are you really want to permanently this website?`}
                            onConfirm={() => handleDeleteWebsite(item, true)}
                            okButtonProps={{ className: `btn-sm btn-danger` }}
                            cancelButtonProps={{ className: `btn-sm` }}
                          >
                            <Button className={`btn-sm no-svg-padding btn-danger btn-danger__simple`}>
                              <RiDeleteBin3Fill />
                            </Button>
                          </Popconfirm>
                        )}
                      </>
                    )}
                  </>
                ),
              },
            ]}
            data={data.data}
          />
          {filters.perPage < data.total && (
            <Pagination
              className="mt-4"
              size="small"
              current={filters.page}
              pageSize={filters.perPage}
              total={data.total}
              showSizeChanger={false}
              onChange={(page) => setFilters({ ...filters, page })}
            />
          )}
        </Spin>
      </DefaultCard>
      <WebsiteForm
        visible={websitesFormModal.visible}
        setVisibility={setWebsitesFormModal}
        initialData={websitesFormModal.initialData}
        activeSkillInitialData={websitesFormModal.activeSkillInitialData}
        reloadData={getData}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(WebsitesPage))
