import React from 'react'
import { Form, Input, InputNumber } from 'antd'

const PropertyInfo = () => {
  return (
    <div className="row">
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'taxes']}
        label="Taxes"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property taxes" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'zoning']}
        label="Zoning"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property zoning" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'utilities']}
        label="Utilities"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property utilities" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'deedType']}
        label="Deed Type"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property deed type" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'roadAccess']}
        label="Road Access"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property road access" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'legal']}
        label="Legal"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property legal info" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'hoa']}
        label="HOA"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property HOA" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'mobilesHomesAllowed']}
        label="Mobiles Homes Allowed"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property mobiles homes allowed" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'sizeAcres']}
        label="Size Acres"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <InputNumber min={0} step={0.01} placeholder="Property size in acres" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['info', 'sizeSqFt']}
        label="Size SqFt"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <InputNumber min={0} step={0.01} placeholder="Property size in SqFt" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 mb-md-0"
        name={['info', 'taxCollectorOffice']}
        label="Tax Collector Office"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property tax collector office" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 mb-0"
        name={['info', 'propertyAppraiserOffice']}
        label="Property Appraiser Office"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property property appraiser office" />
      </Form.Item>
      <Form.Item
        className="col-12 mt-4"
        name={['info', 'youTubeLink']}
        label="YouTube Link"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property YouTube Video" />
      </Form.Item>
    </div>
  )
}

export default PropertyInfo
