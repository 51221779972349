import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, InputNumber, Select } from 'antd'
import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'

const PropertiesFilters = ({ visible, setVisibility, filters, setFilters }) => {
  const [form] = Form.useForm()
  const [companiesLoading, setCompaniesLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [leadFilters, setLeadFilters] = useState({})

  useEffect(() => {
    if (visible) {
      setCompaniesLoading(true)
      axiosRequest('api/v1/companies/all', 'get', null, true)
        .then((res) => {
          if (res.data && res.data.success) {
            setCompanies(res.data.companies)
            setCompaniesLoading(false)
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
    }
  }, [visible])

  const handleFilters = () => {
    form.setFieldsValue({ ...leadFilters })
    setFilters({ ...filters, ...leadFilters })
  }

  const clearFilters = () => {
    clearFields()
    setFilters({ page: filters.page, perPage: filters.perPage })
    setLeadFilters({})
  }

  const clearFields = () => {
    form.setFieldsValue({
      q: null,
      status: null,
      boosts: null,
      company: null,
      fromAcres: null,
      toAcres: null,
      fromPrice: null,
      toPrice: null,
      fromOwnerFinancePrice: null,
      toOwnerFinancePrice: null,
      isOwnerFinance: null,
    })
  }

  return (
    <Drawer
      title="Filters"
      visible={visible}
      destroyOnClose
      width={370}
      onClose={() => {
        setVisibility(false)
      }}
      className="property-form-drawer drawer-width-400"
      footer={
        <>
          <div className="row">
            <div className="col-6">
              <Button className="w-100" onClick={() => clearFilters()}>
                Clear
              </Button>
            </div>
            <div className="col-6">
              <Button
                className="btn-primary w-100"
                onClick={() => handleFilters()}
              >
                Submit
              </Button>
            </div>
          </div>
        </>
      }
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="default-form"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleFilters()
          }
        }}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          initialValues={filters}
          form={form}
          className="row"
        >
          <Form.Item name="q" label="Search" className="col-12">
            <Input
              allowClear
              placeholder="Search..."
              onChange={(e) =>
                setLeadFilters({ ...leadFilters, q: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item name="company" label="Company" className="col-12">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Search by company..."
              loading={companiesLoading}
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, company: val })
              }
            >
              {companies.map((company) => (
                <Select.Option key={company.id} value={company.id}>
                  {company.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="status" label="Status" className="col-12">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Search by property status..."
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, status: val })
              }
            >
              <Select.Option value="published">Published</Select.Option>
              <Select.Option value="withErrors">With Errors</Select.Option>
              <Select.Option value="sold">Sold</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="boosts" label="Boosts" className="col-12">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Search by company..."
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, boosts: val })
              }
            >
              <Select.Option value="featured">Featured</Select.Option>
              <Select.Option value="showcase">Showcase</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="fromPrice" label="Price" className="col-12 col-md-6">
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="From"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, fromPrice: val })
              }
            />
          </Form.Item>
          <Form.Item name="toPrice" label=" " className="col-12 col-md-6">
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="To"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, toPrice: val })
              }
            />
          </Form.Item>
          <Form.Item
            name="fromAcres"
            label="Size Acres"
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="From"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, fromAcres: val })
              }
            />
          </Form.Item>
          <Form.Item name="toAcres" label=" " className="col-12 col-md-6">
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="To"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, toAcres: val })
              }
            />
          </Form.Item>
          <Form.Item
            name="isOwnerFinance"
            label="Owner Finance"
            className="col-12"
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Search by owner finance field"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, isOwnerFinance: val })
              }
            >
              <Select.Option value="yes">Show only owner finance</Select.Option>
              <Select.Option value="no">
                Show all except owner finance
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="fromOwnerFinancePrice"
            label="Owner Finance Price"
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="From"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, fromOwnerFinancePrice: val })
              }
            />
          </Form.Item>
          <Form.Item
            name="toOwnerFinancePrice"
            label=" "
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="To"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, toOwnerFinancePrice: val })
              }
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  )
}

export default PropertiesFilters
