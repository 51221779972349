import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'

import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

const PropertyLocation = ({ form }) => {
  const [loading, setLoading] = useState(false)

  const geocoding = () => {
    const lat = form.getFieldValue(['main', 'lat'])
    const lng = form.getFieldValue(['main', 'lng'])
    const country = form.getFieldValue(['main', 'country'])
    const stateRegion = form.getFieldValue(['main', 'stateRegion'])
    const county = form.getFieldValue(['main', 'county'])
    const street = form.getFieldValue(['main', 'street'])
    const city = form.getFieldValue(['main', 'city'])
    const zip = form.getFieldValue(['main', 'zip'])
    let url = 'api/v1/google-maps/reverse-geocoding'
    let data = { lat, lng }
    const method = 'post'
    if (!lat || !lng) {
      url = 'api/v1/google-maps/geocoding'
      data = { country, stateRegion, county, city, street, zip }
    }
    setLoading(true)
    axiosRequest(url, method, data, true)
      .then((res) => {
        if (res.data && res.data.success) {
          if (res.data.type === 'default') {
            form.setFieldsValue({
              main: {
                country: res.data.data.country,
                stateRegion: res.data.data.stateRegion,
                county: res.data.data.county,
                city: res.data.data.city,
                street: res.data.data.street,
                zip: res.data.data.zip,
                lat: res.data.data.lat,
                lng: res.data.data.lng,
              },
            })
          } else {
            form.setFieldsValue({
              main: {
                country: res.data.data.country,
                stateRegion: res.data.data.stateRegion,
                county: res.data.data.county,
                city: res.data.data.city,
                street: res.data.data.street,
                zip: res.data.data.zip,
              },
            })
          }
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="row">
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'lat']}
        label="Latitude"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Latitude" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'lng']}
        label="Longitude"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Longitude" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'country']}
        label="Country"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Country" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'stateRegion']}
        label="State/Region"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="State/Region" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'county']}
        label="County"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="County" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'city']}
        label="City"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="City" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'street']}
        label="Street"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Street" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 mb-0"
        name={['main', 'zip']}
        label="ZIP"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="ZIP" />
      </Form.Item>
      <Form.Item label="Get Location" className="col-12 col-md-6 mb-0">
        <Button className="w-100 btn-primary btn-primary__light" onClick={() => geocoding()} loading={loading}>
          Get Location
        </Button>
      </Form.Item>
    </div>
  )
}

export default PropertyLocation
