import React, { useState } from 'react'
import { Button, Form, Modal, Select } from 'antd'

import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

const ChangePropertyStatusModal = ({
  property,
  visible,
  setVisibility,
  getData,
  agentStatuses = false,
}) => {
  const [loading, setLoading] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    axiosRequest(
      `api/v1/acquisition/data/properties/${property.id}`,
      'patch',
      { Status: values.Status || property.Status },
      true,
    )
      .then(() => {
        getData()
        setVisibility()
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!property) {
    return null
  }

  return (
    <Modal
      title={`${property.ACRES} acre in ${property.SitusCity}, ${property.SitusState}`}
      visible={visible}
      onCancel={() => setVisibility()}
      destroyOnClose
      footer={false}
      centered
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        initialValues={{ Status: property.Status }}
      >
        <Form.Item
          name="Status"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select>
            {!agentStatuses && (
              <>
                <Select.Option value="unprocessed">Unprocessed</Select.Option>
                <Select.Option value="req_sent">
                  Waiting for Response
                </Select.Option>
                <Select.Option value="not_answered">Not Answered</Select.Option>
              </>
            )}
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="LP_InNegotiations">
              LP In Negotiations
            </Select.Option>
            <Select.Option value="LP_WaitingForApproval">
              LP Waiting for Approval
            </Select.Option>
            <Select.Option value="LP_CallBack">LP Call Back</Select.Option>
            <Select.Option value="lost">Closed Lost</Select.Option>
            <Select.Option value="success">Closed Won</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            className="btn btn-sm btn-primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangePropertyStatusModal
