const sidebarMenu = [
  {
    key: 'dashboard',
    path: '/',
    name: 'Dashboard',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2"
          y="2"
          width="9"
          height="9"
          rx="2"
          fill="currentColor"
        ></rect>
        <rect
          opacity="0.3"
          x="13"
          y="2"
          width="9"
          height="9"
          rx="2"
          fill="currentColor"
        ></rect>
        <rect
          opacity="0.3"
          x="13"
          y="13"
          width="9"
          height="9"
          rx="2"
          fill="currentColor"
        ></rect>
        <rect
          opacity="0.3"
          x="2"
          y="13"
          width="9"
          height="9"
          rx="2"
          fill="currentColor"
        ></rect>
      </svg>
    ),
    childs: null,
  },
  {
    key: 'acquisition',
    path: '/acquisition',
    name: 'Acquisition',
    icon: (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z"
          fill="currentColor"
        ></path>
        <path
          d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    childs: [
      { path: '/acquisition/dashboard', name: 'Dashboard' },
      { path: '/acquisition/leads-pool', name: 'Leads Pool' },
      { path: '/acquisition/data-parser', name: 'Data Parser' },
      { path: '/acquisition/requests', name: 'Requests' },
      { path: '/acquisition/settings', name: 'Settings' },
    ],
  },
  {
    key: 'listings',
    path: '/properties',
    name: 'Listings',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
          fill="currentColor"
        ></path>
        <path
          d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    childs: [
      { path: '/properties/all', name: 'All Listings' },
      { path: '/properties/live', name: 'Live Listings' },
      { path: '/properties/sold', name: 'Sold Listings' },
      { path: '/properties/draft', name: 'Draft Listings' },
      { path: '/properties/expired', name: 'Expired Listings' },
      { path: '/categories', name: 'Categories' },
    ],
  },
  {
    key: 'crm',
    path: '/crm',
    name: 'CRM',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M7 20.5L2 17.6V11.8L7 8.90002L12 11.8V17.6L7 20.5ZM21 20.8V18.5L19 17.3L17 18.5V20.8L19 22L21 20.8Z"
          fill="currentColor"
        ></path>
        <path
          d="M22 14.1V6L15 2L8 6V14.1L15 18.2L22 14.1Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    childs: [
      { path: '/crm/leads/all', name: 'All Leads' },
      { path: '/crm/leads/deposit', name: 'Deposits' },
      { path: '/crm/leads/contact', name: 'Contact Form' },
      // { path: '/crm/deals', name: 'Deals' },
      { path: '/crm/clients', name: 'Clients' },
    ],
  },
  {
    key: 'companies',
    path: '/companies',
    name: 'Companies',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
          fill="currentColor"
        ></path>
        <path
          opacity="0.3"
          d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    childs: [
      { path: '/companies', name: 'Companies' },
      { path: '/websites', name: 'Websites' },
      { path: '/companies/activeskill', name: 'ActiveSkill' },
    ],
  },
  {
    key: 'articles',
    path: '/articles',
    name: 'Articles',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.89999 21.9C5.89999 21.9 5.1 21.6 4.7 21.1C4.2 20.6 4 19.9 4 18.9V4.90002C4 3.90002 4.2 3.10007 4.7 2.70007C5.2 2.30007 5.89999 2 6.89999 2H13C13.9 2 14.7 2.10007 15.4 2.20007C16.1 2.30007 16.8 2.60002 17.4 2.90002C17.9 3.20002 18.3 3.6 18.7 4C19.1 4.5 19.4 5 19.6 5.5C19.8 6.1 19.9 6.70005 19.9 7.30005C19.9 9.00005 19.2 10.3 17.9 11.3C19.8 12.3 20.8 13.9 20.8 16C20.8 17.2 20.5 18.2001 19.9 19.2001C19.3 20.1001 18.5 20.9 17.4 21.3C16.8 21.6 16.1 21.7 15.3 21.9C14.5 22 13.6 22.1 12.6 22.1H6.89999V21.9ZM12.5 17.7001C15.3 17.7001 15.7 16.6 15.7 15.6C15.7 14.7 15.3 14.3 15 14.1C14.4 13.7 13.6 13.6 12.4 13.6H9.09999V17.7001H12.5ZM11.9 9.59998C12.8 9.59998 13.4 9.50002 13.9 9.40002C14.3 9.30002 14.5 9.00007 14.7 8.70007C14.9 8.40007 14.9 8.10005 14.9 7.80005C14.9 6.90005 14.5 6.6 14.3 6.5C13.7 6.2 12.8 6.09998 11.5 6.09998H9.09999V9.59998H11.9Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    key: 'users',
    path: '/users',
    name: 'Users',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
          fill="currentColor"
        ></path>
        <rect
          opacity="0.3"
          x="8"
          y="3"
          width="8"
          height="8"
          rx="4"
          fill="currentColor"
        ></rect>
      </svg>
    ),
    childs: [
      { path: '/users/all', name: 'All Users' },
      { path: '/users/admin', name: 'Management Team' },
      { path: '/users/acquisition', name: 'Acquisition Team' },
      { path: '/users/sales', name: 'Sales Team' },
    ],
  },
  {
    key: 'mailings',
    path: '/mailings',
    name: 'Newsletters',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
          fill="currentColor"
        ></path>
        <path
          d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
  },
]

export default sidebarMenu
