import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Button, Empty, Form, InputNumber, Select, Spin } from 'antd'

import PageTitle from '../../components/ui/title/PageTitle'
import DefaultCard from '../../components/ui/cards/DefaultCard'

import axiosRequest from '../../helpers/axios-request.helper'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import { countiesFips, statesFips } from '../../data/states/fips-county-codes'

const AcquisitionSettings = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  const getData = () => {
    setLoading(true)
    axiosRequest('api/v1/acquisition/parser-settings', 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          const formData = {}
          res.data.data.forEach((item) => {
            if (
              ['states', 'counties'].includes(item.key) &&
              item.value === ''
            ) {
              formData[item.key] = []
            } else {
              if (item.value !== null) {
                if (['states', 'counties'].includes(item.key)) {
                  formData[item.key] = item.value.split(',')
                } else {
                  formData[item.key] = item.value
                }
              } else {
                formData[item.key] = item.numericValue
              }
            }
          })
          setData(formData)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const setLabel = (key) => {
    switch (key) {
      case 'states':
        return 'States'
      case 'counties':
        return 'Counties'
      case 'dailyLimit':
        return 'Daily Limit'
      case 'threshold':
        return 'Threshold (Unprocessed leads amount, when parser should reduce his speed)'
      case 'stopWhenUnprocessedLeadsCount':
        return 'Stop parser when unprocessed leads count is equal'
      default:
        return ''
    }
  }

  const onFormSubmit = (val) => {
    Object.keys(val).forEach((key) => {
      const data = {
        key,
        value: ['states', 'counties'].includes(key)
          ? val[key].toString()
          : null,
        numericValue: !['states', 'counties'].includes(key)
          ? parseInt(val[key], 10)
          : null,
      }
      axiosRequest('api/v1/acquisition/parser-settings', 'post', data, true)
        .then(() => {
          getData()
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
    })
  }

  return (
    <>
      <Helmet title="Acquisition Settings" />
      <PageTitle title={`Acquisition Settings`} />
      <Spin spinning={loading}>
        <DefaultCard>
          {!loading && data ? (
            <Form
              layout="vertical"
              requiredMark={false}
              className="row"
              initialValues={data}
              onFinish={onFormSubmit}
            >
              {Object.keys(data).map((key) => {
                if (
                  key === 'dailyLimit' ||
                  key === 'threshold' ||
                  key === 'stopWhenUnprocessedLeadsCount'
                ) {
                  return (
                    <Form.Item
                      name={key}
                      key={key}
                      label={setLabel(key)}
                      className="col-12 col-xl-6"
                    >
                      <InputNumber min={0} step={1} className="w-100" />
                    </Form.Item>
                  )
                }

                if (key === 'states') {
                  return (
                    <Form.Item
                      name={key}
                      key={key}
                      label={setLabel(key)}
                      className="col-12"
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        showSearch
                        maxTagCount="responsive"
                        optionFilterProp="children"
                      >
                        {Object.keys(statesFips).map((key) => (
                          <Select.Option
                            key={`${key}${statesFips[key]}`}
                            value={key.toString()}
                          >
                            {statesFips[key]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }

                if (key === 'counties') {
                  return (
                    <Form.Item
                      name={key}
                      key={key}
                      label={setLabel(key)}
                      className="col-12"
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                      >
                        {countiesFips.map((county) => (
                          <Select.Option
                            key={`${county.countyFips}${county.state}${county.countyName}`}
                            value={`${
                              county.state
                            }|${county.countyFips.toString()}`}
                          >
                            {county.countyName} <small>{county.state}</small>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }

                return null
              })}
              <Form.Item className="mb-0">
                <Button htmlType="submit" className="btn-primary">
                  Save
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Empty description="Access Denied" />
          )}
        </DefaultCard>
      </Spin>
    </>
  )
}

export default AcquisitionSettings
