import React from 'react'
import _ from 'lodash'
import { RiAddFill, RiCheckboxFill, RiDeleteBin2Fill } from 'react-icons/ri'
import { message } from 'antd'

import axiosRequest from '../../../../../helpers/axios-request.helper'

import styles from './property-image.module.scss'

const PropertyImages = ({ images, setImages }) => {
  const onFilesUpload = (e) => {
    message.loading({ content: 'Please, wait...', key: 'uploadImages', duration: 60 })
    if (e.target.files) {
      Array.from(e.target.files).forEach((file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'
        if (!isJpgOrPng) {
          message.warn({ content: 'You can only upload JPG/PNG files', key: 'uploadImages' })
          return
        }
        // TODO max 20 MB
        const isLt2M = file.size / 1024 / 1024 < 20
        if (!isLt2M) {
          message.warn({ content: 'Image must be smaller than 20MB!', key: 'uploadImages' })
          return
        }
        const formData = new FormData()
        formData.append('file', file)
        axiosRequest('api/v1/upload/image', 'post', formData, true)
          .then((res) => {
            if (res.data && res.data.success) {
              const newImagesArr = images
              newImagesArr.push(res.data.file)
              setImages([...newImagesArr])
              message.success({ content: 'Done', key: 'uploadImages' })
            }
          })
          .catch(() => {
            message.error({ content: 'Image not uploaded. Try again or contact customers support.', key: 'uploadImages' })
          })
      })
    }
    e.target.value = ''
  }

  const handleDeleteImage = (id) => {
    const newImagesArr = images
    const findIndex = _.findIndex(images, (data) => data.id === id)
    if (findIndex >= 0) {
      newImagesArr.splice(findIndex, 1)
      setImages([...newImagesArr])
    }
  }

  const handleMainImage = (id) => {
    const newImagesArr = images
    const findIndex = _.findIndex(images, (data) => data.id === id)
    if (findIndex >= 0) {
      _.forEach(newImagesArr, (data) => {
        data.isMain = false
      })
      newImagesArr[findIndex].isMain ? (newImagesArr[findIndex].isMain = false) : (newImagesArr[findIndex].isMain = true)
      setImages([...newImagesArr])
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 mb-4">
          <div className={styles.uploaderWrapper}>
            <input type="file" accept="image/*" multiple onChange={(e) => onFilesUpload(e)} />
            <div className={styles.uploader}>
              <div className={styles.icon}>
                <RiAddFill />
              </div>
              <div className={styles.placeholder}>Drag or select an image</div>
            </div>
          </div>
        </div>
        {images.map((image) => (
          <div className="col-12 col-md-6 col-lg-6 mb-4" key={image.id}>
            <div className={styles.image}>
              <img src={image.medium} alt={image.name} />
              <div className={styles.controls}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={() => handleMainImage(image.id)} className={image.isMain ? styles.main : styles.nonMain}>
                  <RiCheckboxFill />
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={() => handleDeleteImage(image.id)} className={styles.delete}>
                  <RiDeleteBin2Fill />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PropertyImages
