import React from 'react'

import priceFormat from '../../../../../helpers/proce-format'

import styles from './styles.module.scss'

const Details = ({ lead }) => {
  return (
    <div className={styles.leadDetailsBlock}>
      <div className={styles.blockWrapper}>
        <h3>{lead.name}</h3>
        <div className="row">
          {lead.description && (
            <div className="col-12 mb-4">
              <p className={styles.label}>Description</p>
              <p className={styles.value}>
                <span>{lead.description}</span>
              </p>
            </div>
          )}
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>State</p>
            <p className={styles.value}>
              {lead.isClosed ? (
                <span className={lead.isSuccess ? 'success-color' : 'danger-color'}>{lead.isSuccess ? 'Won' : 'Lost'}</span>
              ) : (
                <span className="warning-color">Open</span>
              )}
            </p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Status</p>
            <p className={styles.value}>
              <span>{lead.status.name}</span>
            </p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Manager</p>
            <p className={styles.value}>
              <strong>{lead.customer && lead.customer.fullName}</strong>
            </p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Deposit</p>
            <p className={styles.value}>
              {lead.isDepositSubmitted
                ? `Deposit submitted. Deposit amount: ${
                    lead.depositAmount ? priceFormat(lead.depositAmount) : 'Not defined'
                  }`
                : 'Deposit not submitted'}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <h3>Property</h3>
        <div className="row">
          <div className="col-12">
            <h4>{lead.property.name}</h4>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Country</p>
            <p className={styles.value}>{lead.property.country || '-'}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>State/Region</p>
            <p className={styles.value}>{lead.property.stateRegion || '-'}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>County</p>
            <p className={styles.value}>{lead.property.county || '-'}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>City</p>
            <p className={styles.value}>{lead.property.city || '-'}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Street</p>
            <p className={styles.value}>{lead.property.street || '-'}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Zip</p>
            <p className={styles.value}>{lead.property.zip || '-'}</p>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Price</p>
            <p className={styles.value}>
              <span>{priceFormat(lead.property.price || 0, true)}</span>
            </p>
          </div>
          {lead.property.isOwnerFinance && lead.property.ownerFinancePrice && (
            <div className="col-12 col-md-6 mb-4">
              <p className={styles.label}>Owner Finance Price</p>
              <p className={styles.value}>{priceFormat(lead.property.ownerFinancePrice, true)}</p>
            </div>
          )}
          <div className="col-12 col-md-6 mb-4">
            <p className={styles.label}>Property Status</p>
            <p className={styles.value}>
              <span className={lead.property.isSold ? 'danger-color' : 'success-color'}>
                {lead.property.isSold ? 'Sold' : 'Available'}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
