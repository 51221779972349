import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import PageTitle from '../../components/ui/title/PageTitle'
import MailingForm from '../../components/pages/mailings/Form'
import DefaultCard from '../../components/ui/cards/DefaultCard'

import axiosRequest from '../../helpers/axios-request.helper'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import { Spin } from 'antd'

const CreateUpdateMailing = () => {
  const params = useParams()
  const { slug } = params
  const [loading, setLoading] = useState(true)
  const [dataLoading, setDataLoading] = useState(true)
  const [mailingData, setMailingData] = useState({
    name: null,
    website: null,
    preBody: null,
    postBody: null,
    emails: [],
    properties: [],
  })
  const [formData, setFormData] = useState({ websites: [] })

  useEffect(() => {
    setLoading(true)
    if (slug !== 'create') {
      axiosRequest(`api/v1/mailings/${slug}`, 'get', null, true)
        .then((res) => {
          if (res.data && res.data.success) {
            const { name, preBody, postBody, get_website, emails, properties } = res.data.mailingList
            const formData = {
              name,
              preBody,
              postBody,
              website: get_website.id,
              emails: _.map(emails, (v) => {
                return { key: v.get_customer.id, label: v.get_customer.fullName }
              }),
              properties: _.map(properties, (v) => {
                return { key: v.id, label: `${v.name} - ${v.propertyNumber}` }
              }),
            }
            setMailingData(formData)
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [slug])

  useEffect(() => {
    setDataLoading(true)
    axiosRequest('api/v1/websites?page=1&perPage=99999', 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          setFormData({ ...formData, websites: res.data.websites.data })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setDataLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet title="Create/Update Newsletter" />
      <PageTitle title="Create/Update Newsletter" />
      <Spin spinning={loading}>
        <DefaultCard>
          <MailingForm formData={formData} dataLoading={dataLoading} mailingData={mailingData} slug={slug} />
        </DefaultCard>
      </Spin>
    </>
  )
}

export default CreateUpdateMailing
