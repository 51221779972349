import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useStripe } from '@stripe/react-stripe-js'
import {
  Modal,
  Skeleton,
  Form,
  InputNumber,
  Select,
  Button,
  message,
} from 'antd'

import axiosErrorHelper from '../../../helpers/axios-error.helper'

const AddFundsModal = ({
  websiteName = null,
  websiteApiKey = null,
  onClose,
  isVisible = false,
}) => {
  const stripe = useStripe()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])

  useEffect(() => {
    setLoading(true)
    if (isVisible && websiteApiKey) {
      axios
        .post(
          `${process.env.REACT_APP_ACTIVESKILL_URL}public-api/v1/portal-station/payments/payment-methods`,
          { apiKey: websiteApiKey },
          { withCredentials: false },
        )
        .then((res) => {
          if (
            res.data &&
            res.data.success &&
            res.data.paymentMethods &&
            res.data.paymentMethods.data
          ) {
            setPaymentMethods(res.data.paymentMethods.data)
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [websiteApiKey, isVisible])

  const onFinish = (values) => {
    const { amount, paymentMethod } = values
    setPaymentLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_ACTIVESKILL_URL}public-api/v1/portal-station/payments/create-payment`,
        { apiKey: websiteApiKey, amount },
        { withCredentials: false },
      )
      .then((res) => {
        if (res.data && res.data.success) {
          const { transaction } = res.data
          stripe
            .confirmCardPayment(res.data.paymentIntent.client_secret, {
              payment_method: paymentMethod,
            })
            .then((stripeRes) => {
              if (stripeRes.error) {
                axios
                  .post(
                    `${process.env.REACT_APP_ACTIVESKILL_URL}public-api/v1/portal-station/payments/cancel-payment`,
                    { apiKey: websiteApiKey, amount },
                    { withCredentials: false },
                  )
                  .then(() => {
                    setPaymentLoading(false)
                    message.error(
                      res.error
                        ? res.error.message
                        : 'Payment failed. Please, try another card or contact support.',
                    )
                  })
                  .catch((e) => {
                    setPaymentLoading(false)
                    axiosErrorHelper(e)
                  })
              } else {
                if (stripeRes.paymentIntent.status === 'succeeded') {
                  axios
                    .post(
                      `${process.env.REACT_APP_ACTIVESKILL_URL}public-api/v1/portal-station/payments/confirm-payment`,
                      {
                        apiKey: websiteApiKey,
                        payment: stripeRes.paymentIntent,
                        transactionId: transaction.id,
                      },
                      { withCredentials: false },
                    )
                    .then(() => {
                      setPaymentLoading(false)
                      form.resetFields()
                      message.success('Success')
                      onClose()
                    })
                    .catch((e) => {
                      setPaymentLoading(false)
                      axiosErrorHelper(e)
                    })
                } else {
                  setPaymentLoading(false)
                  message.error(
                    'Something goes wrong. Try again later or contact customers support.',
                  )
                }
              }
              setPaymentLoading(false)
            })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  if (!websiteApiKey) {
    return null
  }

  return (
    <Modal
      title={`Add funds to ${websiteName}`}
      visible={isVisible}
      onCancel={() => {
        form && form.resetFields()
        setPaymentMethods([])
        onClose()
      }}
      destroyOnClose
      footer={false}
    >
      {loading && paymentMethods.length > 0 ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          className="default-form"
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item
            name="amount"
            label="Amount"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <InputNumber min={0.01} step={0.01} />
          </Form.Item>
          <Form.Item
            name="paymentMethod"
            label="Payment Method"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Select>
              {paymentMethods.length > 0 &&
                paymentMethods.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {`XXXX-XXXX-XXXX-${item.card.last4} | Expire ${item.card.exp_month}/${item.card.exp_year}`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item className="mb-0 text-end">
            <Button
              htmlType="submit"
              className="btn-primary"
              loading={paymentLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default AddFundsModal
