import React, { useEffect, useState } from 'react'
import { Button, Drawer, Table, Tooltip } from 'antd'
import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

// import axiosRequest from '../../../../../helpers/axios-request.helper'

const allowedKeys = [
  'Status',
  // 'Phone',
  // 'EmailAddress',
  // 'LineType',
  'Owner1Type',
  'OwnerName1Full',
  'Owner1FirstName',
  'OwnerLastname1',
  'MailingStreetAddress',
  'MailCity',
  'MailState',
  'MailZZIP9',
  'SitusStreetAddress',
  'SitusCity',
  'SitusState',
  'SitusZipCode',
  'County',
  'LegalDescription',
  'APNFormatted',
  'Latitude',
  'Longitude',
  'Subdivision',
  'LandUse',
  'CountyLandUse',
  'Zoning',
  'ACRES',
  'LandSquareFootage',
  'WaterType',
  'SewerType',
  'FloodZoneCode',
  'AssdTotalValue',
  'AssdLandValue',
  'AssdImprovementValue',
  'MarketValue',
  'DelinquentTaxValue',
  '',
]

const ParserPropertiesDrawer = ({ visible, setVisibility, properties }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ columns: [], data: [] })

  const titleCase = (str) => {
    let txt = str.replace(/([A-Z])/g, ' $1')
    txt = txt.charAt(0).toUpperCase() + txt.slice(1)
    return txt.trim()
  }

  const createTable = () => {
    const columns = []
    const tableData = []
    Object.keys(properties[0]).forEach((key) => {
      if (allowedKeys.includes(key)) {
        columns.push({
          title: titleCase(key),
          dataIndex: key,
          key: key,
          ellipsis: true,
          width: 200,
          render: (text) => (
            <Tooltip title={text} placement="topLeft">
              {text}
            </Tooltip>
          ),
        })
      }
    })

    properties.forEach((property) => {
      // axiosRequest(
      //   `api/v1/acquisition/data/properties/contacts/find/${property.id}`,
      //   'get',
      //   null,
      //   true,
      // )
      // .then((res) => {
      //   if (res.data) {
      //     if (res.data.result.result) {
      //       notification.success({
      //         message: 'Contacts Uploaded',
      //         description: `Contact was uploaded for property ${res.data.result.property.MailingStreetAddress}, ${res.data.result.property.MailCity}, ${res.data.result.property.MailState}, ${res.data.result.property.MailZZIP9}`,
      //       })
      //     } else {
      //       notification.error({
      //         message: 'Contacts Not Uploaded',
      //         description: `Can not find contacts for property ${res.data.result.property.MailingStreetAddress}, ${res.data.result.property.MailCity}, ${res.data.result.property.MailState}, ${res.data.result.property.MailZZIP9}`,
      //       })
      //     }
      //   }
      // })
      // .catch((e) => axiosErrorHelper(e))
      const dataItem = {
        key: property.PropertyId,
        // actions: (
        //   <>
        //     <Button
        //       className="btn-sm btn-danger"
        //       onClick={() => handleDeleteRow(property.PropertyId)}
        //     >
        //       <RiDeleteBin2Fill className="pe-0 me-0" />
        //     </Button>
        //   </>
        // ),
      }
      Object.keys(property).forEach((key) => {
        if (allowedKeys.includes(key)) {
          dataItem[key] = property[key] || '-'
        }
      })
      tableData.push(dataItem)
    })
    setData({ columns, data: tableData })
  }

  useEffect(() => {
    if (visible && properties && properties.length > 0) {
      createTable()
    }
    // eslint-disable-next-line
  }, [visible, properties])

  const sendMessagesHandler = () => {
    setLoading(true)
    const propertiesIds = []
    properties.forEach((property) => {
      propertiesIds.push(property.id)
    })
    axiosRequest(
      'api/v1/acquisition/data/admin/send-found-properties',
      'post',
      { properties: propertiesIds },
      true,
    )
      .then(() => {
        setData({ columns: [], data: [] })
        setVisibility(false)
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Drawer
      title="Search Result"
      visible={visible}
      onClose={() => {
        setData({ columns: [], data: [] })
        setVisibility(false)
      }}
      width="100%"
      destroyOnClose
    >
      <div className="mb-4 d-flex justify-content-end align-items-center">
        <div>
          <Button
            loading={loading}
            className="btn btn-sm btn-primary"
            onClick={() => sendMessagesHandler()}
          >
            Send Messages
          </Button>
        </div>
      </div>
      <div>
        {data.columns && data.columns.length > 0 ? (
          <div>
            <Table
              columns={data.columns}
              dataSource={data.data}
              pagination={false}
              size="small"
              rowSelection={{
                type: 'checkbox',
                fixed: true,
                columnWidth: 70,
                onChange: (selectedRowKeys, selectedRows) => {
                  console.log(
                    `selectedRowKeys: ${selectedRowKeys}`,
                    'selectedRows: ',
                    selectedRows,
                  )
                },
              }}
            />
          </div>
        ) : null}
      </div>
    </Drawer>
  )
}

export default ParserPropertiesDrawer
