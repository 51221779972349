import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, message, Skeleton } from 'antd'

import DefaultCard from '../../../../ui/cards/DefaultCard'
import PropertyDetails from './PropertyDetails'
import PropertyPrices from './PropertyPrices'
import PropertySettings from './PropertySettings'
import PropertyLocation from './PropertyLocation'
import PropertyInfo from './PropertyInfo'
import PropertyImages from './PropertyImages'

import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'
import { propertyData } from '../../../../../data/properties/form-initial-data'

const PropertyForm = ({
  visible,
  setVisibility,
  property,
  reloadData = (f) => f,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [fieldsData, setFieldsData] = useState({
    companies: [],
    categories: [],
    websites: [],
  })
  const [fieldsDataLoading, setFieldsDataLoading] = useState(true)

  useEffect(() => {
    setFieldsDataLoading(true)
    setImages(
      property &&
        property.main &&
        property.main.slug &&
        property.images &&
        property.images.length > 0
        ? property.images
        : [],
    )
    axiosRequest('api/v1/property-field-data', 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          const { websites, categories, companies } = res.data
          setFieldsData({ categories, companies, websites })
          setFieldsDataLoading(false)
          form.setFieldsValue({ ...property })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property])

  const handleFormSubmit = (values) => {
    setLoading(true)
    const propertyData = values
    if (!values.categories) {
      propertyData.categories = []
    }
    if (!values.websites) {
      propertyData.websites = []
    }
    propertyData.images = images
    let url = 'api/v1/listings/properties'
    let method = 'post'
    if (property && property.main && property.main.slug) {
      url = `api/v1/listings/properties/${property.main.slug}`
      method = 'patch'
    }
    axiosRequest(url, method, values, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
          form.resetFields()
          setImages([])
          setVisibility(false, propertyData)
          setFieldsData({
            companies: [],
            categories: [],
            websites: [],
          })
        }
      })
      .catch((e) => {
        reloadData()
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleFormErrors = () => {
    message.error('Please, check your inputs')
  }

  return (
    <Drawer
      title="Property Form"
      visible={visible}
      // destroyOnClose
      onClose={() => {
        form.resetFields()
        setVisibility(false, propertyData)
        setImages([])
        setFieldsData({
          companies: [],
          categories: [],
          websites: [],
        })
      }}
      className="property-form-drawer"
    >
      {fieldsDataLoading ? (
        <Skeleton active />
      ) : (
        <div className="default-form">
          <Form
            initialValues={property}
            layout="vertical"
            requiredMark={false}
            onFinish={handleFormSubmit}
            onFinishFailed={handleFormErrors}
            form={form}
          >
            <div className="row">
              <div className="col-12 col-lg-8">
                <DefaultCard title="Details" className="mb-4">
                  <PropertyDetails fieldsData={fieldsData} />
                </DefaultCard>
                <DefaultCard title="Info" className="mb-4">
                  <PropertyInfo />
                </DefaultCard>
              </div>
              <div className="col-12 col-lg-4">
                <DefaultCard title="Settings" className="mb-4">
                  <PropertySettings />
                </DefaultCard>
                <DefaultCard title="Prices" className="mb-4">
                  <PropertyPrices />
                </DefaultCard>
                <DefaultCard title="Location" className="mb-4">
                  <PropertyLocation form={form} />
                </DefaultCard>
                <DefaultCard title="Images" className="mb-4">
                  <PropertyImages images={images} setImages={setImages} />
                </DefaultCard>
              </div>
              <div className="col-12 mt-4 text-end">
                <DefaultCard>
                  <Button
                    className="btn-primary btn-primary__simple me-4"
                    onClick={(e) => {
                      e.preventDefault()
                      setVisibility(false, {})
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={loading}
                    htmlType="submit"
                    className="btn-primary"
                  >
                    Save
                  </Button>
                </DefaultCard>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Drawer>
  )
}

export default PropertyForm
