import React from 'react'
import moment from 'moment/moment'
import { Button, message, Popconfirm } from 'antd'
import { RiDeleteBin3Fill, RiEditFill, RiEyeFill } from 'react-icons/ri'

import Table from '../../../ui/table/Table'

import checkPermission from '../../../../helpers/check-permission'
import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'
import _ from 'lodash'

const ClientsTable = ({
  clients,
  reloadData,
  permissions,
  allowDetails = true,
  allowEdit = true,
  allowDelete = true,
  setVisibility = (f) => f,
}) => {
  const handleDeleteCustomer = (id) => {
    message.loading('Please, wait...')
    axiosRequest(`api/v1/crm/customers/${id}`, 'delete', null, true)
      .then(() => {
        reloadData()
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        message.destroy()
      })
  }

  return (
    <>
      <Table
        columns={[
          {
            key: 'name',
            name: 'Name',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                <p className="mb-1">
                  <strong>{item.fullName}</strong>
                </p>
                <p className="mb-0">
                  <small>
                    <strong className="primary-color">{item.isWholesaler ? 'Wholesaler' : ''}</strong>
                  </small>
                </p>
              </>
            ),
          },
          {
            key: 'contacts',
            name: 'Contacts',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                <p className="mb-2">
                  Email: <span className="primary-color">{item.email || '-'}</span>
                </p>
                <p className="mb-0">
                  Phone: <span className="primary-color">{item.phone || '-'}</span>
                </p>
              </>
            ),
          },
          {
            key: 'interests',
            name: 'Interests',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                {item.state && (
                  <p className="mb-1">
                    <strong>State:</strong> <span className="primary-color">{item.state}</span>
                  </p>
                )}
                {item.city && (
                  <p className="mb-1">
                    <strong>City:</strong> <span className="primary-color">{item.city}</span>
                  </p>
                )}
                {item.get_categories && item.get_categories.length > 0 ? (
                  <p className="mb-0 span-coma">
                    <strong>Categories:</strong>{' '}
                    {item.get_categories.map((category) => (
                      <span key={category.id} className="primary-color">
                        {category.name}
                      </span>
                    ))}
                  </p>
                ) : null}
              </>
            ),
          },
          {
            key: 'stats',
            name: 'Stats',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                <p className="mb-2">
                  <strong className="primary-color">{item.get_deposits_count || 0}</strong> <small>deposit(-s)</small>
                </p>
                <p className="mb-0">
                  <strong className="primary-color">{item.get_leads_count || 0}</strong> <small>lead(-s)</small>
                </p>
              </>
            ),
          },
          {
            key: 'date',
            name: 'Date',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                <p className="mb-1">
                  <span>
                    <small>Created at</small> {moment(item.created_at).format('YYYY-MM-DD')}
                  </span>
                </p>
                <p className="mb-0">
                  <span>
                    <small>Last update</small> {moment(item.updated_at).format('YYYY-MM-DD')}
                  </span>
                </p>
              </>
            ),
          },
          {
            key: 'actions',
            name: 'Actions',
            headClassName: null,
            itemClassName: null,
            render: (item) => (
              <>
                {allowDetails && (
                  <Button
                    className="me-2 btn-sm btn-success btn-success__simple no-svg-padding"
                    // onClick={() => {
                    //   setLeadDetails({ visible: true, id: item.id })
                    // }}
                  >
                    <RiEyeFill />
                  </Button>
                )}
                {allowEdit && checkPermission(permissions, 'clients:edit') ? (
                  <Button
                    className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                    onClick={() => {
                      const initialData = {
                        ...item,
                        state: item.state && item.state.length > 0 ? item.state.split(',') : null,
                        categories: [],
                      }
                      initialData.categories = _.map(item.get_categories, (category) => category.id)
                      setVisibility({ visible: true, data: initialData })
                    }}
                  >
                    <RiEditFill />
                  </Button>
                ) : null}
                {allowDelete && checkPermission(permissions, 'clients:delete') ? (
                  <Popconfirm
                    title={`Are you really want to permanently delete this customer?`}
                    onConfirm={() => handleDeleteCustomer(item.id)}
                    okButtonProps={{ className: `btn-sm btn-danger` }}
                    cancelButtonProps={{ className: `btn-sm` }}
                  >
                    <Button className="me-2 btn-sm btn-danger btn-danger__simple no-svg-padding">
                      <RiDeleteBin3Fill />
                    </Button>
                  </Popconfirm>
                ) : null}
              </>
            ),
          },
        ]}
        data={clients}
      />
    </>
  )
}

export default ClientsTable
