import React from 'react'
import { Button, Popconfirm, message } from 'antd'
import { RiEyeFill, RiFile3Fill, RiDeleteBin2Fill } from 'react-icons/ri'

import styles from './file-item.module.scss'

import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'

const FileItem = ({ fileData, reloadData, lead = null, property = null }) => {
  const { file } = fileData

  const openFile = (path) => {
    message.loading({ content: 'Please, wait...', key: 'file' })
    axiosRequest(`api/v1/upload/temporary-link`, 'post', { path }, true)
      .then((res) => {
        if (res.data && res.data.success) {
          window.open(res.data.file)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        message.success({ content: 'Done', key: 'file' })
      })
  }

  const handleRemoveFile = () => {
    message.loading({ content: 'Please, wait...', key: 'file' })
    axiosRequest(
      `api/v1/upload/${file.id}`,
      'patch',
      {
        property: property || null,
        lead: lead || null,
      },
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        message.success({ content: 'Done', key: 'file' })
      })
  }

  return (
    <div className={styles.fileItem}>
      <div className={styles.icon}>
        <RiFile3Fill />
      </div>
      <div className={styles.name}>
        {file.fileName ? file.fileName.substring(0, file.fileName.length > 36 ? 36 : file.fileName.length) : 'File'}
      </div>
      <div className={styles.actions}>
        <Button
          className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
          onClick={() => {
            openFile(file.path)
          }}
        >
          <RiEyeFill />
        </Button>
        <Popconfirm
          title={`Are you really want to permanently delete this file?`}
          onConfirm={() => handleRemoveFile(file.id)}
          okButtonProps={{ className: `btn-sm btn-danger` }}
          cancelButtonProps={{ className: `btn-sm` }}
        >
          <Button className="me-0 btn-sm btn-danger btn-danger__simple no-svg-padding">
            <RiDeleteBin2Fill />
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

export default FileItem
