import authRoutes from './auth'
import appRoutes from './app'
import crmRoutes from './crm'
import acquisitionRoutes from './acquisition'

const routes = [
  // Auth routes
  ...authRoutes,
  // App routes
  ...appRoutes,
  // Crm routes
  ...crmRoutes,
  // Acquisitions routes
  ...acquisitionRoutes,
]

export default routes
