import React from 'react'
import { Empty } from 'antd'

import DefaultCard from '../../../../ui/cards/DefaultCard'
import UploadButton from '../../../../ui/uploads/UploadButton'
import FileItem from '../../../../ui/uploads/FileItem'

const PropertiesFiles = ({ leadId, files, reloadData, uploadType }) => {
  return (
    <DefaultCard title="Files" titleData={<UploadButton reloadData={reloadData} dataId={leadId} uploadType={uploadType} />}>
      {files && files.length > 0 ? (
        files.map((file) => <FileItem fileData={file} reloadData={reloadData} key={file.id} lead={leadId} property={null} />)
      ) : (
        <Empty description="No uploaded files found" />
      )}
    </DefaultCard>
  )
}

export default PropertiesFiles
