import React from 'react'
import { Checkbox, Input, message, Modal, notification } from 'antd'
import { RiAddFill } from 'react-icons/ri'

import styles from '../../../listings/properties/form/property-image.module.scss'
import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

const UploadCSVPropertiesModal = ({ visible, setVisibility, getData }) => {
  const [getContacts, setGetContacts] = React.useState(true)
  const [fileName, setFileName] = React.useState('')
  const [skipNotUnique, setSkipNotUnique] = React.useState(true)
  const [skipNotUniquePhones, setSkipNotUniquePhones] = React.useState(true)
  const [sendDialer, setSendDialer] = React.useState(true)
  const [sendText, setSendText] = React.useState(false)
  const [sendEmail, setSendEmail] = React.useState(true)
  const [sendDrop, setSendDrop] = React.useState(true)
  const [useAdminSellMyDirt, setUseAdminSellMyDirt] = React.useState(false)
  const [useSimpleTexting, setUseSimpleTexting] = React.useState(true)
  const [useBulkSMS, setUseBulkSMS] = React.useState(true)
  const [useClickSend, setUseClickSend] = React.useState(true)

  const onFilesUpload = (e) => {
    message.loading({
      content: 'Please, wait...',
      key: 'uploadImages',
      duration: 60,
    })
    if (e.target.files && e.target.files[0]) {
      if (!fileName || fileName.length < 1) {
        message.error({
          content: 'File name is missing',
          key: 'uploadImages',
        })
        e.target.value = ''
        return null
      }
      const file = e.target.files[0]
      const isCSV = file.type === 'text/csv'
      if (!isCSV) {
        message.warn({
          content: 'You can only upload CSV files',
          key: 'uploadImages',
        })
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        message.warn({
          content: 'File must be smaller than 20MB!',
          key: 'uploadImages',
        })
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileName', fileName)
      formData.append('skipNotUnique', skipNotUnique ? '1' : '0')
      formData.append('skipNotUniquePhones', skipNotUniquePhones ? '1' : '0')
      formData.append('getContacts', getContacts ? '1' : '0')
      formData.append('sendDialer', sendDialer ? '1' : '0')
      formData.append('sendText', sendText ? '1' : '0')
      formData.append('sendEmail', sendEmail ? '1' : '0')
      formData.append('sendDrop', sendDrop ? '1' : '0')
      formData.append('useAdminSellMyDirt', useAdminSellMyDirt ? '1' : '0')
      formData.append('useSimpleTexting', useSimpleTexting ? '1' : '0')
      formData.append('useBulkSMS', useBulkSMS ? '1' : '0')
      formData.append('useClickSend', useClickSend ? '1' : '0')
      axiosRequest(
        `api/v1/acquisition/data/admin/csv-upload`,
        'post',
        formData,
        true,
      )
        .then((res) => {
          if (res.data && res.data.success) {
            message.success({
              content: 'Done',
              key: 'uploadImages',
            })
            notification.success({
              message: 'Success',
              description: res.data.message,
            })
            setGetContacts(true)
            getData()
            setVisibility(false)
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
    }
    e.target.value = ''
  }

  return (
    <Modal
      title="CSV Uploader"
      visible={visible}
      onCancel={() => {
        setGetContacts(true)
        setGetContacts(true)
        setSkipNotUnique(true)
        setSendDialer(true)
        setSendText(false)
        setSendEmail(true)
        setSendDrop(true)
        setUseAdminSellMyDirt(true)
        setUseBulkSMS(true)
        setUseClickSend(true)
        setVisibility(false)
      }}
      width={780}
      destroyOnClose
      footer={false}
    >
      <>
        <div className="row mb-5">
          <div className="col-12 mb-4">
            <Input
              type="text"
              placeholder="File Name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-4">
            <Checkbox
              checked={skipNotUnique}
              onChange={(e) => setSkipNotUnique(e.target.checked)}
            >
              Upload Only Unique Properties (Check by parcel number)
            </Checkbox>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <Checkbox
              checked={skipNotUniquePhones}
              onChange={(e) => setSkipNotUniquePhones(e.target.checked)}
            >
              Upload Only Unique Phones (Check by phone number)
            </Checkbox>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <Checkbox
              checked={getContacts}
              onChange={(e) => setGetContacts(e.target.checked)}
            >
              Parse Contact Information
            </Checkbox>
          </div>
          <div className="col-6 mb-4">
            <Checkbox
              checked={sendDialer}
              onChange={(e) => setSendDialer(e.target.checked)}
            >
              Send to Dialer
            </Checkbox>
          </div>
          <div className="col-6 mb-4">
            <Checkbox
              checked={useBulkSMS}
              onChange={(e) => setUseBulkSMS(e.target.checked)}
            >
              Bulk SMS
            </Checkbox>
          </div>
          <div className="col-6 mb-4">
            <Checkbox
              checked={useClickSend}
              onChange={(e) => setUseClickSend(e.target.checked)}
            >
              ClickSend SMS
            </Checkbox>
          </div>
          <div className="col-6 mb-4">
            <Checkbox
              checked={useAdminSellMyDirt}
              onChange={(e) => {
                setUseAdminSellMyDirt(e.target.checked)
                if (e.target.checked) {
                  setUseSimpleTexting(false)
                }
              }}
            >
              Text through SMS Gateway
            </Checkbox>
          </div>
          <div className="col-6 mb-4">
            <Checkbox
              checked={useSimpleTexting}
              onChange={(e) => {
                setUseSimpleTexting(e.target.checked)
                if (e.target.checked) {
                  setUseAdminSellMyDirt(false)
                }
              }}
            >
              Text through Trumpia
            </Checkbox>
          </div>
          <div className="col-6 mb-4">
            <Checkbox
              checked={sendText}
              disabled
              onChange={(e) => setSendText(e.target.checked)}
            >
              Send Text Messages
            </Checkbox>
          </div>
          <div className="col-6 mb-4">
            <Checkbox
              checked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
            >
              Send Email Messages
            </Checkbox>
          </div>
          <div className="col-6 mb-4">
            <Checkbox
              checked={sendDrop}
              onChange={(e) => setSendDrop(e.target.checked)}
            >
              Send Drop Campaign
            </Checkbox>
          </div>
        </div>
        <div className={styles.uploaderWrapper}>
          <input type="file" accept=".csv" onChange={(e) => onFilesUpload(e)} />
          <div className={styles.uploader}>
            <div className={styles.icon}>
              <RiAddFill />
            </div>
            <div className={styles.placeholder}>Drag or select an CSV file</div>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default UploadCSVPropertiesModal
