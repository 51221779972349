const priceFormat = (price, isInCents = true) => {
  let getPrice = price
  if (isInCents) {
    getPrice = getPrice / 100
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
    .format(getPrice)
    .replace(/\D00(?=\D*$)/, '')
}

export default priceFormat
