import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Button, Spin, Pagination } from 'antd'
import { RiAddFill, RiFilter3Fill, RiSettings2Fill } from 'react-icons/ri'

import PageTitle from '../../components/ui/title/PageTitle'
import PropertyForm from '../../components/pages/listings/properties/form/Form'
import PropertyItem from '../../components/pages/listings/properties/item/PropertyItem'

import checkPermission from '../../helpers/check-permission'
import {
  propertyData,
  buildPropertyData,
} from '../../data/properties/form-initial-data'
import axiosRequest from '../../helpers/axios-request.helper'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import PropertiesFilters from '../../components/pages/listings/properties/Filters'
import convertJsonToUrlParams from '../../helpers/convert-json-to-url-params'
import PropertiesContractVariablesForm from '../../components/pages/listings/properties/PropertiesContractVariablesForm'

const mapStateToProps = ({ users }) => ({ users })

const PropertiesPage = ({ users }) => {
  const { slug } = useParams()
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [contractPriceVariables, setContractPriceVariables] = useState(false)
  const [filtersVisibility, setFiltersVisibility] = useState(false)
  const [data, setData] = useState({ data: [], total: 0 })
  const [filters, setFilters] = useState({ page: 1, perPage: 20 })
  const [propertyForm, setPropertyForm] = useState({
    visible: false,
    initialData: {},
  })

  const getData = () => {
    setLoading(true)
    axiosRequest(
      `api/v1/listings/properties${convertJsonToUrlParams(
        filters,
      )}&listingsState=${slug}&respectPriority=true&countLeads=true`,
      'get',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          setData({
            data: res.data.properties.data,
            total: res.data.properties.total,
          })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, slug])

  const handlePropertyForm = (visible, initialData) => {
    if (visible && initialData.slug) {
      setPropertyForm({
        visible: true,
        initialData: buildPropertyData(initialData),
      })
    } else {
      setPropertyForm({ visible, initialData })
    }
  }

  return (
    <>
      <Helmet title="Properties" />
      <PageTitle
        title={`Properties (${data.total})`}
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/properties', name: 'Properties' },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'properties:create') && (
              <Button
                icon={<RiAddFill />}
                className="btn-primary fw-500"
                onClick={() => {
                  handlePropertyForm(true, propertyData)
                }}
              >
                Add New Property
              </Button>
            )}
            <Button
              icon={<RiFilter3Fill />}
              className="btn-primary fw-500 ms-3"
              onClick={() => {
                setFiltersVisibility(true)
              }}
            >
              Filters
            </Button>
            {checkPermission(permissions, 'properties:contractSettings') && (
              <Button
                className="btn-primary fw-500 ms-3 pe-2"
                onClick={() => {
                  setContractPriceVariables(true)
                }}
              >
                <RiSettings2Fill className="pe-0 ms-0" />
              </Button>
            )}
          </>
        }
      />
      <Spin spinning={loading}>
        {data.data.map((property) => (
          <PropertyItem
            users={users}
            property={property}
            permissions={permissions}
            key={property.id}
            handlePropertyForm={handlePropertyForm}
            reloadData={getData}
          />
        ))}
        {filters.perPage < data.total && (
          <Pagination
            className="mt-4"
            size="small"
            current={filters.page}
            pageSize={filters.perPage}
            total={data.total}
            showSizeChanger={false}
            onChange={(page) => setFilters({ ...filters, page })}
          />
        )}
      </Spin>
      <PropertyForm
        visible={propertyForm.visible}
        setVisibility={handlePropertyForm}
        property={propertyForm.initialData}
        reloadData={getData}
      />
      <PropertiesFilters
        filters={filters}
        setVisibility={setFiltersVisibility}
        visible={filtersVisibility}
        setFilters={setFilters}
      />
      <PropertiesContractVariablesForm
        visible={contractPriceVariables}
        setVisibility={setContractPriceVariables}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(PropertiesPage))
