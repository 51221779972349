import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Button, Pagination, Spin } from 'antd'
import { RiAddFill, RiFilter3Fill } from 'react-icons/ri'

import PageTitle from '../../components/ui/title/PageTitle'
import DefaultCard from '../../components/ui/cards/DefaultCard'

import checkPermission from '../../helpers/check-permission'
import axiosRequest from '../../helpers/axios-request.helper'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import convertJsonToUrlParams from '../../helpers/convert-json-to-url-params'
import LeadsTable from '../../components/pages/crm/leads/LeadsTable'
import LeadForm from '../../components/pages/crm/leads/LeadForm'
import LeadsFilters from '../../components/pages/crm/leads/Filters'

const mapStateToProps = ({ users }) => ({ users })

const LeadsPage = ({ users }) => {
  const { slug } = useParams()
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({ page: 1, perPage: 30 })
  const [data, setData] = useState({ data: [], total: 0 })
  const [statuses, setStatuses] = useState([])
  const [filtersVisibility, setFiltersVisibility] = useState(false)
  const [leadForm, setLeadForm] = useState({ visible: false, data: {} })

  const getData = () => {
    setLoading(true)
    axiosRequest(
      `api/v1/crm/leads${convertJsonToUrlParams(filters)}${slug && slug !== 'all' ? `&leadType=${slug}` : ''}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.leads) {
          setData({ data: res.data.leads.data, total: res.data.leads.total })
          setStatuses(res.data.statuses)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, slug])

  const handleLeadFormVisibility = (visibility, data = {}) => {
    const leadData = data
    if (!leadData.id) {
      leadData.isClosed = false
      leadData.isSuccess = false
      leadData.isDepositSubmitted = false
    } else {
      leadData.manager = {
        key: leadData.manager.id,
        label: leadData.manager.name,
        value: leadData.manager.id,
        disabled: false,
      }
      leadData.customer = {
        key: leadData.customer.id,
        label: leadData.customer.fullName,
        value: leadData.customer.id,
        disabled: false,
      }
      leadData.property = {
        key: leadData.property.id,
        label: leadData.property.name,
        value: leadData.property.id,
        disabled: false,
      }
      leadData.status = {
        key: leadData.status.id,
        label: leadData.status.name,
        value: leadData.status.id,
        disabled: false,
      }
    }
    setLeadForm({ visible: visibility, data: leadData })
    getData()
  }

  const handleUpdateFilters = (label, value) => {
    setFilters({
      ...filters,
      [label]: value,
    })
  }

  return (
    <>
      <Helmet title="Leads" />
      <PageTitle
        title="Leads"
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/crm/leads', name: 'CRM' },
          { path: '/crm/leads', name: 'Leads' },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'companies:create') && (
              <Button
                icon={<RiAddFill />}
                className="btn-primary fw-500 me-3"
                onClick={() => {
                  handleLeadFormVisibility(true, {})
                }}
              >
                Add Lead
              </Button>
            )}
            <Button
              icon={<RiFilter3Fill />}
              className="btn-primary fw-500"
              onClick={() => {
                setFiltersVisibility(true)
              }}
            >
              Filters
            </Button>
          </>
        }
      />
      <Spin spinning={loading}>
        <DefaultCard>
          <LeadsTable
            user={users.user}
            leads={data.data}
            reloadData={getData}
            permissions={permissions}
            setVisibility={handleLeadFormVisibility}
            setFilters={handleUpdateFilters}
          />
          {filters.perPage < data.total && (
            <Pagination
              className="mt-4"
              size="small"
              current={filters.page}
              pageSize={filters.perPage}
              total={data.total}
              showSizeChanger={false}
              onChange={(page) => setFilters({ ...filters, page })}
            />
          )}
        </DefaultCard>
      </Spin>
      <LeadForm
        statuses={statuses}
        reloadData={getData}
        initialData={leadForm.data}
        setVisibility={handleLeadFormVisibility}
        visibility={leadForm.visible}
      />
      <LeadsFilters
        setFilters={setFilters}
        setVisibility={setFiltersVisibility}
        visible={filtersVisibility}
        filters={filters}
        statuses={statuses}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(LeadsPage))
