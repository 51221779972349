import React, { useState } from 'react'
import moment from 'moment'
import {
  RiAlignJustify,
  RiBuildingFill,
  RiDeleteBin3Fill,
  RiEditFill,
  RiExternalLinkFill,
  RiEyeFill,
  RiMap2Fill,
  RiRestartFill,
  RiTimerFill,
  RiHashtag,
  RiLandscapeFill,
  RiMoneyDollarCircleFill,
} from 'react-icons/ri'
import { Button, Popconfirm, Tooltip } from 'antd'

import DefaultCard from '../../../../ui/cards/DefaultCard'
import Footer from './partials/Footer'
import PropertyDetails from './PropertyDetails'

import checkPermission from '../../../../../helpers/check-permission'
import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

import styles from './property-item.module.scss'

const PropertyItem = ({
  users,
  property,
  permissions,
  handlePropertyForm,
  reloadData,
}) => {
  const [propertyDetails, setPropertyDetails] = useState({
    visible: false,
    slug: null,
  })

  const handleDeleteProperty = (property, forceDelete = false) => {
    axiosRequest(
      `api/v1/listings/properties/${property.slug}?${
        !forceDelete ? `restore=${property.deleted_at ? 1 : 0} ` : ''
      }${forceDelete ? 'forceDelete=1' : ''}`,
      'delete',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  return (
    <>
      <DefaultCard className="mb-4">
        <Tooltip
          placement="bottom"
          title={property.priority === 1 ? 'High Priority' : 'Normal Priority'}
        >
          <div
            className={`property-priority property-priority__${property.priority}`}
          />
        </Tooltip>
        <div className={styles.propertyItem}>
          <div className={styles.item}>
            <div className={styles.itemWrapper}>
              <div className={styles.image}>
                {property.image && property.image.image ? (
                  <img src={property.image.image.small} alt="" />
                ) : null}
              </div>
              <div className={styles.contentWrapper}>
                <div className={styles.content}>
                  <h2>
                    {property.name}
                    {property.featured && (
                      <sup className="ms-2 success-color">Featured</sup>
                    )}
                    {property.showcase && (
                      <sup className="ms-2 primary-color">Showcase</sup>
                    )}
                    {property.isSold && (
                      <sup className="ms-2 success-color">Sold</sup>
                    )}
                    {property.linked_buildfortune && (
                      <sup className="ms-2 purple-color">BuildFortune</sup>
                    )}
                  </h2>
                  <div className={styles.info}>
                    <ul className="row">
                      <li className="col-12 col-xl-6">
                        <RiHashtag />{' '}
                        <span>
                          Property number:{' '}
                          <strong>{property.propertyNumber}</strong>
                        </span>
                      </li>
                      <li className="col-12 col-xl-6">
                        <RiLandscapeFill />{' '}
                        <span>
                          Parcel number:{' '}
                          <span className="primary-color">
                            {property.parcelNumber}
                          </span>
                        </span>
                      </li>
                      <li className="col-12 col-xl-6">
                        <RiBuildingFill />{' '}
                        <span>
                          {property.company
                            ? property.company.name
                            : 'Company not defined'}
                        </span>
                      </li>
                      {property.country &&
                      property.stateRegion &&
                      property.city &&
                      property.street &&
                      property.zip ? (
                        <li className="col-12 col-xl-6">
                          <RiMap2Fill />{' '}
                          <span>
                            {property.street}, {property.city},{' '}
                            {property.county + ', ' || ''}{' '}
                            {property.stateRegion}, {property.country},{' '}
                            {property.zip}
                          </span>
                        </li>
                      ) : null}
                      {property.categories && property.categories.length > 0 ? (
                        <li className="col-12 col-xl-6">
                          <p className="mb-0 span-coma">
                            <RiAlignJustify />{' '}
                            {property.categories.map((category) => (
                              <span key={category.id}>{category.name}</span>
                            ))}
                          </p>
                        </li>
                      ) : null}
                      {property.websites && property.websites.length > 0 ? (
                        <li className="col-12 col-xl-6">
                          <p className="mb-0 span-coma">
                            <RiExternalLinkFill />{' '}
                            {property.websites.map((website) => (
                              <span key={website.id}>
                                <a
                                  href={`${website.domain}/p/${property.propertyNumber}`}
                                  target="_blank"
                                  rel="noreferrer nofollow noopener"
                                >
                                  {website.name}
                                </a>
                              </span>
                            ))}
                          </p>
                        </li>
                      ) : null}
                      <li className="col-12 col-xl-4 mt-0 mt-xl-2">
                        <RiMoneyDollarCircleFill />{' '}
                        <span>
                          Gross Profit:{' '}
                          {property.grossProfit
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                maximumFractionDigits: 0,
                              }).format(property.grossProfit / 100)
                            : 'None'}
                        </span>
                      </li>
                      <li className="col-12 col-xl-4 mt-0 mt-xl-2">
                        <RiMoneyDollarCircleFill />{' '}
                        <span>
                          Potential Profit:{' '}
                          {property.potentialProfit
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                maximumFractionDigits: 0,
                              }).format(property.potentialProfit / 100)
                            : 'None'}
                        </span>
                      </li>
                      <li className="col-12 col-xl-4 mt-0 mt-xl-2">
                        <RiMoneyDollarCircleFill />{' '}
                        <span>
                          Expenses:{' '}
                          {property.expenses
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                maximumFractionDigits: 0,
                              }).format(property.expenses / 100)
                            : 'None'}
                        </span>
                      </li>
                      <li className="col-12 col-xl-6">
                        <RiTimerFill />{' '}
                        <span>
                          Added:{' '}
                          {moment(property.created_at).format(
                            'YYYY-MM-DD HH:mm',
                          )}
                        </span>
                      </li>
                      <li className="col-12 col-xl-6">
                        <RiEditFill />{' '}
                        <span>
                          Last Edit:{' '}
                          {moment(property.updated_at).format(
                            'YYYY-MM-DD HH:mm',
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={styles.actions}>
                  <>
                    {checkPermission(permissions, 'properties:single') && (
                      <div className="mb-2">
                        <Button
                          className="btn-sm btn-primary btn-primary__simple no-svg-padding"
                          onClick={() => {
                            setPropertyDetails({
                              visible: true,
                              slug: property.slug,
                            })
                          }}
                        >
                          <RiEyeFill /> <span className="ps-2">Details</span>
                        </Button>
                      </div>
                    )}
                    {checkPermission(permissions, 'properties:edit') && (
                      <div className="mb-2">
                        <Button
                          className="btn-sm btn-primary btn-primary__simple no-svg-padding"
                          onClick={() => {
                            handlePropertyForm(true, property)
                          }}
                        >
                          <RiEditFill /> <span className="ps-2">Edit</span>
                        </Button>
                      </div>
                    )}
                    {checkPermission(permissions, 'properties:delete') && (
                      <>
                        <div className="mb-0">
                          <Popconfirm
                            title={`Are you really want to ${
                              property.deleted_at ? 'restore' : 'draft/archive'
                            } this property?`}
                            onConfirm={() => handleDeleteProperty(property)}
                            okButtonProps={{
                              className: `btn-sm ${
                                property.deleted_at
                                  ? 'btn-warning'
                                  : 'btn-danger'
                              }`,
                            }}
                            cancelButtonProps={{ className: `btn-sm` }}
                          >
                            <Button
                              className={`btn-sm no-svg-padding ${
                                property.deleted_at
                                  ? 'btn-warning btn-warning__simple'
                                  : 'btn-danger btn-danger__simple'
                              }`}
                            >
                              {property.deleted_at ? (
                                <>
                                  <RiRestartFill />{' '}
                                  <span className="ps-2">Publish Property</span>
                                </>
                              ) : (
                                <>
                                  <RiDeleteBin3Fill />{' '}
                                  <span className="ps-2">Archive Property</span>
                                </>
                              )}
                            </Button>
                          </Popconfirm>
                          {property.deleted_at && (
                            <Popconfirm
                              title={`Are you really want to permanently delete this property?`}
                              onConfirm={() =>
                                handleDeleteProperty(property, true)
                              }
                              okButtonProps={{ className: `btn-sm btn-danger` }}
                              cancelButtonProps={{ className: `btn-sm` }}
                            >
                              <Button
                                className={`d-none btn-sm no-svg-padding btn-danger btn-danger__simple`}
                              >
                                <RiDeleteBin3Fill />{' '}
                                <span className="ps-2">Delete</span>
                              </Button>
                            </Popconfirm>
                          )}
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
          <Footer
            property={property}
            permissions={permissions}
            user={users.user}
          />
        </div>
      </DefaultCard>
      <PropertyDetails
        users={users}
        visible={propertyDetails.visible}
        setVisibility={setPropertyDetails}
        slug={propertyDetails.slug}
      />
    </>
  )
}

export default PropertyItem
