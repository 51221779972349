import React from 'react'
import { Modal, Empty } from 'antd'

import PageTitle from '../../../../ui/title/PageTitle'
import ParserForm from './ParserForm'
import ParserPropertiesDrawer from './ParserPropertiesDrawer'

const ParserModal = ({ visible, setVisible, getData }) => {
  const [drawer, setDrawer] = React.useState(false)
  const [properties, setProperties] = React.useState({})

  return (
    <>
      <Modal
        title="DataTree Parser"
        visible={visible}
        onCancel={() => {
          getData()
          setVisible()
        }}
        footer={false}
        width={1400}
        centered
        destroyOnClose
      >
        <>
          <ParserForm setProperties={setProperties} setDrawer={setDrawer} />
          <div className="mb-0">
            {!properties.properties ||
            properties.properties.length === 0 ? null : (
              <>
                <PageTitle title="Search Results" />
                <div>
                  {!properties.properties ||
                  properties.properties.length === 0 ? (
                    <Empty description="No data" />
                  ) : (
                    <p className="mb-0">
                      Parser {properties.found || 0} properties. Found{' '}
                      {properties.duplicates || 0} duplicates.
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      </Modal>
      <ParserPropertiesDrawer
        visible={drawer}
        setVisibility={setDrawer}
        properties={properties.properties}
      />
    </>
  )
}

export default ParserModal
