import React from 'react'
import { Drawer } from 'antd'

import styles from './property-details.module.scss'

const PropertyDetails = ({
  showContacts,
  visible,
  setVisibility,
  property,
}) => {
  const titleCase = (str) => {
    let txt = str.replace(/([A-Z])/g, ' $1')
    txt = txt.charAt(0).toUpperCase() + txt.slice(1)
    return txt.trim()
  }

  if (!property) {
    return null
  }

  return (
    <Drawer
      visible={visible}
      onClose={() => setVisibility()}
      footer={false}
      width={600}
      destroyOnClose
      title={`${property.ACRES} acre in ${property.SitusCity}, ${property.SitusState}`}
    >
      <div className={styles.propertyDetails}>
        {Object.keys(property).map((key) => {
          const restrictedKeys = [
            'Status',
            'AgentId',
            'SearchId',
            'RawData',
            'id',
            'get_agent',
            'get_contact',
            'get_conversations',
            'created_at',
            'updated_at',
            'AssdTotalValueFloat',
            'AssdLandValueFloat',
            'AssdImprovementValueFloat',
            'MarketValueFloat',
          ]
          if (!showContacts) {
            restrictedKeys.push('EmailAddress', 'Phone', 'LineType')
          }
          if (restrictedKeys.includes(key)) {
            return null
          }

          return (
            <div key={key} className={styles.detail}>
              <p>{titleCase(key)}</p>
              <h5>{property[key] || '-'}</h5>
            </div>
          )
        })}
      </div>
    </Drawer>
  )
}

export default PropertyDetails
