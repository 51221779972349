import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message } from 'antd'

import { history } from '../../index'
import actions from './actions'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import axiosRequest from '../../helpers/axios-request.helper'
import localStorageHelper from '../../helpers/local-storage.helper'

export function* LOGIN({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { loading: true } })
    yield call(axiosRequest, 'sanctum/csrf-cookie', 'get', null, false)
    const login = yield call(axiosRequest, 'api/v1/auth/login', 'post', payload)
    console.log(login.data)
    if (login.data.success && login.data.accessToken) {
      localStorageHelper.set('apiToken', login.data.accessToken)
      message.success(`Welcome back, ${login.data.user.name}!`)
      yield put({
        type: actions.SET_STATE,
        payload: {
          user: login.data.user,
          authorized: true,
          loading: false,
          isAdmin: login.data.user.isAdmin,
          permissions: login.data.user.get_permissions || [],
        },
      })
      history.push('/')
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false, authorized: false },
      })
    }
  } catch (e) {
    axiosErrorHelper(e)
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false, authorized: false },
    })
  }
}

export function* LOGOUT() {
  try {
    yield call(axiosRequest, 'api/v1/users/logout', 'get', null, true)
    yield put({
      type: actions.SET_STATE,
      payload: {
        user: {},
        loading: false,
        dataLoading: false,
        authorized: false,
      },
    })
    localStorageHelper.remove('apiToken')
    history.push('/secured/login')
  } catch (e) {
    axiosErrorHelper(e)
    yield put({ type: actions.SET_STATE, payload: { loading: false } })
  }
}

export function* AUTH_CHECK() {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })
  let authorized = false
  const token = localStorageHelper.get('apiToken')
  try {
    if (!token) {
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, authorized: false },
      })
      history.push('/secured/login')
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: true, dataLoading: true },
      })
      const authCheck = yield call(
        axiosRequest,
        'api/v1/users/me',
        'get',
        null,
        true,
      )
      if (authCheck.data && authCheck.data.success) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
            dataLoading: false,
            authorized: true,
            user: authCheck.data.user,
            permissions: authCheck.data.user.get_permissions || [],
            isAdmin: authCheck.data.user.isAdmin,
          },
        })
        authorized = true
        if (/^\/secured(?=\/|$)/i.test(history.location.pathname)) {
          history.push('/')
        }
      }
    }
  } catch (e) {
    axiosErrorHelper(e)
    yield put({
      type: actions.SET_STATE,
      payload: {
        user: {},
        loading: false,
        dataLoading: false,
        authorized: false,
      },
    })
    if (!authorized && !/^\/secured(?=\/|$)/i.test(history.location.pathname)) {
      history.push('/secured/login')
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    AUTH_CHECK(),
  ])
}
