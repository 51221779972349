import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, InputNumber, Select, Button } from 'antd'
import ReactQuill from 'react-quill'

const FormBuilder = ({
  loading,
  formProps,
  fields,
  onFinish,
  formClass = 'default-form',
  btnSubmitClassName = 'col-12',
  btnSubmitText = 'Save',
  contentBefore = null,
  children = null,
}) => {
  const onSubmit = (values) => {
    onFinish(values)
  }

  return (
    <div className={formClass}>
      <Form
        onFinish={onSubmit}
        layout={formProps.layout}
        requiredMark={formProps.requiredMarks}
        initialValues={formProps.initialValues || {}}
        autoComplete="off"
        className="row"
      >
        {contentBefore && contentBefore}
        {fields.map((field) => (
          <Form.Item className={`col-12 ${field.className || ''}`} key={field.name} label={field.label}>
            {field.fieldType === 'input' && (
              <Form.Item
                noStyle
                name={field.name}
                rules={[
                  {
                    required: field.required,
                    message: 'This field is required',
                  },
                ]}
              >
                <Input type={field.type || 'text'} placeholder={field.placeholder || ''} />
              </Form.Item>
            )}
            {field.fieldType === 'inputNumber' && (
              <Form.Item
                noStyle
                name={field.name}
                rules={[
                  {
                    required: field.required,
                    message: 'This field is required',
                  },
                ]}
              >
                <InputNumber min={0} step={0.01} type={field.type || 'text'} placeholder={field.placeholder || ''} />
              </Form.Item>
            )}
            {field.fieldType === 'quill' && (
              <Form.Item
                noStyle
                name={field.name}
                rules={[
                  {
                    required: field.required,
                    message: 'This field is required',
                  },
                ]}
              >
                <ReactQuill theme="snow" />
              </Form.Item>
            )}
            {field.fieldType === 'textarea' && (
              <Form.Item
                noStyle
                name={field.name}
                rules={[
                  {
                    required: field.required,
                    message: 'This field is required',
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 12 }} placeholder={field.placeholder || ''} />
              </Form.Item>
            )}
            {field.fieldType === 'render' && (
              <Form.Item
                noStyle
                name={field.name}
                rules={[
                  {
                    required: field.required,
                    message: 'This field is required',
                  },
                ]}
              >
                {field.render()}
              </Form.Item>
            )}
            {field.fieldType === 'select' && (
              <Form.Item
                noStyle
                name={field.name}
                rules={[
                  {
                    required: field.required,
                    message: 'This field is required',
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  mode={field.select?.isMultiple ? 'multiple' : undefined}
                  loading={!!field.select?.loading}
                  placeholder={field.placeholder || ''}
                >
                  {field.select ? (
                    field.select.options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="">Выберите вариант из списка</Select.Option>
                  )}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
        ))}
        {children && children}
        <Form.Item className={`mb-0 ${btnSubmitClassName}`}>
          <Button loading={loading} htmlType="submit" className="w-100 btn-primary">
            {btnSubmitText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

FormBuilder.propTypes = {
  onFinish: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  btnSubmitClassName: PropTypes.string,
  btnSubmitText: PropTypes.string,
  formProps: PropTypes.shape({
    layout: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
    requiredMarks: PropTypes.bool.isRequired,
    initialValues: PropTypes.object,
  }),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      fieldType: PropTypes.oneOf(['input', 'inputNumber', 'textarea', 'select', 'render', 'quill']).isRequired,
      render: PropTypes.any,
      type: PropTypes.oneOf(['text', 'password', 'email']).isRequired,
      placeholder: PropTypes.string,
      className: PropTypes.string,
      select: PropTypes.shape({
        isMultiple: PropTypes.bool,
        loading: PropTypes.bool,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
      }),
    }),
  ),
  formClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.any]),
  contentBefore: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.any]),
}

export default FormBuilder
