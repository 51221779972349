import React from 'react'
import { DatePicker, Form, Select } from 'antd'

const PropertySettings = () => {
  return (
    <div className="row">
      <Form.Item
        className="col-12 col-md-6 mb-md-0"
        name={['main', 'isPublished']}
        label="Published"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select optionFilterProp="children">
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 mb-3"
        name={['main', 'isSold']}
        label="Sold"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select optionFilterProp="children">
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 mb-md-3"
        name={['main', 'featured']}
        label="Featured"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select optionFilterProp="children">
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 mb-md-0"
        name={['main', 'showcase']}
        label="Showcase"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select optionFilterProp="children">
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={['main', 'expiration_date']}
        label="Expiration Date"
        className="col-12 col-md-6 mb-md-0"
      >
        <DatePicker />
      </Form.Item>
      {/*<Form.Item*/}
      {/*  className="col-12 col-md-6 mb-md-0"*/}
      {/*  name={['main', 'linked_buildfortune']}*/}
      {/*  label="BuildFortune Link"*/}
      {/*  rules={[*/}
      {/*    {*/}
      {/*      required: true,*/}
      {/*      message: 'This field is required',*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*>*/}
      {/*  <Select optionFilterProp="children">*/}
      {/*    <Select.Option value={true}>Yes</Select.Option>*/}
      {/*    <Select.Option value={false}>No</Select.Option>*/}
      {/*  </Select>*/}
      {/*</Form.Item>*/}
      <Form.Item
        className="col-12 col-md-6 mb-md-0"
        name={['main', 'priority']}
        label="Priority"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select optionFilterProp="children">
          <Select.Option value={1}>High Priority</Select.Option>
          <Select.Option value={4}>Normal Priority</Select.Option>
        </Select>
      </Form.Item>
    </div>
  )
}

export default PropertySettings
