import React from 'react'
import moment from 'moment'
import { Button, Popconfirm } from 'antd'
import { RiEditFill, RiDeleteBin3Fill } from 'react-icons/ri'

import styles from './notes.module.scss'

import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

const Notes = ({ user, notes, setVisibility, reloadData }) => {
  const handleDeleteNote = (id) => {
    axiosRequest(`api/v1/crm/leads/data/notes/${id}`, 'delete', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  return (
    <div className={styles.notes}>
      {notes.map((note) => (
        <div key={note.id} className={styles.note}>
          <div className={styles.info}>
            <div className={styles.data}>
              <strong>{moment(note.created_at).format('DD MMM, YYYY')}</strong> by <strong>{note.user.name}</strong>
            </div>
            <div className={styles.actions}>
              {user.id === note.user.id && (
                <>
                  <Button
                    className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                    onClick={() => {
                      setVisibility(true, note)
                    }}
                  >
                    <RiEditFill />
                  </Button>
                  <Popconfirm
                    title={`Are you really want to delete this note?`}
                    onConfirm={() => handleDeleteNote(note.id)}
                    okButtonProps={{ className: `btn-sm btn-danger` }}
                    cancelButtonProps={{ className: `btn-sm` }}
                  >
                    <Button className="me-2 btn-sm btn-danger btn-danger__simple no-svg-padding">
                      <RiDeleteBin3Fill />
                    </Button>
                  </Popconfirm>
                </>
              )}
            </div>
          </div>
          <div className={styles.noteDescription} dangerouslySetInnerHTML={{ __html: note.note }} />
        </div>
      ))}
    </div>
  )
}

export default Notes
