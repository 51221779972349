import React from 'react'
import ReactQuill from 'react-quill'
import { Form, Input, Select } from 'antd'

const PropertyDetails = ({ fieldsData }) => {
  return (
    <div className="row">
      <Form.Item
        className="col-12 col-md-6 col-lg-8"
        name={['main', 'name']}
        label="Name"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property name" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 col-lg-4"
        name={['main', 'parcelNumber']}
        label="Parcel Number"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input placeholder="Property parcel number" />
      </Form.Item>
      <Form.Item
        className="col-12"
        name={['main', 'company']}
        label="Company"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          placeholder="Property company"
        >
          {fieldsData.companies.map((company) => (
            <Select.Option key={company.id} value={parseInt(company.id, 10)}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="col-12"
        name="categories"
        label="Categories"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          placeholder="Property categories"
          mode="multiple"
          maxTagCount="responsive"
        >
          {fieldsData.categories.map((categories) => (
            <Select.Option key={categories.id} value={categories.id}>
              {categories.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="col-12"
        name="websites"
        label="Websites"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          placeholder="Property websites"
          mode="multiple"
          maxTagCount="responsive"
        >
          {fieldsData.websites.map((website) => (
            <Select.Option key={website.id} value={website.id}>
              {website.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="col-12"
        name={['main', 'description']}
        label="Description"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <ReactQuill theme="snow" />
      </Form.Item>
      <Form.Item
        className="col-12 mb-0"
        name={['main', 'ownerFinanceTerms']}
        label="Owner Finance Terms"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <ReactQuill theme="snow" />
      </Form.Item>
    </div>
  )
}

export default PropertyDetails
