import React from 'react'
import _ from 'lodash'
import { Button, Form, InputNumber, Select } from 'antd'

import PageTitle from '../../../../ui/title/PageTitle'

import {
  statesFips,
  countiesFips,
} from '../../../../../data/states/fips-county-codes'
import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

const ParserForm = ({ setProperties, setDrawer }) => {
  const [form] = Form.useForm()
  const [searchLoading, setSearchLoading] = React.useState(false)
  const [countiesToSelect, setCountiesToSelect] = React.useState([])

  const onStateSelect = (value) => {
    form.setFieldsValue({ counties: [] })
    setCountiesToSelect(_.filter(countiesFips, { stateFips: value }))
  }

  const onFinish = (values) => {
    setSearchLoading(true)
    axiosRequest('api/v1/acquisition/data/admin/search', 'post', values, true)
      .then((res) => {
        if (res.data) {
          setProperties(res.data)
          setDrawer(true)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setSearchLoading(false)
      })
  }

  return (
    <>
      <div className="mb-5">
        <PageTitle title="Parser Form" />
        <Form
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
          form={form}
          className="row"
          initialValues={{
            limit: 100,
            corporateOwned: '0',
            productNames: 'PropertyCharacteristicsExport',
          }}
        >
          <Form.Item
            name="productNames"
            label="Report Type"
            className="col-12"
            rules={[{ required: true, message: 'Please select a state' }]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={onStateSelect}
            >
              <Select.Option value="PropertyCharacteristicsExport">
                Property Characteristics Export
              </Select.Option>
              <Select.Option value="PropertyDetailExport">
                Property Detail Export
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="limit"
            label="Max. Results"
            className="col-12 col-md-6"
            rules={[
              { required: true, message: 'Please select a max. return limit' },
            ]}
          >
            <InputNumber min={1} max={1000} step={1} className="w-100" />
          </Form.Item>
          <Form.Item
            name="corporateOwned"
            label="Corporate Owned"
            className="col-12 col-md-6"
            rules={[{ required: true, message: 'Please select a state' }]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={onStateSelect}
            >
              <Select.Option value="0">Exclude Corporate Owned</Select.Option>
              <Select.Option value="1">Only Corporate Owned</Select.Option>
              <Select.Option value="2">
                Include All Ownership Types
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            className="col-12 col-md-6"
            rules={[{ required: true, message: 'Please select a state' }]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={onStateSelect}
            >
              {Object.keys(statesFips).map((stateKey) => (
                <Select.Option key={stateKey} value={stateKey.toString()}>
                  {statesFips[stateKey]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="counties"
            label="County"
            className="col-12 col-md-6"
            rules={[
              { required: true, message: 'Please select at least one county' },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
            >
              {countiesToSelect.map((county) => (
                <Select.Option
                  key={`${county.countyFips}${county.stateFips}${county.state}`}
                  value={`${county.stateFips.toString()}${county.countyFips.toString()}`}
                >
                  {county.countyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="col-12 col-md-6" label="Lot Acreage">
            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="lotAcreageMin"
                  label="From"
                  rules={[{ required: false }]}
                >
                  <InputNumber
                    allowClear
                    min={0}
                    max={999999}
                    step={1}
                    className="w-100"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="lotAcreageMax"
                  label="To"
                  rules={[{ required: false }]}
                >
                  <InputNumber min={0} max={1000} step={1} className="w-100" />
                </Form.Item>
              </div>
            </div>
          </Form.Item>
          <Form.Item className="col-12 col-md-6" label="Assessed Total Value">
            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="assessedTotalValueMin"
                  label="From"
                  rules={[{ required: false }]}
                >
                  <InputNumber
                    allowClear
                    min={0}
                    max={999999}
                    step={1}
                    className="w-100"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="assessedTotalValueMax"
                  label="To"
                  rules={[{ required: false }]}
                >
                  <InputNumber min={0} max={1000} step={1} className="w-100" />
                </Form.Item>
              </div>
            </div>
          </Form.Item>
          <Form.Item className="col-12 col-md-6" label="Assessed Land Value">
            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="assessedLandValueMin"
                  label="From"
                  rules={[{ required: false }]}
                >
                  <InputNumber
                    allowClear
                    min={0}
                    max={999999}
                    step={1}
                    className="w-100"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="assessedLandValueMax"
                  label="To"
                  rules={[{ required: false }]}
                >
                  <InputNumber min={0} max={1000} step={1} className="w-100" />
                </Form.Item>
              </div>
            </div>
          </Form.Item>
          <Form.Item
            className="col-12 col-md-6"
            label="Assessed Improvement Value"
          >
            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="assessedImprovementValueMin"
                  label="From"
                  rules={[{ required: false }]}
                >
                  <InputNumber
                    allowClear
                    min={0}
                    max={999999}
                    step={1}
                    className="w-100"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="assessedImprovementValueMax"
                  label="To"
                  rules={[{ required: false }]}
                >
                  <InputNumber min={0} max={1000} step={1} className="w-100" />
                </Form.Item>
              </div>
            </div>
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              htmlType="submit"
              className="btn-sm btn-primary"
              loading={searchLoading}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default ParserForm
