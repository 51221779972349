import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import _ from 'lodash'
import moment from 'moment'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const SentReceivedChart = ({ data }) => {
  return (
    <div style={{ height: '320px', position: 'relative' }}>
      <Line
        data={{
          labels: _.map(data, (day) => moment(day.startOfDay).format('MMM DD')),
          datasets: [
            {
              label: 'Sent',
              data: _.map(data, (day) => day.sent),
              backgroundColor: '#007bff',
              fill: true,
              borderRadius: 6,
              borderColor: '#007bff',
              borderWidth: 1,
              barPercentage: 0.5,
              barThickness: 24,
            },
            {
              label: 'Received',
              data: _.map(data, (day) => day.received),
              backgroundColor: '#20c997',
              fill: true,
              borderRadius: 6,
              borderColor: '#20c997',
              borderWidth: 1,
              barPercentage: 0.5,
              barThickness: 24,
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: false,
            },
            tooltip: {
              displayColors: true,
              callbacks: {
                label: function (tooltipItem, data) {
                  return `${tooltipItem.formattedValue} ${
                    tooltipItem.dataset.label || ''
                  } Messages`
                },
              },
            },
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },
            y: {
              display: true,
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },
            yAxis: {
              display: true,
              ticks: {
                display: false,
              },
              grid: {
                color: '#ebebeb',
                lineWidth: 1,
              },
              border: { display: false },
            },
            xAxis: {
              display: false,
              ticks: {
                display: false,
              },
              grid: {
                display: false,
              },
              border: { display: false },
            },
          },
        }}
      />
    </div>
  )
}

export default SentReceivedChart
