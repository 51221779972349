import LoginPage from '../pages/secured/Login'

const authRoutes = [
  {
    path: '/secured/login',
    Component: LoginPage,
  },
]

export default authRoutes
