import React, { useState } from 'react'
import { Button, Drawer, Form, Input, Tabs, Badge } from 'antd'
import moment from 'moment'

import styles from './property-conversation.module.scss'
import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

const tabs = [
  { key: 'Trumpia', name: 'Trumpia' },
  { key: 'SMSBulk', name: 'Bulk SMS' },
  { key: 'ClickSend', name: 'ClickSend' },
  { key: 'SMS Gateway', name: 'SMS Gateway' },
  { key: 'SMS - SimpleTexting', name: 'SMS - SimpleTexting' },
  { key: 'Email', name: 'Email' },
  { key: 'Vicidial', name: 'Dialler' },
  { key: 'Not Defined', name: 'Not Defined' },
]

const PropertyConversation = ({
  getData,
  property,
  visible,
  setVisibility,
}) => {
  const [activeKey, setActiveKey] = useState('Trumpia')
  const [loading, setLoading] = useState(false)

  if (!property || !property.get_contact) {
    return null
  }

  const onMessageSend = (values) => {
    setLoading(true)
    axiosRequest(
      `api/v1/acquisition/data/properties/contacts/custom-send/${property.id}/${property.get_contact.id}?source=${activeKey}`,
      'post',
      {
        source: activeKey,
        message: values.message,
      },
      true,
    )
      .then(() => {
        getData()
        setVisibility()
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onTabChange = (tab) => {
    if (
      property.get_contact.UnreadMessageSource === tab &&
      property.get_contact.HaveUnreadMessage
    ) {
      axiosRequest(
        `api/v1/acquisition/data/properties/contacts/read-message/${property.get_contact.id}`,
        'get',
        null,
        true,
      )
        .then(() => {
          getData(false)
        })
        .catch((e) => axiosErrorHelper(e))
    }
  }

  return (
    <Drawer
      visible={visible}
      onClose={() => setVisibility()}
      footer={
        <>
          {property.Status === 'active' ? (
            <div
              className={
                [
                  'Trumpia',
                  'SMSBulk',
                  'ClickSend',
                  'SMS Gateway',
                  'SMS - SimpleTexting',
                  'Email',
                ].includes(activeKey)
                  ? ''
                  : 'd-none'
              }
            >
              <Form
                layout="vertical"
                requiredMark={false}
                onFinish={onMessageSend}
              >
                <Form.Item
                  name="message"
                  rules={[
                    { required: true, message: 'This field is required' },
                  ]}
                >
                  <Input.TextArea placeholder="Type a message" />
                </Form.Item>
                <Form.Item className="mb-0 text-end">
                  <Button
                    className="btn btn-sm btn-primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : null}
        </>
      }
      width={600}
      destroyOnClose
      title={`${property.ACRES} acre in ${property.SitusCity}, ${property.SitusState}`}
    >
      <div>
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onTabClick={(key) => {
            setActiveKey(key)
            onTabChange(key)
          }}
        >
          {tabs.map((tab) => (
            <Tabs.TabPane
              tab={
                property.get_contact.UnreadMessageSource === tab.key &&
                property.get_contact.HaveUnreadMessage ? (
                  <Badge dot color="orange" size="small">
                    {tab.name}
                  </Badge>
                ) : (
                  tab.name
                )
              }
              key={tab.key}
            >
              <div className={styles.propertyConversation}>
                <div className={styles.wrapper}>
                  {property.get_contact.get_messages.map((conversation) => {
                    if (
                      tab.key === conversation.Source ||
                      (tab.key === 'Not Defined' &&
                        conversation.Source === null)
                    ) {
                      return (
                        <div
                          key={conversation.id}
                          className={styles.message}
                          data-direction={conversation.MessageType}
                        >
                          <div className={styles.header}>
                            <div className={styles.name}>
                              {conversation.MessageType === 'in'
                                ? property.OwnerName1Full
                                : 'PortalStation'}
                            </div>
                            <div className={styles.date}>
                              {moment(conversation.created_at).format(
                                'YYYY-MM-DD',
                              )}
                            </div>
                          </div>
                          <div
                            className={styles.body}
                            dangerouslySetInnerHTML={{
                              __html: conversation.Body,
                            }}
                          />
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </Drawer>
  )
}

export default PropertyConversation
