import React from 'react'
import { message } from 'antd'
import { RiAddFill } from 'react-icons/ri'

import styles from './upload-button.module.scss'

import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'

const UploadButton = ({ uploadType = 'lead', dataId = null, reloadData }) => {
  const uploadHandler = (e) => {
    message.loading({ content: 'Please, wait...', key: 'uploadImages', duration: 60 })
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      let url = null
      switch (uploadType) {
        case 'lead':
          url = `api/v1/upload/file?lead=${dataId}`
          break
        case 'property':
          url = `api/v1/upload/file?property=${dataId}`
          break
        default:
          break
      }
      if (!url) {
        return false
      }
      axiosRequest(url, 'post', formData, true)
        .then((res) => {
          if (res.data && res.data.success) {
            reloadData()
            message.success({ content: 'Done', key: 'uploadImages' })
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
          message.error({ content: 'Image not uploaded. Try again or contact customers support.', key: 'uploadImages' })
        })
    } else {
      message.error({ content: 'Image not uploaded. Try again or contact customers support.', key: 'uploadImages' })
    }
    e.target.value = ''
  }

  return (
    <div className={styles.uploadButton}>
      <input type="file" onChange={(e) => uploadHandler(e)} />
      <div className={styles.button}>
        <RiAddFill /> Upload File
      </div>
    </div>
  )
}

export default UploadButton
