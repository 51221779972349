import React, { useEffect, useState } from 'react'
import { Pagination, Spin } from 'antd'
import moment from 'moment/moment'

import Table from '../../../../ui/table/Table'

import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

const ActivityLogs = ({ activeTab }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({ data: [], total: 0 })
  const [filters, setFilters] = useState({ page: 1, perPage: 24 })

  useEffect(() => {
    if (activeTab === 'activityLogs') {
      setLoading(true)
      axiosRequest(
        `api/v1/acquisition/activity-logs?page=${filters.page}`,
        'get',
        null,
        true,
      )
        .then((res) => {
          if (res.data && res.data.success) {
            setData(res.data.logs)
          }
        })
        .catch((e) => axiosErrorHelper(e))
        .finally(() => setLoading(false))
    }
  }, [activeTab, filters])

  return (
    <Spin spinning={loading} tip="Loading...">
      <Table
        columns={[
          {
            key: 'date',
            name: 'Date',
            headClassName: null,
            itemClassName: null,
            render: (item) =>
              moment(item.created_at).format('YYYY-MM-DD HH:mm (Z)'),
          },
          {
            key: 'log',
            name: 'Log',
            headClassName: null,
            itemClassName: null,
            render: (item) => item.log,
          },
        ]}
        data={data.data}
      />
      {filters.perPage < data.total && (
        <Pagination
          className="mt-4"
          size="small"
          current={filters.page}
          pageSize={filters.perPage}
          total={data.total}
          showSizeChanger={false}
          onChange={(page) => {
            if (window) {
              window.scrollTo(0, 0)
            }
            setFilters({ ...filters, page })
          }}
        />
      )}
    </Spin>
  )
}

export default ActivityLogs
