import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
} from 'antd'
import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'

const FiltersDrawer = ({
  visible,
  setVisibility,
  filters,
  setFilters,
  activeTab,
  isLeadsPool = false,
  hideManager = false,
}) => {
  const [form] = Form.useForm()
  const [usersLoading, setUsersLoading] = useState(true)
  const [searchesLoading, setSearchesLoading] = useState(true)
  const [managers, setManagers] = useState([])
  const [searches, setSearches] = useState([])
  const [leadFilters, setLeadFilters] = useState({})

  useEffect(() => {
    if (visible) {
      setUsersLoading(true)
      setSearchesLoading(true)
      axiosRequest(
        'api/v1/users?page=1&perPage=9999&type=acquisition',
        'get',
        null,
        true,
      )
        .then((res) => {
          setManagers(res.data.users.users)
        })
        .catch((e) => axiosErrorHelper(e))
        .finally(() => setUsersLoading(false))
      if (!hideManager) {
        axiosRequest(
          'api/v1/acquisition/data/admin/searches',
          'get',
          null,
          true,
        )
          .then((res) => {
            setSearches(res.data.data)
          })
          .catch((e) => axiosErrorHelper(e))
          .finally(() => setSearchesLoading(false))
      }
    }
  }, [visible, hideManager])

  const handleFilters = () => {
    form.setFieldsValue({ ...leadFilters })
    setFilters({ ...filters, ...leadFilters, page: 1 })
  }

  const clearFilters = () => {
    setLeadFilters({})
    form.setFieldsValue({
      q: null,
      uploaderFile: null,
      onlyNewMessages: null,
      apn: null,
      manager: null,
      leadStatus: null,
      contactName: null,
      lineType: null,
      contactEmail: null,
      contactPhone: null,
      sizeAcresFrom: null,
      sizeAcresTo: null,
      noContactsLeads: null,
      marketValueFrom: null,
      marketValueTo: null,
      assdTotalValueFrom: null,
      assdTotalValueTo: null,
      assdLandValueFrom: null,
      assdLandValueTo: null,
      assdImprovementValueFrom: null,
      assdImprovementValueTo: null,
    })
    setFilters({ page: 1, perPage: 24 })
    setVisibility(false)
  }

  return (
    <Drawer
      title="Filters"
      visible={visible}
      destroyOnClose
      width={480}
      onClose={() => {
        setVisibility(false)
      }}
      footer={
        <>
          <div className="row">
            <div className="col-6">
              <Button className="w-100" onClick={() => clearFilters()}>
                Clear
              </Button>
            </div>
            <div className="col-6">
              <Button
                className="btn-primary w-100"
                onClick={() => handleFilters()}
              >
                Submit
              </Button>
            </div>
          </div>
        </>
      }
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="default-form"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleFilters()
          }
        }}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          initialValues={filters}
          form={form}
          className="row"
        >
          <Form.Item name="q" label="Search" className="col-12">
            <Input
              allowClear
              placeholder="Search..."
              onChange={(e) =>
                setLeadFilters({ ...leadFilters, q: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item name="apn" label="APN" className="col-12">
            <Input
              allowClear
              placeholder="Search by APN number"
              onChange={(e) =>
                setLeadFilters({ ...leadFilters, apn: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item name="onlyNewMessages" className="col-12">
            <Checkbox
              checked={filters.onlyNewMessages || false}
              onChange={(e) =>
                setFilters({ ...filters, onlyNewMessages: e.target.checked })
              }
            >
              Show only new messages
            </Checkbox>
          </Form.Item>
          {!hideManager && (
            <Form.Item
              name="uploaderFile"
              label="CSV Uploaded File"
              className="col-12"
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Search by CSV files..."
                loading={searchesLoading}
                onChange={(val) => {
                  setLeadFilters({ ...leadFilters, uploaderFile: val })
                }}
              >
                {searches.map((search) => (
                  <Select.Option key={`search_${search.id}`} value={search.id}>
                    {search.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {!hideManager && (
            <Form.Item name="manager" label="Manager" className="col-12">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Search by manager..."
                loading={usersLoading}
                onChange={(val) =>
                  setLeadFilters({ ...leadFilters, manager: val })
                }
              >
                {managers &&
                  managers.length >= 0 &&
                  managers.map((manager) => (
                    <Select.Option key={manager.id} value={manager.id}>
                      {manager.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="sizeAcresFrom"
            label="Size Acres"
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="From"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, sizeAcresFrom: val })
              }
            />
          </Form.Item>
          <Form.Item name="sizeAcresTo" label=" " className="col-12 col-md-6">
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="To"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, sizeAcresTo: val })
              }
            />
          </Form.Item>
          {activeTab === 'all' && (
            <Form.Item name="leadStatus" label="Status" className="col-12">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Search by property status..."
                onChange={(val) =>
                  setLeadFilters({ ...leadFilters, leadStatus: val })
                }
              >
                <Select.Option value="unprocessed">Unprocessed</Select.Option>
                <Select.Option value="req_sent">
                  Waiting for Response
                </Select.Option>
                <Select.Option value="not_answered">Not Answered</Select.Option>
                <Select.Option value="active">Active</Select.Option>
                <Select.Option value="LP_InNegotiations">
                  LP In Negotiations
                </Select.Option>
                <Select.Option value="LP_WaitingForApproval">
                  LP Waiting for Approval
                </Select.Option>
                <Select.Option value="LP_CallBack">LP Call Back</Select.Option>
                <Select.Option value="lost">Closed Lost</Select.Option>
                <Select.Option value="success">Closed Won</Select.Option>
              </Select>
            </Form.Item>
          )}
          {!isLeadsPool && activeTab === 'all' ? (
            <Form.Item name="noContactsLeads" valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  setLeadFilters({
                    ...leadFilters,
                    noContactsLeads: e.target.checked ? '1' : null,
                  })
                }}
              >
                Show leads without contact information
              </Checkbox>
            </Form.Item>
          ) : null}
          <Divider className="my-0 py-3 pt-1" />
          <Form.Item
            name="marketValueFrom"
            label="Market Value"
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="From"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, marketValueFrom: val })
              }
            />
          </Form.Item>
          <Form.Item name="marketValueTo" label=" " className="col-12 col-md-6">
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="To"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, marketValueTo: val })
              }
            />
          </Form.Item>
          <Form.Item
            name="assdTotalValueFrom"
            label="Assd. Total Value"
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="From"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, assdTotalValueFrom: val })
              }
            />
          </Form.Item>
          <Form.Item
            name="assdTotalValueTo"
            label=" "
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="To"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, assdTotalValueTo: val })
              }
            />
          </Form.Item>
          <Form.Item
            name="assdLandValueFrom"
            label="Assd. Land Value"
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="From"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, assdLandValueFrom: val })
              }
            />
          </Form.Item>
          <Form.Item
            name="assdLandValueTo"
            label=" "
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="To"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, assdLandValueTo: val })
              }
            />
          </Form.Item>
          <Form.Item
            name="assdImprovementValueFrom"
            label="Assd. Improvement Value"
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="From"
              onChange={(val) =>
                setLeadFilters({
                  ...leadFilters,
                  assdImprovementValueFrom: val,
                })
              }
            />
          </Form.Item>
          <Form.Item
            name="assdImprovementValueTo"
            label=" "
            className="col-12 col-md-6"
          >
            <InputNumber
              min={0}
              step={0.01}
              allowClear
              placeholder="To"
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, assdImprovementValueTo: val })
              }
            />
          </Form.Item>
          <Divider className="my-0 py-3 pt-1" />
          {!isLeadsPool && (
            <Form.Item
              name="contactName"
              label="Contact Name"
              className="col-12"
            >
              <Input
                allowClear
                placeholder="Search contact full name"
                onChange={(e) =>
                  setLeadFilters({
                    ...leadFilters,
                    contactName: e.target.value,
                  })
                }
              />
            </Form.Item>
          )}
          <Form.Item name="lineType" label="Line Type" className="col-12">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Search by property status..."
              onChange={(val) =>
                setLeadFilters({ ...leadFilters, lineType: val })
              }
            >
              <Select.Option value="Landline">Landline</Select.Option>
              <Select.Option value="Mobile">Mobile</Select.Option>
              <Select.Option value="Voip">Voip</Select.Option>
            </Select>
          </Form.Item>
          {!isLeadsPool && (
            <>
              <Form.Item
                name="contactEmail"
                label="Contact Email"
                className="col-12 col-md-6"
              >
                <Input
                  allowClear
                  placeholder="Search by contact email"
                  onChange={(e) =>
                    setLeadFilters({
                      ...leadFilters,
                      contactEmail: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                name="contactPhone"
                label="Contact Phone"
                className="col-12 col-md-6"
              >
                <Input
                  allowClear
                  placeholder="Search by contact phone"
                  onChange={(e) =>
                    setLeadFilters({
                      ...leadFilters,
                      contactPhone: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default FiltersDrawer
