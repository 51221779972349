import React from 'react'
import { Modal } from 'antd'

const RequestDetailsModal = ({ data = null, visibility = false, setVisibility = (f) => f }) => {
  if (!data || !data.id) {
    return null
  }

  return (
    <Modal visible={visibility} title="Request Details" footer={false} destroyOnClose onCancel={() => setVisibility()}>
      <div className="row">
        <p className="col-12 col-md-6">
          Website:{' '}
          <strong>
            {data.get_website && data.get_website.id ? (
              <a href={data.get_website.domain} target="_blank" rel="noreferrer nofollow noopener">
                {data.get_website.name}
              </a>
            ) : (
              ''
            )}
          </strong>
        </p>
        <p className="col-12 col-md-6">
          Full Name: <strong>{data.fullName || ''}</strong>
        </p>
        <p className="col-12 col-md-6">
          Email: <strong>{data.email || ''}</strong>
        </p>
        <p className="col-12 col-md-6">
          Phone: <strong>{data.phone || ''}</strong>
        </p>
        <p className="col-12 col-md-6">
          State: <strong>{data.state || ''}</strong>
        </p>
        <p className="col-12 col-md-6">
          County: <strong>{data.county || ''}</strong>
        </p>
        <p className="col-12 col-md-6">
          Address: <strong>{data.address || ''}</strong>
        </p>
        <p className="col-12 col-md-6">
          HOA: <strong>{data.hoa || ''}</strong>
        </p>
        <p className="col-12 col-md-6">
          HOA Fees Owed: <strong>{data.hoaFeesOwed || ''}</strong>
        </p>
        <p className="col-12 col-md-6">
          Taxes Owed: <strong>{data.taxesOwed || ''}</strong>
        </p>
        <p className="mb-1">Description:</p>
        <p className="col-12">{data.description || 'Description Missing'}</p>
      </div>
    </Modal>
  )
}

export default RequestDetailsModal
