import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Button, Pagination, Popconfirm, Spin } from 'antd'
import {
  RiAddFill,
  RiCloseFill,
  RiCheckFill,
  RiEditFill,
  RiDeleteBin3Fill,
  RiSendPlaneFill,
  RiFileCopyFill,
} from 'react-icons/ri'

import PageTitle from '../../components/ui/title/PageTitle'
import DefaultCard from '../../components/ui/cards/DefaultCard'
import Table from '../../components/ui/table/Table'

import axiosRequest from '../../helpers/axios-request.helper'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import { history } from '../../index'
import moment from 'moment'

const MailingsListPage = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({ page: 1, perPage: 24 })

  const getData = () => {
    setLoading(true)
    axiosRequest(`api/v1/mailings?page=${filters.page}&perPage=${filters.perPage}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          setData({ list: res.data.list, total: res.data.total })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleDeleteItem = (slug) => {
    axiosRequest(`api/v1/mailings/${slug}`, 'delete', null, true)
      .then(() => {
        getData()
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const handleSendItem = (slug) => {
    axiosRequest(`api/v1/mailings/actions/send/${slug}`, 'get', null, true)
      .then(() => {
        getData()
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const handleCloneItem = (slug) => {
    axiosRequest(`api/v1/mailings/actions/clone/${slug}`, 'get', null, true)
      .then(() => {
        getData()
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  return (
    <>
      <Helmet title="Newsletters" />
      <PageTitle
        title="Newsletters"
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/mailings', name: 'Newsletters' },
        ]}
        extraButtons={
          <>
            <Button icon={<RiAddFill />} className="btn-primary fw-500" onClick={() => history.push('/mailings/create')}>
              New Campaign
            </Button>
          </>
        }
      />
      <DefaultCard>
        <Spin spinning={loading}>
          <Table
            columns={[
              {
                key: 'name',
                name: 'Name',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    <p className="mb-0">
                      <strong>{item.name}</strong>
                    </p>
                  </>
                ),
              },
              {
                key: 'website',
                name: 'Website',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    <p className="mb-0">{item.get_website.name}</p>
                  </>
                ),
              },
              {
                key: 'emails',
                name: 'Emails',
                headClassName: null,
                itemClassName: null,
                render: (item) => item.emails.length,
              },
              {
                key: 'properties',
                name: 'Properties',
                headClassName: null,
                itemClassName: null,
                render: (item) => item.properties.length,
              },
              {
                key: 'isSent',
                name: 'Is Sent?',
                headClassName: null,
                itemClassName: null,
                render: (item) =>
                  item.isSent ? (
                    <span className="success-color">
                      <RiCheckFill />
                    </span>
                  ) : (
                    <span className="warning-color">
                      <RiCloseFill />
                    </span>
                  ),
              },
              {
                key: 'addedDate',
                name: 'Added',
                headClassName: null,
                itemClassName: null,
                render: (item) => moment(item.created_at).format('DD-MM-YYYY'),
              },
              {
                key: 'actions',
                name: 'Actions',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {!item.isSent && (
                      <Popconfirm
                        title={`Are you really want to send this emails?`}
                        onConfirm={() => handleSendItem(item.slug)}
                        okButtonProps={{ className: `btn-sm btn-success` }}
                        cancelButtonProps={{ className: `btn-sm` }}
                      >
                        <Button className="me-2 btn-sm btn-success btn-success__simple no-svg-padding">
                          <RiSendPlaneFill /> <span className="ps-1">Send</span>
                        </Button>
                      </Popconfirm>
                    )}
                    <Button
                      className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                      onClick={() => history.push(`/mailings/${item.slug}`)}
                    >
                      <RiEditFill />
                    </Button>
                    <Popconfirm
                      title={`Are you really want to clone this item?`}
                      onConfirm={() => handleCloneItem(item.slug)}
                      okButtonProps={{ className: `btn-sm btn-warning` }}
                      cancelButtonProps={{ className: `btn-sm` }}
                    >
                      <Button className="me-2 btn-sm btn-warning btn-warning__simple no-svg-padding">
                        <RiFileCopyFill />
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title={`Are you really want to delete this item?`}
                      onConfirm={() => handleDeleteItem(item.slug)}
                      okButtonProps={{ className: `btn-sm btn-danger` }}
                      cancelButtonProps={{ className: `btn-sm` }}
                    >
                      <Button className={`btn-sm no-svg-padding btn-danger btn-danger__simple`}>
                        <RiDeleteBin3Fill />
                      </Button>
                    </Popconfirm>
                  </>
                ),
              },
            ]}
            data={data.list || []}
          />
          {data.total && filters.perPage < data.total && (
            <Pagination
              className="mt-4"
              size="small"
              current={filters.page}
              pageSize={filters.perPage}
              total={data.total || 0}
              showSizeChanger={false}
              onChange={(page) => setFilters({ ...filters, page })}
            />
          )}
        </Spin>
      </DefaultCard>
    </>
  )
}

export default MailingsListPage
