import React, { useEffect, useState } from 'react'
import { Drawer, Skeleton } from 'antd'
import axiosRequest from '../../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../../helpers/axios-error.helper'
import DefaultCard from '../../../../../ui/cards/DefaultCard'
import LeadsTable from '../../../../crm/leads/LeadsTable'

const LeadsListDrawer = ({ visible = false, onClose, slug, permissions, user }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [title, setTitle] = useState(null)

  const getData = () => {
    setLoading(true)
    axiosRequest(`api/v1/listings/properties/${slug}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          setData(res.data.property && res.data.property.leads ? res.data.property.leads : [])
          console.log(res.data.property)
          setTitle(`${res.data.property.name} | ${res.data.property.propertyNumber || ''}`)
          setLoading(false)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  useEffect(() => {
    if (slug) {
      getData()
    } else {
      setData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  return (
    <Drawer
      title={title || 'Leads'}
      visible={visible}
      destroyOnClose
      onClose={() => {
        onClose({ visible: false, slug: null })
      }}
      className="property-form-drawer"
    >
      {loading || !data ? (
        <Skeleton active />
      ) : (
        <DefaultCard>
          <LeadsTable
            leads={data}
            reloadData={getData}
            user={user}
            allowDelete={false}
            allowEdit={false}
            permissions={permissions}
          />
        </DefaultCard>
      )}
    </Drawer>
  )
}

export default LeadsListDrawer
