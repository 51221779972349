import React, { useEffect, useState } from 'react'
import { Drawer, Skeleton } from 'antd'

import DefaultCard from '../../../ui/cards/DefaultCard'
import Details from './lead-details/Details'
import Customer from './lead-details/Customer'
import LeadNotes from './LeadNotes'

import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'
import LeadFiles from './lead-details/LeadFiles'

const LeadDetails = ({ user, id, visible, setVisibility }) => {
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState('Loading...')
  const [data, setData] = useState(null)

  const getData = () => {
    if (id) {
      setLoading(true)
      axiosRequest(`api/v1/crm/leads/${id}`, 'get', null, true)
        .then((res) => {
          if (res.data && res.data.lead) {
            setData(res.data.lead)
            setTitle(res.data.lead.name)
            setLoading(false)
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Drawer
      title={title}
      visible={visible}
      destroyOnClose
      onClose={() => {
        setVisibility({ visible: false, id: null })
      }}
      className="property-form-drawer"
    >
      {loading || !data ? (
        <Skeleton active />
      ) : (
        <div className="row">
          <div className="col-12 col-lg-4 mb-4">
            <DefaultCard className="mb-4">
              <Details lead={data} />
            </DefaultCard>
            <DefaultCard className="mb-4">
              <Customer lead={data} />
            </DefaultCard>
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <LeadNotes user={user} notes={data.notes} lead={data} reloadData={getData} />
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <div className="mb-4">
              <LeadFiles files={data.files || []} reloadData={getData} leadId={data.id} uploadType="lead" />
            </div>
            <DefaultCard title="Tasks" className="mb-4">
              <p className="mb-0">Tasks under development</p>
            </DefaultCard>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default LeadDetails
