import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import styles from '../../../main.module.scss'

const MenuItem = ({ menu, expandedMenu = [], setExpandedMenu = (f) => f, withIcon = false }) => {
  if (!withIcon) {
    return <Link to={menu.path}>{menu.name}</Link>
  }

  return (
    <Link
      to={menu.path}
      onClick={(e) => {
        if (menu.childs) {
          e.preventDefault()
          const expandedMenus = expandedMenu
          if (expandedMenus.includes(menu.key)) {
            expandedMenus.splice(_.findIndex(expandedMenus, menu.key), 1)
          } else {
            expandedMenus.push(menu.key)
          }
          setExpandedMenu([...expandedMenus])
        }
      }}
    >
      <div className={styles.menuItem}>
        <div className={styles.menuIcon}>{menu.icon}</div>
        <div className={styles.menuName}>{menu.name}</div>
        {menu.childs && (
          <div className={styles.menuExpand}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        )}
      </div>
    </Link>
  )
}

export default MenuItem
