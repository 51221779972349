import React, { useState } from 'react'
import _ from 'lodash'
import { Modal, Form, Select } from 'antd'

import FormBuilder from '../../../ui/forms/FormBuilder'

import leadSources from '../../../../data/lead-sources'

import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'

const LeadForm = ({ initialData, statuses, visibility, setVisibility, reloadData }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    users: {
      loading: false,
      data: [],
    },
    customers: {
      loading: false,
      data: [],
    },
    properties: {
      loading: false,
      data: [],
    },
  })

  const searchUsers = (value) => {
    setData({
      ...data,
      users: {
        ...data.users,
        loading: true,
      },
    })
    axiosRequest(`api/v1/users/actions/autocomplete?q=${value}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.users) {
          setData({
            ...data,
            users: {
              ...data.users,
              data: res.data.users,
              loading: false,
            },
          })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const searchProperties = (value) => {
    setData({
      ...data,
      properties: {
        ...data.properties,
        loading: true,
      },
    })
    axiosRequest(`api/v1/listings/properties/actions/autocomplete?q=${value}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.properties) {
          setData({
            ...data,
            properties: {
              ...data.properties,
              data: res.data.properties,
              loading: false,
            },
          })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const searchCustomers = (value) => {
    setData({
      ...data,
      customers: {
        ...data.customers,
        loading: true,
      },
    })
    axiosRequest(`api/v1/crm/customers/actions/autocomplete?q=${value}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.customers) {
          setData({
            ...data,
            customers: {
              ...data.customers,
              data: res.data.customers,
              loading: false,
            },
          })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const handleFormSubmit = (values) => {
    setLoading(true)
    let data = {
      ...values,
      manager: values.manager ? values.manager.value : null,
      customer: values.customer ? values.customer.value : null,
      property: values.property ? values.property.value : null,
      status: values.status ? (values.status.value ? values.status.value : values.status) : null,
    }
    let url = 'api/v1/crm/leads'
    let method = 'post'
    if (initialData && initialData.id) {
      url = `api/v1/crm/leads/${initialData.id}`
      method = 'patch'
    }
    axiosRequest(url, method, data, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
          setVisibility(false, {})
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal title="Leads Form" visible={visibility} onCancel={() => setVisibility(false, {})} destroyOnClose footer={false}>
      <FormBuilder
        onFinish={handleFormSubmit}
        loading={loading}
        formProps={{
          layout: 'vertical',
          requiredMarks: false,
          initialValues: initialData,
        }}
        contentBefore={
          <>
            <Form.Item name="manager" label="Manager" rules={[{ required: true, message: 'This field is required' }]}>
              <Select
                allowClear
                showSearch
                onSearch={searchUsers}
                filterOption={false}
                notFoundContent={null}
                labelInValue
                loading={data.users.loading}
              >
                {data.users.data.map((user) => (
                  <Select.Option value={user.key} key={user.key}>
                    {user.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="property" label="Property" rules={[{ required: true, message: 'This field is required' }]}>
              <Select
                allowClear
                showSearch
                onSearch={searchProperties}
                filterOption={false}
                notFoundContent={null}
                labelInValue
                loading={data.properties.loading}
              >
                {data.properties.data.map((property) => (
                  <Select.Option value={property.key} key={property.key}>
                    {property.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        }
        fields={[
          {
            label: 'Lead Name',
            name: 'name',
            required: true,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Lead name',
            className: 'col-12',
          },
          {
            label: 'Lead Source',
            name: 'leadSource',
            required: true,
            fieldType: 'select',
            placeholder: 'Select lead source',
            className: 'col-12',
            select: {
              isMultiple: false,
              loading: false,
              options: _.map(leadSources, (source) => {
                return { label: source, value: source }
              }),
            },
          },
          {
            label: 'Status',
            name: 'status',
            required: true,
            fieldType: 'select',
            placeholder: 'Select lead status',
            className: 'col-12',
            select: {
              isMultiple: false,
              loading: false,
              options: statuses,
            },
          },
          {
            label: 'Closed',
            name: 'isClosed',
            required: true,
            fieldType: 'select',
            placeholder: 'Lead closed?',
            className: 'col-12 col-md-6',
            select: {
              isMultiple: false,
              loading: false,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
            },
          },
          {
            label: 'Closed Successfully',
            name: 'isSuccess',
            required: true,
            fieldType: 'select',
            placeholder: 'Lead closed successfully?',
            className: 'col-12 col-md-6',
            select: {
              isMultiple: false,
              loading: false,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
            },
          },
          {
            label: 'Deposit Submitted',
            name: 'isDepositSubmitted',
            required: true,
            fieldType: 'select',
            placeholder: 'Deposit Submitted?',
            className: 'col-12 col-md-6',
            select: {
              isMultiple: false,
              loading: false,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
            },
          },
          {
            label: 'Deposit Amount',
            name: 'depositAmount',
            required: false,
            fieldType: 'inputNumber',
            type: 'text',
            placeholder: 'Deposit Amount',
            className: 'col-12 col-md-6',
          },
          {
            label: 'Description',
            name: 'description',
            required: false,
            fieldType: 'textarea',
            type: 'text',
            placeholder: 'Lead name',
            className: 'col-12',
          },
          {
            label: 'Create customer',
            name: ['newCustomer', 'fullName'],
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Customer full name',
            className: 'col-12',
          },
          {
            label: 'Customer email',
            name: ['newCustomer', 'email'],
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Customer email',
            className: 'col-12 col-md-6',
          },
          {
            label: 'Customer phone',
            name: ['newCustomer', 'phone'],
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Customer phone',
            className: 'col-12 col-md-6',
          },
        ]}
      >
        <Form.Item
          name="customer"
          label="Or find existing customer"
          rules={[{ required: false, message: 'This field is required' }]}
        >
          <Select
            allowClear
            showSearch
            onSearch={searchCustomers}
            filterOption={false}
            notFoundContent={null}
            labelInValue
            loading={data.customers.loading}
          >
            {data.customers.data.map((customer) => (
              <Select.Option value={customer.key} key={customer.key}>
                {customer.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </FormBuilder>
    </Modal>
  )
}

export default LeadForm
