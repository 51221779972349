import React, { useState } from 'react'
import {
  Button,
  Empty,
  message,
  notification,
  Popconfirm,
  Spin,
  Tooltip,
  Badge,
} from 'antd'
import {
  RiDeleteBin3Fill,
  RiEyeFill,
  RiMap2Fill,
  RiRestartFill,
  RiPriceTag2Fill,
  RiMailFill,
  RiPhoneFill,
  RiUserFill,
  RiHashtag,
  RiLandscapeFill,
  RiMoneyDollarCircleFill,
  RiCheckFill,
  RiContactsBook2Fill,
  RiSendPlane2Fill,
  RiEdit2Fill,
  RiAlertFill,
} from 'react-icons/ri'

import DefaultCard from '../../../../ui/cards/DefaultCard'
import PropertyDetails from './PropertyDetails'

import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

import styles from '../../../listings/properties/item/property-item.module.scss'
import { useSelector } from 'react-redux'
import PropertyConversation from './PropertyConversation'
import ChangePropertyStatusModal from './ChangePropertyStatusModal'

const AcquisitionProperties = ({
  getData,
  setActiveTab,
  properties = [],
  loading = true,
  showContacts = true,
  showControlButtons = true,
  showSubmitButton = false,
  allowChangeStatuses = true,
  agentView = false,
}) => {
  const users = useSelector((state) => state.users)
  const { user } = users
  const [changeStatus, setChangeStatus] = useState({
    visible: false,
    property: null,
  })
  const [details, setDetails] = useState({ visible: false, property: null })
  const [conversations, setConversations] = useState({
    visible: false,
    property: null,
  })

  const loadContactData = (id) => {
    message.loading({ content: 'Please, wait...', key: 'l1' })
    axiosRequest(
      `api/v1/acquisition/data/properties/contacts/find/${id}`,
      'get',
      null,
      true,
    )
      .then(() => {
        message.success({ content: 'Done', key: 'l1' })
        getData()
      })
      .catch((e) => {
        message.destroy('l1')
        axiosErrorHelper(e)
      })
  }

  const deleteProperty = (id) => {
    message.loading({ content: 'Please, wait...', key: 'l2' })
    axiosRequest(
      `api/v1/acquisition/data/properties/${id}`,
      'delete',
      null,
      true,
    )
      .then(() => {
        message.success({ content: 'Done', key: 'l2' })
        getData()
      })
      .catch((e) => {
        message.destroy('l2')
        axiosErrorHelper(e)
      })
  }

  const submitProperty = (id, property) => {
    message.loading({ content: 'Please, wait...', key: 'l3' })
    axiosRequest(
      `api/v1/acquisition/data/properties/${id}`,
      'patch',
      { AgentId: user.id },
      true,
    )
      .then(() => {
        message.success({ content: 'Done', key: 'l3' })
        getData(true, () => {
          setActiveTab('myLeads')
          setConversations({
            property: property,
            visible: true,
          })
        })
        // setTimeout(() => {
        // }, 3000)
      })
      .catch((e) => {
        message.destroy('l3')
        axiosErrorHelper(e)
      })
  }

  const sendTextMessage = (property_id, contact_id) => {
    message.loading({ content: 'Please, wait...', key: 'l4' })
    axiosRequest(
      `api/v1/acquisition/data/properties/contacts/send/${property_id}/${contact_id}`,
      'get',
      null,
      true,
    )
      .then(() => {
        message.success({ content: 'Done', key: 'l4' })
        notification.success({
          message: 'Sent',
          description:
            'Messages has been sent, listings data will be updated in couple minutes. Please, do not send new messages for this listings during this time.',
        })
        getData()
      })
      .catch((e) => {
        message.destroy('l4')
        axiosErrorHelper(e)
      })
  }

  if (!loading && properties.length === 0) {
    return (
      <div className="mt-5">
        <Empty description="No properties found" />
      </div>
    )
  }

  return (
    <>
      <div className="mt-4">
        <Spin spinning={loading} tip="Loading properties...">
          {properties.map((property) => (
            <DefaultCard className="mb-4" key={property.id}>
              <div className={styles.propertyItem}>
                <div className={styles.item}>
                  <div className={styles.itemWrapper}>
                    <div
                      className={styles.contentWrapper}
                      style={{ flexBasis: '100%' }}
                    >
                      <div
                        className={styles.content}
                        style={{ flexBasis: '90%' }}
                      >
                        <Badge
                          count={
                            property &&
                            property.get_contact &&
                            property.get_contact.HaveUnreadMessage
                              ? 'New Message'
                              : 0
                          }
                          size="small"
                          color="orange"
                          showZero={false}
                        >
                          <h2>
                            {property.ACRES} acre in {property.SitusCity},{' '}
                            {property.SitusState}
                            {property.get_agent && (
                              <sup
                                className="ms-2 primary-color"
                                style={{ textTransform: 'uppercase' }}
                              >
                                {property.get_agent.name}
                              </sup>
                            )}
                          </h2>
                        </Badge>
                        <div className={styles.info}>
                          <ul className="row">
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiHashtag />{' '}
                              <span>
                                Property ID:{' '}
                                <strong>{property.PropertyId}</strong>
                              </span>
                            </li>
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiLandscapeFill />{' '}
                              <span>
                                APN: <strong>{property.APNFormatted}</strong>
                              </span>
                            </li>
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiMap2Fill />{' '}
                              <span>
                                {property.SitusStreetAddress},{' '}
                                {property.SitusCity},{' '}
                                {property.County ? property.County + ', ' : ''}{' '}
                                {property.SitusState
                                  ? property.SitusState + ', '
                                  : ''}{' '}
                                US, {property.SitusZipCode}
                              </span>
                            </li>
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiMap2Fill /> GPS:{' '}
                              <a
                                href={`http://maps.google.com/maps?daddr=${property.Latitude}%2C${property.Longitude}`}
                                target="_blank"
                                rel="noreferrer noopener nofollow"
                              >
                                Open Map
                              </a>
                            </li>
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiPriceTag2Fill />{' '}
                              <span>
                                Zoning:{' '}
                                <strong>{property.Zoning || '-'}</strong>
                              </span>
                            </li>
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiPriceTag2Fill />{' '}
                              <span>
                                Land Use:{' '}
                                <strong>{property.LandUse || '-'}</strong>
                              </span>
                            </li>
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiPriceTag2Fill />{' '}
                              <span>
                                Water Type:{' '}
                                <strong>{property.WaterType || '-'}</strong>
                              </span>
                            </li>
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiPriceTag2Fill />{' '}
                              <span>
                                Sewer Type:{' '}
                                <strong>{property.SewerType || '-'}</strong>
                              </span>
                            </li>
                            <li className="col-6 col-md-6 col-lg-4">
                              <RiPriceTag2Fill />{' '}
                              <span>
                                Subdivision:{' '}
                                <strong>{property.Subdivision || '-'}</strong>
                              </span>
                            </li>
                            {property.get_contact &&
                            property.get_contact.id &&
                            !showSubmitButton ? (
                              <li className="col-6 col-md-6 col-lg-4">
                                <RiUserFill />{' '}
                                <span>
                                  <strong>
                                    {property.get_contact.FirstName}{' '}
                                    {property.get_contact.LastName}
                                  </strong>
                                </span>
                              </li>
                            ) : null}
                            {property.get_contact &&
                            property.get_contact.id &&
                            showContacts ? (
                              <>
                                <li className="col-6 col-md-6 col-lg-4">
                                  <RiMailFill />{' '}
                                  <span>
                                    {property.get_contact.Email || '-'}
                                  </span>
                                </li>
                                <li className="col-6 col-md-6 col-lg-4">
                                  <RiPhoneFill />{' '}
                                  {property.get_contact.Phone ? (
                                    <a
                                      href={`tel:${property.get_contact.Phone}`}
                                    >
                                      {property.get_contact.Phone}{' '}
                                      <small>
                                        ({property.get_contact.LineType})
                                      </small>
                                    </a>
                                  ) : (
                                    '-'
                                  )}
                                </li>
                              </>
                            ) : (
                              <li className={`col-6 col-md-6 col-lg-4`}>
                                {!showContacts ? (
                                  <>
                                    <RiPhoneFill />{' '}
                                    {property.get_contact.LineType}
                                  </>
                                ) : (
                                  <>
                                    <RiAlertFill className="danger-color" />{' '}
                                    <span className="danger-color">
                                      Contact information not found
                                    </span>
                                  </>
                                )}
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div
                        className={styles.actions}
                        style={{ flexBasis: '10%' }}
                      >
                        <div className="mb-2">
                          <Button
                            className="btn-sm btn-primary btn-primary__simple no-svg-padding"
                            onClick={() =>
                              setDetails({
                                visible: true,
                                property: property,
                              })
                            }
                          >
                            <RiEyeFill /> <span className="ps-2">Details</span>
                          </Button>
                        </div>
                        {showSubmitButton && (
                          <div className="mb-2">
                            <Popconfirm
                              title={`Are you really want to submit this lead?`}
                              onConfirm={() => {
                                submitProperty(property.id, property)
                              }}
                              okButtonProps={{
                                className: `btn-sm btn-success`,
                              }}
                              cancelButtonProps={{ className: `btn-sm` }}
                            >
                              <Button
                                className={`btn-sm no-svg-padding btn-success btn-success__simple`}
                              >
                                <RiCheckFill />{' '}
                                <span className="ps-2">Submit Lead</span>
                              </Button>
                            </Popconfirm>
                          </div>
                        )}
                        {showControlButtons && (
                          <>
                            {property.get_contact &&
                            property.get_contact.Phone ? (
                              <div className="mb-2">
                                <Popconfirm
                                  title={`Are you really want to send Text/Email message to seller?`}
                                  onConfirm={() => {
                                    sendTextMessage(
                                      property.id,
                                      property.get_contact.id,
                                    )
                                  }}
                                  okButtonProps={{
                                    className: `btn-sm btn-success`,
                                  }}
                                  cancelButtonProps={{ className: `btn-sm` }}
                                >
                                  <Button
                                    className={`btn-sm no-svg-padding btn-success btn-success__simple d-none`}
                                  >
                                    <RiSendPlane2Fill />{' '}
                                    <span className="ps-2">Send</span>
                                  </Button>
                                </Popconfirm>
                              </div>
                            ) : null}
                            {!property.get_contact ||
                            (!property.get_contact.Email &&
                              !property.get_contact.Phone) ? (
                              <div className="mb-2">
                                <Popconfirm
                                  title={`Are you really want reload property contacts?`}
                                  onConfirm={() => {
                                    loadContactData(property.id)
                                  }}
                                  okButtonProps={{
                                    className: `btn-sm btn-warning`,
                                  }}
                                  cancelButtonProps={{ className: `btn-sm` }}
                                >
                                  <Button
                                    className={`btn-sm no-svg-padding btn-warning btn-warning__simple`}
                                  >
                                    <RiRestartFill />{' '}
                                    <span className="ps-2">Load Contacts</span>
                                  </Button>
                                </Popconfirm>
                              </div>
                            ) : null}
                            <div className="mb-2">
                              <Popconfirm
                                title={`Are you really want to delete this property?`}
                                onConfirm={() => {
                                  deleteProperty(property.id)
                                }}
                                okButtonProps={{
                                  className: `btn-sm btn-danger`,
                                }}
                                cancelButtonProps={{ className: `btn-sm` }}
                              >
                                <Button
                                  className={`btn-sm no-svg-padding btn-danger btn-danger__simple`}
                                >
                                  <RiDeleteBin3Fill />{' '}
                                  <span className="ps-2">Delete</span>
                                </Button>
                              </Popconfirm>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.itemFooter}>
                  <div className={styles.footerWrapper}>
                    <div className={styles.priceWrapper}>
                      <div className={styles.icon}>
                        <RiMoneyDollarCircleFill />
                      </div>
                      <div className={styles.priceContent}>
                        <h5>Market Value</h5>
                        <h3>
                          {property.MarketValue ? property.MarketValue : 'None'}
                        </h3>
                      </div>
                    </div>
                    {property.DelinquentTaxValue ? (
                      <div className={styles.priceWrapper}>
                        <div className={styles.icon}>
                          <RiMoneyDollarCircleFill />
                        </div>
                        <div className={styles.priceContent}>
                          <h5>Delq. Tax Value</h5>
                          <h3>
                            {property.DelinquentTaxValue
                              ? property.DelinquentTaxValue
                              : 'None'}
                          </h3>
                        </div>
                      </div>
                    ) : null}
                    {property.AssdTotalValue ? (
                      <div className={styles.priceWrapper}>
                        <div className={styles.icon}>
                          <RiMoneyDollarCircleFill />
                        </div>
                        <div className={styles.priceContent}>
                          <h5>Assd. Total Value</h5>
                          <h3>
                            {property.AssdTotalValue
                              ? property.AssdTotalValue
                              : 'None'}
                          </h3>
                        </div>
                      </div>
                    ) : null}
                    {property.AssdLandValue ? (
                      <div className={styles.priceWrapper}>
                        <div className={styles.icon}>
                          <RiMoneyDollarCircleFill />
                        </div>
                        <div className={styles.priceContent}>
                          <h5>Assd. Land Value</h5>
                          <h3>
                            {property.AssdLandValue
                              ? property.AssdLandValue
                              : 'None'}
                          </h3>
                        </div>
                      </div>
                    ) : null}
                    {property.AssdImprovementValue ? (
                      <div className={styles.priceWrapper}>
                        <div className={styles.icon}>
                          <RiMoneyDollarCircleFill />
                        </div>
                        <div className={styles.priceContent}>
                          <h5>Assd. Improvement Value</h5>
                          <h3>
                            {property.AssdImprovementValue
                              ? property.AssdImprovementValue
                              : 'None'}
                          </h3>
                        </div>
                      </div>
                    ) : null}
                    <div className={styles.data}>
                      {allowChangeStatuses ? (
                        <div className={styles.priceWrapper}>
                          <div className={styles.priceContent}>
                            <Tooltip title="Click here to change the status">
                              <Button
                                icon={<RiEdit2Fill />}
                                className="btn-sm btn-primary btn-primary__simple"
                                onClick={() => {
                                  setChangeStatus({
                                    visible: true,
                                    property: property,
                                  })
                                }}
                                style={{ textTransform: 'capitalize' }}
                              >
                                {property.Status === 'req_sent'
                                  ? 'Waiting for Response'
                                  : property.Status}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.priceWrapper}>
                          <div className={styles.icon}>
                            <RiCheckFill className="success-color" />
                          </div>
                          <div className={styles.priceContent}>
                            <h5
                              className="mb-0 text-center"
                              style={{ textTransform: 'capitalize' }}
                            >
                              {property.Status === 'req_sent'
                                ? 'Waiting for Response'
                                : property.Status}
                            </h5>
                          </div>
                        </div>
                      )}
                      {showContacts &&
                      property.get_contact &&
                      property.get_contact.get_messages &&
                      property.get_contact.get_messages.length > 0 ? (
                        <div className={`${styles.priceWrapper} me-0`}>
                          <div className={`${styles.priceContent} text-center`}>
                            <Button
                              icon={<RiContactsBook2Fill />}
                              className="btn-sm btn-primary btn-primary__simple"
                              onClick={() =>
                                setConversations({
                                  property: property,
                                  visible: true,
                                })
                              }
                            >
                              Conversation
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </DefaultCard>
          ))}
        </Spin>
      </div>
      <PropertyDetails
        showContacts={showContacts}
        visible={details.visible}
        setVisibility={() => setDetails({ visible: false, property: null })}
        property={details.property}
      />
      <PropertyConversation
        getData={getData}
        visible={conversations.visible}
        setVisibility={() =>
          setConversations({ visible: false, property: null })
        }
        property={conversations.property}
      />
      {allowChangeStatuses && (
        <ChangePropertyStatusModal
          getData={getData}
          visible={changeStatus.visible}
          setVisibility={() =>
            setChangeStatus({ visible: false, propertyId: null })
          }
          property={changeStatus.property}
          agentStatuses={agentView}
        />
      )}
    </>
  )
}

export default AcquisitionProperties
