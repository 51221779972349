import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Button, message, Pagination, Popconfirm, Spin } from 'antd'
import { RiMoneyDollarBoxFill, RiRefreshLine } from 'react-icons/ri'

import PageTitle from '../../components/ui/title/PageTitle'
import DefaultCard from '../../components/ui/cards/DefaultCard'
import Table from '../../components/ui/table/Table'
import ActiveSkillBalance from '../../components/pages/websites/ActiveSkillBalance'
import AddFundsModal from '../../components/pages/activeskill/AddFundsModal'

import axiosRequest from '../../helpers/axios-request.helper'
import axiosErrorHelper from '../../helpers/axios-error.helper'

const ActiveSkillPage = () => {
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({ page: 1, perPage: 30 })
  const [data, setData] = useState({ data: [], total: 0 })
  const [addFundsData, setAddFundsData] = useState({
    visible: false,
    apiKey: null,
    name: null,
  })

  const getData = () => {
    setLoading(true)
    axiosRequest(
      `api/v1/websites?page=${filters.page}&perPage=${filters.perPage}&asOnly=true`,
      'get',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.websites) {
          setLoading(false)
          setData({
            data: res.data.websites.data,
            total: res.data.websites.total,
          })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const reSyncProperties = (websiteId) => {
    axiosRequest(
      `api/v1/websites/active-skill/re-sync/${websiteId}`,
      'post',
      null,
      true,
    )
      .then(() => {
        getData()
        message.success('Success')
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  return (
    <>
      <Helmet title="ActiveSkill Companies" />
      <PageTitle
        title="ActiveSkill Companies"
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/companies', name: 'Companies' },
          { path: '/companies/activeskill', name: 'ActiveSkill' },
        ]}
      />
      <DefaultCard>
        <Spin spinning={loading}>
          <Table
            columns={[
              {
                key: 'name',
                name: 'Name',
                headClassName: null,
                itemClassName: null,
                render: (item) => <strong>{item.name}</strong>,
              },
              {
                key: 'company',
                name: 'Company',
                headClassName: null,
                itemClassName: null,
                render: (item) => item.get_company.name,
              },
              {
                key: 'asBalance',
                name: 'AS Balance',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <strong className="primary-color">
                    <ActiveSkillBalance
                      apiToken={item.active_skill.apiKey}
                      refetch={getData}
                    />
                  </strong>
                ),
              },
              {
                key: 'actions',
                name: 'Actions',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={() => reSyncProperties(item.id)}
                      okButtonProps={{ className: `btn-sm btn-danger` }}
                      cancelButtonProps={{ className: `btn-sm` }}
                    >
                      <Button className="me-3 btn-xsm btn-primary btn-primary__simple no-svg-padding">
                        <RiRefreshLine />{' '}
                        <span className="ps-2">Re-Sync Listings</span>
                      </Button>
                    </Popconfirm>
                    <Button
                      className="me-0 btn-xsm btn-primary btn-success__simple no-svg-padding"
                      onClick={() => {
                        setAddFundsData({
                          visible: true,
                          apiKey: item.active_skill.apiKey,
                          name: item.name,
                        })
                      }}
                    >
                      <RiMoneyDollarBoxFill />{' '}
                      <span className="ps-2">Add Funds</span>
                    </Button>
                  </>
                ),
              },
            ]}
            data={data.data}
          />
          {filters.perPage < data.total && (
            <Pagination
              className="mt-4"
              size="small"
              current={filters.page}
              pageSize={filters.perPage}
              total={data.total}
              showSizeChanger={false}
              onChange={(page) => setFilters({ ...filters, page })}
            />
          )}
        </Spin>
      </DefaultCard>
      <AddFundsModal
        isVisible={addFundsData.visible}
        websiteApiKey={addFundsData.apiKey}
        websiteName={addFundsData.name}
        onClose={() => {
          setAddFundsData({ visible: false, apiKey: null, name: null })
          setData({ data: [], total: 0 })
          getData()
        }}
      />
    </>
  )
}

export default ActiveSkillPage
