import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { logger } from 'redux-logger'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import createRootReducer from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'

import './scss/App.scss'

import reportWebVitals from './reportWebVitals'

// Middleware
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}
const store = createStore(createRootReducer(history), compose(applyMiddleware(...middlewares)))
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE || 'no-stripe-public-key')
sagaMiddleware.run(sagas)

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router history={history} />
      </Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

export { store, history }
