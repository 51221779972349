import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import _ from 'lodash'
import moment from 'moment'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const stringToColour = function (str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

const ManagersSentChart = ({ data, type = 'sms' }) => {
  if (data.length < 1) {
    return null
  }

  return (
    <div style={{ height: '320px', position: 'relative' }}>
      <Line
        data={{
          labels: _.map(data[0].data, (day) =>
            moment(day.startOfDay).format('MMM DD'),
          ),
          datasets: _.map(data, (man) => ({
            label: man.label,
            data: _.map(man.data, (day) => day[type]),
            backgroundColor: stringToColour(man.label),
            fill: true,
            borderRadius: 6,
            borderColor: stringToColour(man.label),
            borderWidth: 1,
            barPercentage: 0.5,
            barThickness: 24,
          })),
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: false,
            },
            tooltip: {
              displayColors: true,
              callbacks: {
                label: function (tooltipItem, data) {
                  return `${tooltipItem.formattedValue} ${
                    tooltipItem.dataset.label || ''
                  } sent`
                },
              },
            },
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },
            y: {
              display: true,
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },
            yAxis: {
              display: true,
              beginAtZero: true,
              ticks: {
                display: false,
              },
              grid: {
                color: '#ebebeb',
                lineWidth: 1,
              },
              border: { display: false },
            },
            xAxis: {
              display: false,
              ticks: {
                display: false,
              },
              grid: {
                display: false,
              },
              border: { display: false },
            },
          },
        }}
      />
    </div>
  )
}

export default ManagersSentChart
