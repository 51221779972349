import React from 'react'
import PropTypes from 'prop-types'

import Breadcrumbs from './Breadcrumbs'

import styles from './page-title.module.scss'

const PageTitle = ({ title, breadcrumbs = [], extraButtons = null }) => {
  return (
    <div className={styles.pageTitle}>
      <div className={styles.title}>
        <h1>{title}</h1>
        {breadcrumbs && breadcrumbs.length > 0 ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
      </div>
      {extraButtons && <div>{extraButtons}</div>}
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
      name: PropTypes.string,
    }),
  ),
  extraButtons: PropTypes.any,
}

export default PageTitle
