import React, { useState } from 'react'
import { Button } from 'antd'
import { RiAddFill } from 'react-icons/ri'

import Notes from './leads-notes/Notes'
import LeadNotesForm from './leads-notes/Form'
import DefaultCard from '../../../ui/cards/DefaultCard'

const LeadNotes = ({ user, lead, notes, reloadData }) => {
  const [leadForm, setLeadForm] = useState({ visible: false, note: {} })

  const handleNoteFormVisibility = (visible, note) => {
    setLeadForm({ visible, note })
  }

  return (
    <>
      <DefaultCard
        title="Notes"
        className="mb-4"
        titleData={
          <Button
            icon={<RiAddFill />}
            className="btn-sm btn-primary fw-500 me-3"
            onClick={() => {
              handleNoteFormVisibility(true, {})
            }}
          >
            Add Note
          </Button>
        }
      >
        <Notes user={user} notes={notes} setVisibility={handleNoteFormVisibility} reloadData={reloadData} />
      </DefaultCard>
      <LeadNotesForm
        visible={leadForm.visible}
        note={leadForm.note}
        setVisibility={handleNoteFormVisibility}
        lead={lead}
        reloadData={reloadData}
      />
    </>
  )
}

export default LeadNotes
