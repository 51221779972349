import React from 'react'

import styles from './auth.module.scss'

const AuthLayout = ({ children }) => {
  return (
    <div className={styles.authLayout}>
      <div className={styles.layoutWrapper}>
        <h1>Welcome to PortalStation</h1>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default AuthLayout
