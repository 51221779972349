import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import FormBuilder from '../../ui/forms/FormBuilder'
import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'

const AssignWebsites = ({ initialData, reloadData }) => {
  const [loading, setLoading] = useState(false)
  const [websites, setWebsites] = useState([])
  const [selectLoading, setSelectLoading] = useState(false)

  useEffect(() => {
    setSelectLoading(true)
    axiosRequest('api/v1/websites?page=1&perPage=99999', 'get', null, true)
      .then((res) => {
        setSelectLoading(false)
        if (res.data && res.data.success && res.data.websites) {
          const websitesArr = _.map(res.data.websites.data, (website) => {
            return {
              label: website.name,
              value: website.id,
            }
          })
          setWebsites(websitesArr)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }, [initialData])

  const handleFormSubmit = (values) => {
    setLoading(true)
    axiosRequest(
      'api/v1/users/actions/assign-to-website',
      'post',
      {
        userId: initialData.id,
        websiteId: values.websiteId || null,
        assign: true,
      },
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <FormBuilder
      onFinish={handleFormSubmit}
      loading={loading}
      formProps={{
        layout: 'vertical',
        requiredMarks: false,
        initialValues: {
          websiteId: initialData.get_websites && initialData.get_websites[0] ? initialData.get_websites[0].id : '',
        },
      }}
      fields={[
        {
          label: 'Websites',
          name: 'websiteId',
          required: false,
          fieldType: 'select',
          placeholder: 'Select website',
          className: 'col-12',
          select: {
            isMultiple: false,
            loading: selectLoading,
            options: websites,
          },
        },
      ]}
    />
  )
}

export default AssignWebsites
