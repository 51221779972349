import React, { useState } from 'react'
import {
  RiCheckFill,
  RiContactsBook2Fill,
  RiErrorWarningFill,
  RiMoneyDollarCircleFill,
} from 'react-icons/ri'

import styles from '../property-item.module.scss'
import { Button, Popover } from 'antd'
import LeadsListDrawer from '../leads-list/LeadsListDrawer'

const Footer = ({ property, user, permissions }) => {
  const [leadsDrawer, setLeadsDrawer] = useState({ visible: false, slug: null })

  return (
    <>
      <div className={styles.itemFooter}>
        <div className={styles.footerWrapper}>
          <div className={styles.priceWrapper}>
            <div className={styles.icon}>
              <RiMoneyDollarCircleFill />
            </div>
            <div className={styles.priceContent}>
              <h5>Price</h5>
              <h3>
                {property.price
                  ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                    }).format(property.price / 100)
                  : 'None'}
              </h3>
            </div>
          </div>
          {property.contractPrice ? (
            <div className={styles.priceWrapper}>
              <div className={styles.icon}>
                <RiMoneyDollarCircleFill />
              </div>
              <div className={styles.priceContent}>
                <h5>Contract Price</h5>
                <h3>
                  {property.contractPrice
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                      }).format(property.contractPrice / 100)
                    : 'None'}
                </h3>
              </div>
            </div>
          ) : null}
          {property.ownerFinancePrice ? (
            <div className={styles.priceWrapper}>
              <div className={styles.icon}>
                <RiMoneyDollarCircleFill />
              </div>
              <div className={styles.priceContent}>
                <h5>Owner Finance Price</h5>
                <h3>
                  {property.ownerFinancePrice
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                      }).format(property.ownerFinancePrice / 100)
                    : 'None'}
                </h3>
              </div>
            </div>
          ) : null}
          {property.processingFee &&
          property.processingFee !== 0 &&
          property.processingFee !== '0' ? (
            <div className={styles.priceWrapper}>
              <div className={styles.icon}>
                <RiMoneyDollarCircleFill />
              </div>
              <div className={styles.priceContent}>
                <h5>Processing Fee</h5>
                <h3>
                  {property.processingFee
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                      }).format(property.processingFee / 100)
                    : 'None'}
                </h3>
              </div>
            </div>
          ) : null}
          {property.sale_bonus ? (
            <div className={styles.priceWrapperHighLight}>
              <div className={styles.icon}>
                <RiMoneyDollarCircleFill />
              </div>
              <div className={styles.priceContent}>
                <h5>Sale Bonus</h5>
                <h3>
                  {property.ownerFinancePrice
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                      }).format(property.sale_bonus / 100)
                    : 'None'}
                </h3>
              </div>
            </div>
          ) : null}
          <div className={styles.data}>
            {property.deleted_at ? (
              <div className={styles.priceWrapper}>
                <div className={styles.icon}>
                  <RiCheckFill className="danger-color" />
                </div>
                <div className={styles.priceContent}>
                  <h5 className="mb-0">Archived</h5>
                </div>
              </div>
            ) : (
              <>
                {property.isSold ? (
                  <div className={styles.priceWrapper}>
                    <div className={styles.icon}>
                      <RiCheckFill className="success-color" />
                    </div>
                    <div className={styles.priceContent}>
                      <h5 className="mb-0">Sold</h5>
                    </div>
                  </div>
                ) : (
                  <>
                    {property.errors && property.errors.length > 0 ? (
                      <Popover
                        content={
                          <ul>
                            {property.errors.map((error) => (
                              <li key={error.id}>{error.error}</li>
                            ))}
                          </ul>
                        }
                        title="Property Errors"
                        trigger="click"
                      >
                        <div className={styles.priceWrapper}>
                          <div className={styles.icon}>
                            <RiErrorWarningFill className="danger-color" />
                          </div>
                          <div className={styles.priceContent}>
                            <h5 className="mb-0 text-center">
                              {property.errors.length} errors
                            </h5>
                            <Button className="btn-sm btn-primary btn-primary__simple">
                              View
                            </Button>
                          </div>
                        </div>
                      </Popover>
                    ) : (
                      <>
                        {property.isPublished ? (
                          <div className={styles.priceWrapper}>
                            <div className={styles.icon}>
                              <RiCheckFill className="success-color" />
                            </div>
                            <div className={styles.priceContent}>
                              <h5 className="mb-0">Published</h5>
                            </div>
                          </div>
                        ) : (
                          <div className={styles.priceWrapper}>
                            <div className={styles.icon}>
                              <RiCheckFill className="warning-color" />
                            </div>
                            <div className={styles.priceContent}>
                              <h5 className="mb-0">Not Published</h5>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <div className={styles.priceWrapper}>
              <div className={styles.icon}>
                <RiContactsBook2Fill className="primary-color" />
              </div>
              <div className={styles.priceContent}>
                <h5 className="mb-0 text-center">
                  {property.leads_count || 0} leads
                </h5>
                <Button
                  className="btn-sm btn-primary btn-primary__simple"
                  onClick={() =>
                    setLeadsDrawer({ visible: true, slug: property.slug })
                  }
                >
                  View
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LeadsListDrawer
        visible={leadsDrawer.visible}
        onClose={setLeadsDrawer}
        slug={leadsDrawer.slug}
        user={user}
        permissions={permissions}
      />
    </>
  )
}

export default Footer
