import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import IndexLayout from './components/layouts/IndexLayout'
import routes from './routes/routes'

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <IndexLayout>
      <Route
        render={(state) => {
          const { location } = state
          return (
            <Switch location={location} key={location.pathname}>
              {routes.map(({ path, Component, exact }) => (
                <Route path={path} key={path} exact={exact || true} render={() => <Component />} />
              ))}
              <Redirect to="/404" />
            </Switch>
          )
        }}
      />
    </IndexLayout>
  </ConnectedRouter>
)

export default Router
