import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useParams, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Button, Pagination, Spin } from 'antd'
import {
  RiAddFill,
  RiCheckFill,
  RiCloseFill,
  RiEditFill,
  RiLock2Fill,
} from 'react-icons/ri'

import PageTitle from '../components/ui/title/PageTitle'
import DefaultCard from '../components/ui/cards/DefaultCard'
import Table from '../components/ui/table/Table'

import axiosRequest from '../helpers/axios-request.helper'
import axiosErrorHelper from '../helpers/axios-error.helper'
import checkPermission from '../helpers/check-permission'
import UsersForm from '../components/pages/users/UsersForm'
import UsersPermissionsForm from '../components/pages/users/UsersPermissionsForm'

const mapStateToProps = ({ users }) => ({ users })

const UsersPage = ({ users }) => {
  const { permissions } = users
  const { slug } = useParams()
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({ page: 1, perPage: 30 })
  const [data, setData] = useState({ data: [], total: 0 })
  const [usersFormModal, setUsersFormModal] = useState({
    visible: false,
    initialData: {},
  })
  const [usersPermissionsFormModal, setUsersPermissionsFormModal] = useState({
    visible: false,
    userId: null,
    initialData: {},
  })

  const getData = () => {
    setLoading(true)
    axiosRequest(
      `api/v1/users?page=${filters.page}&perPage=${filters.perPage}${
        slug && slug !== 'all' ? `&type=${slug}` : ''
      }`,
      'get',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          setData({ data: res.data.users.users, total: res.data.users.total })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, slug])

  const usersFormModalHandler = (isVisible, data) => {
    setUsersFormModal({ visible: isVisible, initialData: data })
  }

  const usersPermissionsFormModalHandler = (isVisible, userId, data) => {
    setUsersPermissionsFormModal({
      visible: isVisible,
      userId,
      initialData: data,
    })
  }

  return (
    <>
      <Helmet title="Users" />
      <PageTitle
        title="Users"
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/users', name: 'Users' },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'users:list') && (
              <Button
                icon={<RiAddFill />}
                className="btn-primary fw-500"
                onClick={() => {
                  usersFormModalHandler(true, {
                    isAdmin: false,
                    isBanned: false,
                  })
                }}
              >
                Add User
              </Button>
            )}
          </>
        }
      />
      <DefaultCard>
        <Spin spinning={loading}>
          <Table
            columns={[
              {
                key: 'name',
                name: 'Name',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    <p className="mb-1">
                      <strong>{item.name}</strong>
                    </p>
                    <p className="mb-0">
                      <small>{item.email}</small>
                    </p>
                  </>
                ),
              },
              {
                key: 'type',
                name: 'Type',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {item.type === 'admin' && <span>Management Team</span>}
                    {item.type === 'sales' && <span>Sales Team</span>}
                    {item.type === 'acquisition' && (
                      <span>Acquisition Team</span>
                    )}
                  </>
                ),
              },
              {
                key: 'isBanned',
                name: 'Status',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {!item.isBanned ? (
                      <span className="f-size-2 success-color">
                        <RiCheckFill />
                      </span>
                    ) : (
                      <span className="f-size-2 danger-color">
                        <RiCloseFill />
                      </span>
                    )}
                  </>
                ),
              },
              {
                key: 'admin',
                name: 'Admin',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {item.isAdmin ? (
                      <span className="f-size-2 success-color">
                        <RiCheckFill />
                      </span>
                    ) : (
                      <span className="f-size-2 danger-color">
                        <RiCloseFill />
                      </span>
                    )}
                  </>
                ),
              },
              {
                key: 'companies',
                name: 'Companies',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <p className="mb-0 span-coma">
                    {item.get_companies && item.get_companies.length > 0
                      ? item.get_companies.map((company) => (
                          <span key={company.id}>{company.name}</span>
                        ))
                      : '-'}
                  </p>
                ),
              },
              {
                key: 'websites',
                name: 'Websites',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <p className="mb-0 span-coma">
                    {item.get_websites && item.get_websites.length > 0
                      ? item.get_websites.map((website) => (
                          <span key={website.id}>
                            <a
                              href={website.domain}
                              target="_blank"
                              rel="noreferrer nofollow noopener"
                            >
                              {website.name}
                            </a>
                          </span>
                        ))
                      : '-'}
                  </p>
                ),
              },
              {
                key: 'lastActivity',
                name: 'Last Activity',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {item.lastActivity
                      ? moment.utc(item.lastActivity).fromNow()
                      : 'N/A'}
                  </>
                ),
              },
              {
                key: 'createdAt',
                name: 'Created At',
                headClassName: null,
                itemClassName: null,
                render: (item) => moment(item.created_at).format('YYYY-MM-DD'),
              },
              {
                key: 'actions',
                name: 'Actions',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {checkPermission(permissions, 'users:edit') && (
                      <Button
                        className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                        onClick={() => {
                          usersFormModalHandler(true, item)
                        }}
                      >
                        <RiEditFill />
                      </Button>
                    )}
                    {checkPermission(
                      permissions,
                      'users:managePermissions',
                    ) && (
                      <Button
                        className="me-2 btn-sm btn-warning btn-warning__simple no-svg-padding"
                        onClick={() => {
                          usersPermissionsFormModalHandler(
                            true,
                            item.id,
                            item.get_permissions || [],
                          )
                        }}
                      >
                        <RiLock2Fill />
                      </Button>
                    )}
                  </>
                ),
              },
            ]}
            data={data.data}
          />
          {filters.perPage < data.total && (
            <Pagination
              className="mt-4"
              size="small"
              current={filters.page}
              pageSize={filters.perPage}
              total={data.total}
              showSizeChanger={false}
              onChange={(page) => setFilters({ ...filters, page })}
            />
          )}
        </Spin>
      </DefaultCard>
      <UsersForm
        reloadData={getData}
        initialData={usersFormModal.initialData}
        visible={usersFormModal.visible}
        setVisibility={usersFormModalHandler}
      />
      <UsersPermissionsForm
        visible={usersPermissionsFormModal.visible}
        setVisibility={usersPermissionsFormModalHandler}
        initialData={usersPermissionsFormModal.initialData}
        userId={usersPermissionsFormModal.userId}
        reloadData={getData}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(UsersPage))
