import React, { useState } from 'react'
import { Modal } from 'antd'

import FormBuilder from '../../ui/forms/FormBuilder'

import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'

const CompanyForm = ({ initialData, title = null, visible, setVisibility, reloadData }) => {
  const [loading, setLoading] = useState(false)

  const handleForm = (values) => {
    setLoading(true)
    let url = 'api/v1/companies'
    let method = 'post'
    if (initialData && initialData.slug) {
      url = `api/v1/companies/${initialData.slug}`
      method = 'patch'
    }
    axiosRequest(url, method, values, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
          setVisibility(false, {})
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      title={title || 'Company Form'}
      visible={visible}
      onCancel={() => setVisibility(false, {})}
      destroyOnClose
      footer={false}
    >
      <FormBuilder
        onFinish={handleForm}
        loading={loading}
        formProps={{
          layout: 'vertical',
          requiredMarks: false,
          initialValues: initialData,
        }}
        fields={[
          {
            label: 'Name',
            name: 'name',
            required: true,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Company name',
            className: 'col-12',
          },
          {
            label: 'Country',
            name: 'country',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Company country',
            className: 'col-12 col-md-6',
          },
          {
            label: 'City',
            name: 'city',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Company city',
            className: 'col-12 col-md-6',
          },
          {
            label: 'Address',
            name: 'address',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Company address',
            className: 'col-12 col-md-6',
          },
          {
            label: 'ZIP',
            name: 'zip',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Company zip',
            className: 'col-12 col-md-6',
          },
          {
            label: 'Email',
            name: 'email',
            required: false,
            fieldType: 'input',
            type: 'email',
            placeholder: 'Company phone',
            className: 'col-12 col-md-6',
          },
          {
            label: 'Additional Email',
            name: 'email2',
            required: false,
            fieldType: 'input',
            type: 'email',
            placeholder: 'Company additional email',
            className: 'col-12 col-md-6',
          },
          {
            label: 'Phone',
            name: 'phone',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Company phone',
            className: 'col-12 col-md-6',
          },
          {
            label: 'Additional Phone',
            name: 'phone2',
            required: false,
            fieldType: 'input',
            type: 'text',
            placeholder: 'Company additional phone',
            className: 'col-12 col-md-6',
          },
        ]}
      />
    </Modal>
  )
}

export default CompanyForm
