import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Button, Tree, Popconfirm, Spin } from 'antd'
import { RiAddFill, RiDeleteBin3Fill, RiEditFill, RiRestartFill } from 'react-icons/ri'

import PageTitle from '../../components/ui/title/PageTitle'
import DefaultCard from '../../components/ui/cards/DefaultCard'
import CategoryForm from '../../components/pages/listings/categories/CategoryForm'

import axiosRequest from '../../helpers/axios-request.helper'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import checkPermission from '../../helpers/check-permission'
import { categoriesTree, getExpandedKeys } from '../../helpers/categories-tree'

import styles from './categories.module.scss'

const mapStateToProps = ({ users }) => ({ users })

const CategoriesPage = ({ users }) => {
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({ data: [], expandedKeys: [] })
  const [categoryForm, setCategoryForm] = useState({ visible: false, initialData: {} })

  const getData = () => {
    setLoading(true)
    axiosRequest('api/v1/listings/categories', 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          const createTree = categoriesTree(res.data.categories, null)
          const getKeys = getExpandedKeys(res.data.categories, null)
          setData({ data: createTree, expandedKeys: getKeys })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const handleCategoryForm = (visible, data = {}) => {
    setCategoryForm({ visible, initialData: data })
  }

  const handleDeleteCategory = (category, forceDelete = false) => {
    axiosRequest(
      `api/v1/listings/categories/${category.slug}?${!forceDelete ? `restore=${category.deleted_at ? 1 : 0} ` : ''}${
        forceDelete ? 'forceDelete=1' : ''
      }`,
      'delete',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          getData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  return (
    <>
      <Helmet title="Listing Categories" />
      <PageTitle
        title="Listing Categories"
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/properties', name: 'Listings' },
          { path: '/categories', name: 'Categories' },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'categories:create') && (
              <Button
                icon={<RiAddFill />}
                className="btn-primary fw-500"
                onClick={() => {
                  handleCategoryForm(true, {})
                }}
              >
                Add Category
              </Button>
            )}
          </>
        }
      />
      <DefaultCard>
        <Spin spinning={loading}>
          <Tree
            expandedKeys={data.expandedKeys}
            checkable={false}
            showLine
            selectable={false}
            treeData={data.data}
            titleRender={(item) => (
              <div className={styles.treeItem}>
                <div className={`${styles.name}`}>
                  <span className={item.categoryData.deleted_at ? 'danger-color' : ''}>{item.title}</span>
                </div>
                <div>
                  {checkPermission(permissions, 'categories:edit') && (
                    <Button
                      className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                      onClick={() => {
                        handleCategoryForm(true, item.categoryData)
                      }}
                    >
                      <RiEditFill />
                    </Button>
                  )}
                  {checkPermission(permissions, 'categories:delete') && (
                    <>
                      <Popconfirm
                        title={`Are you really want to ${
                          item.categoryData.deleted_at ? 'restore' : 'delete'
                        } this category?`}
                        onConfirm={() => handleDeleteCategory(item.categoryData)}
                        okButtonProps={{
                          className: `btn-sm ${item.categoryData.deleted_at ? 'btn-warning' : 'btn-danger'}`,
                        }}
                        cancelButtonProps={{ className: `btn-sm` }}
                      >
                        <Button
                          className={`btn-sm no-svg-padding ${
                            item.categoryData.deleted_at
                              ? 'btn-warning btn-warning__simple'
                              : 'btn-danger btn-danger__simple'
                          }`}
                        >
                          {item.categoryData.deleted_at ? <RiRestartFill /> : <RiDeleteBin3Fill />}
                        </Button>
                      </Popconfirm>
                      {item.categoryData.deleted_at && (
                        <Popconfirm
                          title={`Are you really want to permanently this category?`}
                          onConfirm={() => handleDeleteCategory(item.categoryData, true)}
                          okButtonProps={{ className: `btn-sm btn-danger` }}
                          cancelButtonProps={{ className: `btn-sm` }}
                        >
                          <Button className={`btn-sm no-svg-padding btn-danger btn-danger__simple`}>
                            <RiDeleteBin3Fill />
                          </Button>
                        </Popconfirm>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          />
        </Spin>
      </DefaultCard>
      <CategoryForm
        visible={categoryForm.visible}
        setVisibility={handleCategoryForm}
        reloadData={getData}
        initialData={categoryForm.initialData}
        categoriesData={data.data}
        expandedCategoriesData={data.expandedKeys}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(CategoriesPage))
