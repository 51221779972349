import React from 'react'
import { useDispatch, connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import FormBuilder from '../../components/ui/forms/FormBuilder'

import actions from '../../redux/users/actions'

const mapStateToProps = ({ users }) => ({ users })

const LoginPage = ({ users }) => {
  const dispatch = useDispatch()

  const handleLogin = (values) => {
    dispatch({
      type: actions.LOGIN,
      payload: values,
    })
  }

  return (
    <>
      <Helmet title="Login" />
      <FormBuilder
        loading={users.loading}
        onFinish={handleLogin}
        formProps={{ layout: 'vertical', requiredMarks: false, initialValues: null }}
        fields={[
          {
            name: 'email',
            label: 'Email',
            placeholder: 'Input your email',
            required: true,
            fieldType: 'input',
            type: 'email',
            className: 'col-12',
            select: null,
          },
          {
            name: 'password',
            label: 'Password',
            placeholder: 'Input your password',
            required: true,
            fieldType: 'input',
            type: 'password',
            className: 'col-12',
            select: null,
          },
        ]}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(LoginPage))
