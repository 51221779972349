import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Button, Pagination, Popconfirm, Spin } from 'antd'
import { RiAddFill, RiEyeFill, RiEditFill, RiDeleteBin3Fill, RiRestartFill } from 'react-icons/ri'

import PageTitle from '../components/ui/title/PageTitle'
import DefaultCard from '../components/ui/cards/DefaultCard'
import Table from '../components/ui/table/Table'
import CompanyForm from '../components/pages/companies/CompanyForm'

import axiosRequest from '../helpers/axios-request.helper'
import axiosErrorHelper from '../helpers/axios-error.helper'
import checkPermission from '../helpers/check-permission'

const mapStateToProps = ({ users }) => ({ users })

const CompaniesPage = ({ users }) => {
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({ page: 1, perPage: 30 })
  const [data, setData] = useState({ data: [], total: 0 })
  const [companyFormModal, setCompanyFormModal] = useState({ visible: false, initialData: {} })

  const getData = () => {
    setLoading(true)
    axiosRequest(`api/v1/companies?page=${filters.page}&perPage=${filters.perPage}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.companies) {
          const { data, total } = res.data.companies
          setLoading(false)
          setData({ data, total })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleCompanyDelete = (company, forceDelete = false) => {
    axiosRequest(
      `api/v1/companies/${company.slug}?${!forceDelete ? `restore=${company.deleted_at ? '1' : '0'}` : ''}${
        forceDelete ? 'forceDelete=1' : ''
      }`,
      'delete',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          getData()
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }

  const companyModalHandler = (isVisible, data) => {
    setCompanyFormModal({ visible: isVisible, initialData: data })
  }

  return (
    <>
      <Helmet title="Companies" />
      <PageTitle
        title="Companies"
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/companies', name: 'Companies' },
          { path: '/companies', name: 'List' },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'companies:create') && (
              <Button
                icon={<RiAddFill />}
                className="btn-primary fw-500"
                onClick={() => {
                  companyModalHandler(true, {})
                }}
              >
                Add Company
              </Button>
            )}
          </>
        }
      />
      <Spin spinning={loading}>
        <DefaultCard>
          <Table
            columns={[
              {
                key: 'name',
                name: 'Name',
                headClassName: null,
                itemClassName: null,
                render: (item) => <strong>{item.name}</strong>,
              },
              {
                key: 'status',
                name: 'Status',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <span className={item.deleted_at ? 'danger-color' : 'success-color'}>
                    {item.deleted_at ? 'Disabled' : 'Active'}
                  </span>
                ),
              },
              {
                key: 'country',
                name: 'Country',
                headClassName: null,
                itemClassName: null,
                render: (item) => item.country || '-',
              },
              {
                key: 'email',
                name: 'Email',
                headClassName: null,
                itemClassName: null,
                render: (item) => item.email || '-',
              },
              {
                key: 'phone',
                name: 'Phone',
                headClassName: null,
                itemClassName: null,
                render: (item) => item.phone || '-',
              },
              {
                key: 'users',
                name: 'Members',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <p className="mb-0 span-coma">
                    {item.get_users && item.get_users.length > 0
                      ? item.get_users.map((user) => <span key={user.id}>{user.name}</span>)
                      : 'No users added'}
                  </p>
                ),
              },
              {
                key: 'websites',
                name: 'Websites',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <p className="mb-0 span-coma">
                    {item.get_websites && item.get_websites.length > 0
                      ? item.get_websites.map((website) => (
                          <span key={website.id}>
                            <a href={website.domain} target="_blank" rel="noreferrer noopener nofollow">
                              {website.name}
                            </a>
                          </span>
                        ))
                      : 'No websites added'}
                  </p>
                ),
              },
              {
                key: 'actions',
                name: 'Actions',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {checkPermission(permissions, 'companies:single') && (
                      <Button className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding">
                        <RiEyeFill />
                      </Button>
                    )}
                    {checkPermission(permissions, 'companies:edit') && (
                      <Button
                        className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                        onClick={() => {
                          companyModalHandler(true, item)
                        }}
                      >
                        <RiEditFill />
                      </Button>
                    )}
                    {checkPermission(permissions, 'companies:delete') && (
                      <>
                        <Popconfirm
                          title={`Are you really want to ${item.deleted_at ? 'restore' : 'delete'} this company?`}
                          onConfirm={() => handleCompanyDelete(item)}
                          okButtonProps={{ className: `btn-sm ${item.deleted_at ? 'btn-warning' : 'btn-danger'}` }}
                          cancelButtonProps={{ className: `btn-sm` }}
                        >
                          <Button
                            className={`btn-sm no-svg-padding ${
                              item.deleted_at ? 'btn-warning btn-warning__simple' : 'btn-danger btn-danger__simple'
                            }`}
                          >
                            {item.deleted_at ? <RiRestartFill /> : <RiDeleteBin3Fill />}
                          </Button>
                        </Popconfirm>
                        {item.deleted_at && (
                          <Popconfirm
                            title={`Are you really want to permanently delete this company?`}
                            onConfirm={() => handleCompanyDelete(item, true)}
                            okButtonProps={{ className: `btn-sm btn-danger` }}
                            cancelButtonProps={{ className: `btn-sm` }}
                          >
                            <Button className={`btn-sm no-svg-padding btn-danger btn-danger__simple`}>
                              <RiDeleteBin3Fill />
                            </Button>
                          </Popconfirm>
                        )}
                      </>
                    )}
                  </>
                ),
              },
            ]}
            data={data.data}
          />
          {filters.perPage < data.total && (
            <Pagination
              className="mt-4"
              size="small"
              current={filters.page}
              pageSize={filters.perPage}
              total={data.total}
              showSizeChanger={false}
              onChange={(page) => setFilters({ ...filters, page })}
            />
          )}
        </DefaultCard>
      </Spin>
      <CompanyForm
        initialData={companyFormModal.initialData}
        visible={companyFormModal.visible}
        setVisibility={companyModalHandler}
        reloadData={getData}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(CompaniesPage))
