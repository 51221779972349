import React, { useState } from 'react'
import { Modal, Tabs } from 'antd'

import FormBuilder from '../../ui/forms/FormBuilder'

import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'
import AssignWebsites from './AssignWebsites'

const UsersForm = ({ initialData, title = null, visible, setVisibility, reloadData }) => {
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = (values) => {
    setLoading(true)
    let url = 'api/v1/users'
    let method = 'post'
    if (initialData && initialData.id) {
      url = `api/v1/users/${initialData.id}`
      method = 'patch'
    }
    axiosRequest(url, method, values, true)
      .then((res) => {
        if (res.data && res.data.success) {
          reloadData()
          setVisibility(false, {})
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      title={title || 'User Form'}
      visible={visible}
      onCancel={() => setVisibility(false, {})}
      destroyOnClose
      footer={false}
    >
      <Tabs>
        <Tabs.TabPane tab="User Data" key="1">
          <FormBuilder
            onFinish={handleFormSubmit}
            loading={loading}
            formProps={{
              layout: 'vertical',
              requiredMarks: false,
              initialValues: initialData,
            }}
            fields={[
              {
                label: 'Name',
                name: 'name',
                required: true,
                fieldType: 'input',
                type: 'text',
                placeholder: 'User full name',
                className: 'col-12',
              },
              {
                label: 'Email',
                name: 'email',
                required: true,
                fieldType: 'input',
                type: 'email',
                placeholder: 'User email',
                className: 'col-12',
              },
              {
                label: 'Phone',
                name: 'phone',
                required: false,
                fieldType: 'input',
                type: 'text',
                placeholder: 'User phone',
                className: 'col-12',
              },
              {
                label: 'Password',
                name: 'password',
                required: false,
                fieldType: 'input',
                type: 'password',
                placeholder: 'User password',
                className: 'col-12',
              },
              {
                label: 'User Type',
                name: 'type',
                required: true,
                fieldType: 'select',
                placeholder: 'Choose user type',
                className: 'col-12',
                select: {
                  isMultiple: false,
                  loading: false,
                  options: [
                    { label: 'Management Team', value: 'admin' },
                    { label: 'Sales Team', value: 'sales' },
                    { label: 'Acquisition Team', value: 'acquisition' },
                  ],
                },
              },
              {
                label: 'Is Banned?',
                name: 'isBanned',
                required: true,
                fieldType: 'select',
                placeholder: 'Is user banned?',
                className: 'col-12',
                select: {
                  isMultiple: false,
                  loading: false,
                  options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ],
                },
              },
              {
                label: 'Is Admin?',
                name: 'isAdmin',
                required: true,
                fieldType: 'select',
                placeholder: 'Is user admin?',
                className: 'col-12',
                select: {
                  isMultiple: false,
                  loading: false,
                  options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ],
                },
              },
            ]}
          />
        </Tabs.TabPane>
        {initialData.id && (
          <>
            <Tabs.TabPane tab="Companies" key="2">
              <p className="mb-0">In Progress</p>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Websites" key="3">
              <AssignWebsites reloadData={reloadData} initialData={initialData} />
            </Tabs.TabPane>
          </>
        )}
      </Tabs>
    </Modal>
  )
}

export default UsersForm
