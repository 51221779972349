import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import axios from 'axios'
import axiosErrorHelper from '../../../helpers/axios-error.helper'

const ActiveSkillBalance = ({ refetch, apiToken }) => {
  const [loading, setLoading] = useState(true)
  const [balance, setBalance] = useState(0)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_ACTIVESKILL_URL}public-api/v1/portal-station/company-details`, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        withCredentials: false,
      })
      .then((res) => {
        const { company } = res.data
        setBalance(parseInt(company.balance, 10))
        setLoading(false)
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
  }, [apiToken])

  return loading ? (
    <Skeleton paragraph={{ rows: 0 }} active />
  ) : (
    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(balance / 100)
  )
}

export default ActiveSkillBalance
