const convertJsonToUrlParams = (obj = {}) => {
  let str = []

  for (let p in obj) {
    if (obj[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  }
  const urlParams = '?' + str.join('&')
  return urlParams
}

export default convertJsonToUrlParams
