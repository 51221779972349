import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, Select } from 'antd'

import US_STATES from '../../../../data/us-stated'
import axiosRequest from '../../../../helpers/axios-request.helper'
import _ from 'lodash'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'

const CustomersFilters = ({ visible, setVisibility, filters, setFilters }) => {
  const [form] = Form.useForm()
  const [leadFilters, setLeadFilters] = useState({})
  const [categoriesLoading, setCategoriesLoading] = useState(true)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    if (visible) {
      setCategoriesLoading(true)
      axiosRequest('api/v1/listings/categories', 'get', null, true)
        .then((res) => {
          const options = []
          _.forEach(res.data.categories, (category) => {
            return options.push({ value: category.id, label: category.name })
          })
          setCategories(options)
        })
        .catch((e) => axiosErrorHelper(e))
        .finally(() => setCategoriesLoading(false))
    }
  }, [visible])

  const handleFilters = () => {
    form.setFieldsValue({ ...leadFilters })
    setFilters({ ...filters, ...leadFilters })
  }

  const clearFilters = () => {
    clearFields()
    form.resetFields()
    setFilters({ page: filters.page, perPage: filters.perPage })
    setLeadFilters({})
  }

  const clearFields = () => {
    form.setFieldsValue({ q: null, onlyWholesalers: null })
  }

  return (
    <Drawer
      title="Customers Filters"
      visible={visible}
      destroyOnClose
      width={370}
      onClose={() => {
        setVisibility(false)
      }}
      className="property-form-drawer drawer-width-400"
      footer={
        <>
          <div className="row">
            <div className="col-6">
              <Button className="w-100" onClick={() => clearFilters()}>
                Clear
              </Button>
            </div>
            <div className="col-6">
              <Button className="btn-primary w-100" onClick={() => handleFilters()}>
                Submit
              </Button>
            </div>
          </div>
        </>
      }
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="default-form"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleFilters()
          }
        }}
      >
        <Form layout="vertical" requiredMark={false} initialValues={filters} form={form}>
          <Form.Item name="q" label="Find by customer">
            <Input
              allowClear
              placeholder="Search by customer name, email, phone..."
              onChange={(e) => setLeadFilters({ ...leadFilters, q: e.target.value })}
            />
          </Form.Item>
          <Form.Item name="category" label="Category">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Filter customer by category..."
              loading={categoriesLoading}
              onChange={(val) => setLeadFilters({ ...leadFilters, category: val })}
            >
              {categories.length > 0 &&
                categories.map(({ value, label }) => (
                  <Select.Option key={`category_${value}`} value={value}>
                    {label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name="state" label="State">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Filter customer by state..."
              onChange={(val) => setLeadFilters({ ...leadFilters, state: val })}
            >
              {US_STATES.map(({ name }) => (
                <Select.Option key={`state_${name}`} value={name}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="city" label="Find by city">
            <Input
              allowClear
              placeholder="Search by city"
              onChange={(e) => setLeadFilters({ ...leadFilters, city: e.target.value })}
            />
          </Form.Item>
          <Form.Item name="onlyWholesalers" label="Status">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Filter customer by their status..."
              onChange={(val) => setLeadFilters({ ...leadFilters, onlyWholesalers: val })}
            >
              <Select.Option value="all">Show All</Select.Option>
              <Select.Option value="wholesalers">Only Wholesalers</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  )
}

export default CustomersFilters
