import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Button, Pagination, Popconfirm, Spin } from 'antd'

import PageTitle from '../../components/ui/title/PageTitle'
import DefaultCard from '../../components/ui/cards/DefaultCard'
import Table from '../../components/ui/table/Table'
import RequestDetailsModal from '../../components/pages/acquisition/requests/RequestDetailsModal'

import axiosRequest from '../../helpers/axios-request.helper'
import convertJsonToUrlParams from '../../helpers/convert-json-to-url-params'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import checkPermission from '../../helpers/check-permission'
import { RiDeleteBin3Fill, RiEyeFill, RiRestartFill } from 'react-icons/ri'

const mapStateToProps = ({ users }) => ({ users })

const AcquisitionsRequestsPage = ({ users }) => {
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({ page: 1, perPage: 30 })
  const [data, setData] = useState({ data: [], total: 0 })
  const [requestDetailsModal, setRequestsDetailsModal] = useState({ visible: false, data: null })

  const getData = () => {
    setLoading(true)
    axiosRequest(`api/v1/acquisition/requests${convertJsonToUrlParams(filters)}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.success) {
          setData({ data: res.data.requests, total: res.data.total })
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const deleteRequest = (item, type = 'delete') => {
    setLoading(true)
    let url = `api/v1/acquisition/requests/${item.id}`
    if (type === 'restore') {
      url = url + '?restore=1'
    }
    axiosRequest(url, 'delete', null, true)
      .then(() => {
        getData()
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const requestDetailsHandler = (visible = false, data = null) => {
    setRequestsDetailsModal({ visible, data })
  }

  return (
    <>
      <Helmet title="Properties" />
      <PageTitle title={`Acquisition Requests (${data.total})`} />
      <Spin spinning={loading}>
        <DefaultCard>
          <Table
            columns={[
              {
                key: 'website',
                name: 'Website',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <strong className={item.deleted_at ? 'danger-color' : ''}>
                    {item.get_website && item.get_website.id ? (
                      <a href={item.get_website.domain} target="_blank" rel="noreferrer nofollow noopener">
                        {item.get_website.name}
                      </a>
                    ) : (
                      'Undefined'
                    )}
                  </strong>
                ),
              },
              {
                key: 'fullName',
                name: 'Full Name',
                headClassName: null,
                itemClassName: null,
                render: (item) => item.fullName || '-',
              },
              {
                key: 'email',
                name: 'Email/Phone',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    <p className="mb-1">{item.email || '-'}</p>
                    <p className="mb-0">{item.phone || '-'}</p>
                  </>
                ),
              },
              {
                key: 'parcelNumber',
                name: 'Parcel Number',
                headClassName: null,
                itemClassName: null,
                render: (item) => item.parcelNumber || '-',
              },
              {
                key: 'address',
                name: 'Location',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    <p className="mb-1">State: {item.state || '-'}</p>
                    <p className="mb-1">County: {item.county || '-'}</p>
                    <p className="mb-1">Address: {item.address || '-'}</p>
                  </>
                ),
              },
              {
                key: 'actions',
                name: 'Actions',
                headClassName: null,
                itemClassName: null,
                render: (item) => (
                  <>
                    {checkPermission(permissions, 'acquisitionRequests:single') && (
                      <Button
                        className="me-2 btn-sm btn-primary btn-primary__simple no-svg-padding"
                        onClick={() => {
                          requestDetailsHandler(true, item)
                        }}
                      >
                        <RiEyeFill />
                      </Button>
                    )}
                    {checkPermission(permissions, 'acquisitionRequests:delete') && (
                      <>
                        <Popconfirm
                          title={`Are you really want to ${
                            item.deleted_at ? 'restore' : 'delete'
                          } this acquisition request?`}
                          onConfirm={() => deleteRequest(item, item.deleted_at ? 'restore' : 'delete')}
                          okButtonProps={{ className: `btn-sm ${item.deleted_at ? 'btn-warning' : 'btn-danger'}` }}
                          cancelButtonProps={{ className: `btn-sm` }}
                        >
                          <Button
                            className={`btn-sm no-svg-padding ${
                              item.deleted_at ? 'btn-warning btn-warning__simple' : 'btn-danger btn-danger__simple'
                            }`}
                          >
                            {item.deleted_at ? <RiRestartFill /> : <RiDeleteBin3Fill />}
                          </Button>
                        </Popconfirm>
                        {item.deleted_at && (
                          <Popconfirm
                            title={`Are you really want to permanently delete this  acquisition request?`}
                            onConfirm={() => deleteRequest(item, 'delete')}
                            okButtonProps={{ className: `btn-sm btn-danger` }}
                            cancelButtonProps={{ className: `btn-sm` }}
                          >
                            <Button className={`btn-sm no-svg-padding btn-danger btn-danger__simple`}>
                              <RiDeleteBin3Fill />
                            </Button>
                          </Popconfirm>
                        )}
                      </>
                    )}
                  </>
                ),
              },
            ]}
            data={data.data}
          />
          {filters.perPage < data.total && (
            <Pagination
              className="mt-4"
              size="small"
              current={filters.page}
              pageSize={filters.perPage}
              total={data.total}
              showSizeChanger={false}
              onChange={(page) => setFilters({ ...filters, page })}
            />
          )}
        </DefaultCard>
      </Spin>
      <RequestDetailsModal
        data={requestDetailsModal.data}
        visibility={requestDetailsModal.visible}
        setVisibility={requestDetailsHandler}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(AcquisitionsRequestsPage))
