import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Button, Pagination, Spin } from 'antd'
import { RiAddFill, RiFilter3Fill } from 'react-icons/ri'

import PageTitle from '../../components/ui/title/PageTitle'
import DefaultCard from '../../components/ui/cards/DefaultCard'
import ClientsTable from '../../components/pages/crm/clients/ClientsTable'
import CustomersFilters from '../../components/pages/crm/clients/Filters'
import ClientForm from '../../components/pages/crm/clients/ClientForm'

import axiosRequest from '../../helpers/axios-request.helper'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import checkPermission from '../../helpers/check-permission'
import convertJsonToUrlParams from '../../helpers/convert-json-to-url-params'

const mapStateToProps = ({ users }) => ({ users })

const ClientsPage = ({ users }) => {
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({ page: 1, perPage: 24, onlyWholesalers: 'all' })
  const [data, setData] = useState({ data: [], total: 0 })
  const [filtersVisibility, setFiltersVisibility] = useState(false)
  const [customerForm, setCustomerForm] = useState({ visible: false, data: {} })

  const getData = () => {
    setLoading(true)
    axiosRequest(`api/v1/crm/customers${convertJsonToUrlParams(filters)}`, 'get', null, true)
      .then((res) => {
        setData({ data: res.data.data.data.data, total: res.data.data.total })
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <>
      <Helmet title="Clients" />
      <PageTitle
        title={`Clients (${data.total || 0})`}
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          { path: '/crm/leads', name: 'CRM' },
          { path: '/crm/clients', name: 'Clients' },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'clients:create') && (
              <Button
                icon={<RiAddFill />}
                className="btn-primary fw-500 me-3"
                onClick={() => {
                  setCustomerForm({ visible: true, data: {} })
                }}
              >
                Add Client
              </Button>
            )}
            <Button
              icon={<RiFilter3Fill />}
              className="btn-primary fw-500"
              onClick={() => {
                setFiltersVisibility(true)
              }}
            >
              Filters
            </Button>
          </>
        }
      />
      <Spin spinning={loading}>
        <DefaultCard>
          <ClientsTable clients={data.data} reloadData={getData} permissions={permissions} setVisibility={setCustomerForm} />
          {filters.perPage < data.total && (
            <Pagination
              className="mt-4"
              size="small"
              current={filters.page}
              pageSize={filters.perPage}
              total={data.total}
              showSizeChanger={false}
              onChange={(page) => setFilters({ ...filters, page })}
            />
          )}
        </DefaultCard>
      </Spin>
      <CustomersFilters
        setFilters={setFilters}
        setVisibility={setFiltersVisibility}
        filters={filters}
        visible={filtersVisibility}
      />
      <ClientForm
        setVisibility={setCustomerForm}
        reloadData={getData}
        initialData={customerForm.data}
        visibility={customerForm.visible}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(ClientsPage))
