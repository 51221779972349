import React from 'react'
import PropTypes from 'prop-types'

import styles from './table.module.scss'

const Table = ({ columns, data }) => {
  return (
    <div className={styles.defaultTable}>
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th className={column.headClassName || ''} key={column.key}>
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0
            ? data.map((item, index) => {
                return (
                  <tr key={item.id || index}>
                    {columns.map((column) => (
                      <td className={column.itemClassName || ''} key={column.key}>
                        {column.render ? column.render(item) : item[column.key]}
                      </td>
                    ))}
                  </tr>
                )
              })
            : null}
        </tbody>
      </table>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string,
      headClassName: PropTypes.any,
      itemClassName: PropTypes.any,
      render: PropTypes.any,
    }),
  ).isRequired,
  data: PropTypes.any.isRequired,
}

export default Table
