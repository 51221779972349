import React from 'react'
import { Form, InputNumber, Select } from 'antd'

const PropertyPrices = () => {
  return (
    <div className="row">
      <Form.Item
        className="col-12"
        name={['main', 'price']}
        label="Property Price"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <InputNumber min={0} step={0.01} placeholder="Property price" />
      </Form.Item>
      <Form.Item
        className="col-12"
        name={['main', 'contractPrice']}
        label="Contract Price"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <InputNumber min={0} step={0.01} placeholder="Property price" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'processingFee']}
        label="Deposit"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <InputNumber min={0} step={0.01} placeholder="Deposit" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6"
        name={['main', 'sale_bonus']}
        label="Sale Bonus"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <InputNumber min={0} step={0.01} placeholder="Bonus for sale" />
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 mb-md-0"
        name={['main', 'isOwnerFinance']}
        label="Is Owner Finance"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select optionFilterProp="children">
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className="col-12 col-md-6 mb-0"
        name={['main', 'ownerFinancePrice']}
        label="Owner Finance Price"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <InputNumber
          min={0}
          step={0.01}
          placeholder="Property owner finance price"
        />
      </Form.Item>
      <Form.Item
        className="col-12 mt-4"
        name={['main', 'downPaymentPrice']}
        label="Down Payment"
        rules={[
          {
            required: false,
            message: 'This field is required',
          },
        ]}
      >
        <InputNumber min={0} step={0.01} placeholder="Property down payment" />
      </Form.Item>
    </div>
  )
}

export default PropertyPrices
