export const statesFips = {
  10: 'Delaware',
  11: 'District of Columbia',
  12: 'Florida',
  13: 'Georgia',
  15: 'Hawaii',
  16: 'Idaho',
  17: 'Illinois',
  18: 'Indiana',
  19: 'Iowa',
  20: 'Kansas',
  21: 'Kentucky',
  22: 'Louisiana',
  23: 'Maine',
  24: 'Maryland',
  25: 'Massachusetts',
  26: 'Michigan',
  27: 'Minnesota',
  28: 'Mississippi',
  29: 'Missouri',
  30: 'Montana',
  31: 'Nebraska',
  32: 'Nevada',
  33: 'New Hampshire',
  34: 'New Jersey',
  35: 'New Mexico',
  36: 'New York',
  37: 'North Carolina',
  38: 'North Dakota',
  39: 'Ohio',
  40: 'Oklahoma',
  41: 'Oregon',
  42: 'Pennsylvania',
  44: 'Rhode Island',
  45: 'South Carolina',
  46: 'South Dakota',
  47: 'Tennessee',
  48: 'Texas',
  49: 'Utah',
  50: 'Vermont',
  51: 'Virginia',
  53: 'Washington',
  54: 'West Virginia',
  55: 'Wisconsin',
  56: 'Wyoming',
  '01': 'Alabama',
  '02': 'Alaska',
  '04': 'Arizona',
  '05': 'Arkansas',
  '06': 'California',
  '08': 'Colorado',
  '09': 'Connecticut',
  72: 'Puerto Rico',
  66: 'Guam',
  78: 'Virgin Islands',
  60: 'American Samoa',
}

export const countiesFips = [
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '001',
    countyName: 'Autauga County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '003',
    countyName: 'Baldwin County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '005',
    countyName: 'Barbour County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '007',
    countyName: 'Bibb County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '009',
    countyName: 'Blount County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '011',
    countyName: 'Bullock County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '013',
    countyName: 'Butler County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '015',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '017',
    countyName: 'Chambers County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '019',
    countyName: 'Cherokee County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '021',
    countyName: 'Chilton County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '023',
    countyName: 'Choctaw County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '025',
    countyName: 'Clarke County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '027',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '029',
    countyName: 'Cleburne County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '031',
    countyName: 'Coffee County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '033',
    countyName: 'Colbert County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '035',
    countyName: 'Conecuh County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '037',
    countyName: 'Coosa County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '039',
    countyName: 'Covington County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '041',
    countyName: 'Crenshaw County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '043',
    countyName: 'Cullman County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '045',
    countyName: 'Dale County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '047',
    countyName: 'Dallas County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '049',
    countyName: 'DeKalb County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '051',
    countyName: 'Elmore County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '053',
    countyName: 'Escambia County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '055',
    countyName: 'Etowah County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '057',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '059',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '061',
    countyName: 'Geneva County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '063',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '065',
    countyName: 'Hale County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '067',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '069',
    countyName: 'Houston County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '071',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '073',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '075',
    countyName: 'Lamar County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '077',
    countyName: 'Lauderdale County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '079',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '081',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '083',
    countyName: 'Limestone County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '085',
    countyName: 'Lowndes County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '087',
    countyName: 'Macon County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '089',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '091',
    countyName: 'Marengo County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '093',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '095',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '097',
    countyName: 'Mobile County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '099',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '101',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '103',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '105',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '107',
    countyName: 'Pickens County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '109',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '111',
    countyName: 'Randolph County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '113',
    countyName: 'Russell County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '115',
    countyName: 'St. Clair County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '117',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '119',
    countyName: 'Sumter County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '121',
    countyName: 'Talladega County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '123',
    countyName: 'Tallapoosa County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '125',
    countyName: 'Tuscaloosa County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '127',
    countyName: 'Walker County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '129',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '131',
    countyName: 'Wilcox County',
    num: 'H1',
  },
  {
    state: 'AL',
    stateFips: '01',
    countyFips: '133',
    countyName: 'Winston County',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '013',
    countyName: 'Aleutians East Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '016',
    countyName: 'Aleutians West Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '020',
    countyName: 'Anchorage Municipality',
    num: 'H6',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '050',
    countyName: 'Bethel Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '060',
    countyName: 'Bristol Bay Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '068',
    countyName: 'Denali Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '070',
    countyName: 'Dillingham Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '090',
    countyName: 'Fairbanks North Star Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '100',
    countyName: 'Haines Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '105',
    countyName: 'Hoonah-Angoon Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '110',
    countyName: 'Juneau City and Borough',
    num: 'H6',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '122',
    countyName: 'Kenai Peninsula Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '130',
    countyName: 'Ketchikan Gateway Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '150',
    countyName: 'Kodiak Island Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '164',
    countyName: 'Lake and Peninsula Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '170',
    countyName: 'Matanuska-Susitna Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '180',
    countyName: 'Nome Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '185',
    countyName: 'North Slope Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '188',
    countyName: 'Northwest Arctic Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '195',
    countyName: 'Petersburg Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '198',
    countyName: 'Prince of Wales-Hyder Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '220',
    countyName: 'Sitka City and Borough',
    num: 'H6',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '230',
    countyName: 'Skagway Municipality',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '240',
    countyName: 'Southeast Fairbanks Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '261',
    countyName: 'Valdez-Cordova Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '270',
    countyName: 'Wade Hampton Census Area',
    num: 'H5',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '275',
    countyName: 'Wrangell City and Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '282',
    countyName: 'Yakutat City and Borough',
    num: 'H1',
  },
  {
    state: 'AK',
    stateFips: '02',
    countyFips: '290',
    countyName: 'Yukon-Koyukuk Census Area',
    num: 'H5',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '001',
    countyName: 'Apache County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '003',
    countyName: 'Cochise County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '005',
    countyName: 'Coconino County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '007',
    countyName: 'Gila County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '009',
    countyName: 'Graham County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '011',
    countyName: 'Greenlee County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '012',
    countyName: 'La Paz County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '013',
    countyName: 'Maricopa County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '015',
    countyName: 'Mohave County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '017',
    countyName: 'Navajo County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '019',
    countyName: 'Pima County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '021',
    countyName: 'Pinal County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '023',
    countyName: 'Santa Cruz County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '025',
    countyName: 'Yavapai County',
    num: 'H1',
  },
  {
    state: 'AZ',
    stateFips: '04',
    countyFips: '027',
    countyName: 'Yuma County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '001',
    countyName: 'Arkansas County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '003',
    countyName: 'Ashley County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '005',
    countyName: 'Baxter County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '007',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '009',
    countyName: 'Boone County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '011',
    countyName: 'Bradley County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '013',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '015',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '017',
    countyName: 'Chicot County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '019',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '021',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '023',
    countyName: 'Cleburne County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '025',
    countyName: 'Cleveland County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '027',
    countyName: 'Columbia County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '029',
    countyName: 'Conway County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '031',
    countyName: 'Craighead County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '033',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '035',
    countyName: 'Crittenden County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '037',
    countyName: 'Cross County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '039',
    countyName: 'Dallas County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '041',
    countyName: 'Desha County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '043',
    countyName: 'Drew County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '045',
    countyName: 'Faulkner County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '047',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '049',
    countyName: 'Fulton County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '051',
    countyName: 'Garland County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '053',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '055',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '057',
    countyName: 'Hempstead County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '059',
    countyName: 'Hot Spring County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '061',
    countyName: 'Howard County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '063',
    countyName: 'Independence County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '065',
    countyName: 'Izard County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '067',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '069',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '071',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '073',
    countyName: 'Lafayette County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '075',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '077',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '079',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '081',
    countyName: 'Little River County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '083',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '085',
    countyName: 'Lonoke County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '087',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '089',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '091',
    countyName: 'Miller County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '093',
    countyName: 'Mississippi County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '095',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '097',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '099',
    countyName: 'Nevada County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '101',
    countyName: 'Newton County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '103',
    countyName: 'Ouachita County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '105',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '107',
    countyName: 'Phillips County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '109',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '111',
    countyName: 'Poinsett County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '113',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '115',
    countyName: 'Pope County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '117',
    countyName: 'Prairie County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '119',
    countyName: 'Pulaski County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '121',
    countyName: 'Randolph County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '123',
    countyName: 'St. Francis County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '125',
    countyName: 'Saline County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '127',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '129',
    countyName: 'Searcy County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '131',
    countyName: 'Sebastian County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '133',
    countyName: 'Sevier County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '135',
    countyName: 'Sharp County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '137',
    countyName: 'Stone County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '139',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '141',
    countyName: 'Van Buren County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '143',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '145',
    countyName: 'White County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '147',
    countyName: 'Woodruff County',
    num: 'H1',
  },
  {
    state: 'AR',
    stateFips: '05',
    countyFips: '149',
    countyName: 'Yell County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '001',
    countyName: 'Alameda County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '003',
    countyName: 'Alpine County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '005',
    countyName: 'Amador County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '007',
    countyName: 'Butte County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '009',
    countyName: 'Calaveras County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '011',
    countyName: 'Colusa County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '013',
    countyName: 'Contra Costa County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '015',
    countyName: 'Del Norte County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '017',
    countyName: 'El Dorado County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '019',
    countyName: 'Fresno County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '021',
    countyName: 'Glenn County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '023',
    countyName: 'Humboldt County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '025',
    countyName: 'Imperial County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '027',
    countyName: 'Inyo County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '029',
    countyName: 'Kern County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '031',
    countyName: 'Kings County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '033',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '035',
    countyName: 'Lassen County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '037',
    countyName: 'Los Angeles County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '039',
    countyName: 'Madera County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '041',
    countyName: 'Marin County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '043',
    countyName: 'Mariposa County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '045',
    countyName: 'Mendocino County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '047',
    countyName: 'Merced County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '049',
    countyName: 'Modoc County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '051',
    countyName: 'Mono County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '053',
    countyName: 'Monterey County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '055',
    countyName: 'Napa County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '057',
    countyName: 'Nevada County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '059',
    countyName: 'Orange County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '061',
    countyName: 'Placer County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '063',
    countyName: 'Plumas County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '065',
    countyName: 'Riverside County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '067',
    countyName: 'Sacramento County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '069',
    countyName: 'San Benito County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '071',
    countyName: 'San Bernardino County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '073',
    countyName: 'San Diego County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '075',
    countyName: 'San Francisco County',
    num: 'H6',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '077',
    countyName: 'San Joaquin County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '079',
    countyName: 'San Luis Obispo County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '081',
    countyName: 'San Mateo County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '083',
    countyName: 'Santa Barbara County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '085',
    countyName: 'Santa Clara County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '087',
    countyName: 'Santa Cruz County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '089',
    countyName: 'Shasta County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '091',
    countyName: 'Sierra County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '093',
    countyName: 'Siskiyou County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '095',
    countyName: 'Solano County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '097',
    countyName: 'Sonoma County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '099',
    countyName: 'Stanislaus County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '101',
    countyName: 'Sutter County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '103',
    countyName: 'Tehama County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '105',
    countyName: 'Trinity County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '107',
    countyName: 'Tulare County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '109',
    countyName: 'Tuolumne County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '111',
    countyName: 'Ventura County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '113',
    countyName: 'Yolo County',
    num: 'H1',
  },
  {
    state: 'CA',
    stateFips: '06',
    countyFips: '115',
    countyName: 'Yuba County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '003',
    countyName: 'Alamosa County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '005',
    countyName: 'Arapahoe County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '007',
    countyName: 'Archuleta County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '009',
    countyName: 'Baca County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '011',
    countyName: 'Bent County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '013',
    countyName: 'Boulder County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '014',
    countyName: 'Broomfield County',
    num: 'H6',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '015',
    countyName: 'Chaffee County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '017',
    countyName: 'Cheyenne County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '019',
    countyName: 'Clear Creek County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '021',
    countyName: 'Conejos County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '023',
    countyName: 'Costilla County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '025',
    countyName: 'Crowley County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '027',
    countyName: 'Custer County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '029',
    countyName: 'Delta County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '031',
    countyName: 'Denver County',
    num: 'H6',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '033',
    countyName: 'Dolores County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '035',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '037',
    countyName: 'Eagle County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '039',
    countyName: 'Elbert County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '041',
    countyName: 'El Paso County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '043',
    countyName: 'Fremont County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '045',
    countyName: 'Garfield County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '047',
    countyName: 'Gilpin County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '049',
    countyName: 'Grand County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '051',
    countyName: 'Gunnison County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '053',
    countyName: 'Hinsdale County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '055',
    countyName: 'Huerfano County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '057',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '059',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '061',
    countyName: 'Kiowa County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '063',
    countyName: 'Kit Carson County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '065',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '067',
    countyName: 'La Plata County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '069',
    countyName: 'Larimer County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '071',
    countyName: 'Las Animas County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '073',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '075',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '077',
    countyName: 'Mesa County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '079',
    countyName: 'Mineral County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '081',
    countyName: 'Moffat County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '083',
    countyName: 'Montezuma County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '085',
    countyName: 'Montrose County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '087',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '089',
    countyName: 'Otero County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '091',
    countyName: 'Ouray County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '093',
    countyName: 'Park County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '095',
    countyName: 'Phillips County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '097',
    countyName: 'Pitkin County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '099',
    countyName: 'Prowers County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '101',
    countyName: 'Pueblo County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '103',
    countyName: 'Rio Blanco County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '105',
    countyName: 'Rio Grande County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '107',
    countyName: 'Routt County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '109',
    countyName: 'Saguache County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '111',
    countyName: 'San Juan County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '113',
    countyName: 'San Miguel County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '115',
    countyName: 'Sedgwick County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '117',
    countyName: 'Summit County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '119',
    countyName: 'Teller County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '121',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '123',
    countyName: 'Weld County',
    num: 'H1',
  },
  {
    state: 'CO',
    stateFips: '08',
    countyFips: '125',
    countyName: 'Yuma County',
    num: 'H1',
  },
  {
    state: 'CT',
    stateFips: '09',
    countyFips: '001',
    countyName: 'Fairfield County',
    num: 'H4',
  },
  {
    state: 'CT',
    stateFips: '09',
    countyFips: '003',
    countyName: 'Hartford County',
    num: 'H4',
  },
  {
    state: 'CT',
    stateFips: '09',
    countyFips: '005',
    countyName: 'Litchfield County',
    num: 'H4',
  },
  {
    state: 'CT',
    stateFips: '09',
    countyFips: '007',
    countyName: 'Middlesex County',
    num: 'H4',
  },
  {
    state: 'CT',
    stateFips: '09',
    countyFips: '009',
    countyName: 'New Haven County',
    num: 'H4',
  },
  {
    state: 'CT',
    stateFips: '09',
    countyFips: '011',
    countyName: 'New London County',
    num: 'H4',
  },
  {
    state: 'CT',
    stateFips: '09',
    countyFips: '013',
    countyName: 'Tolland County',
    num: 'H4',
  },
  {
    state: 'CT',
    stateFips: '09',
    countyFips: '015',
    countyName: 'Windham County',
    num: 'H4',
  },
  {
    state: 'DE',
    stateFips: '10',
    countyFips: '001',
    countyName: 'Kent County',
    num: 'H1',
  },
  {
    state: 'DE',
    stateFips: '10',
    countyFips: '003',
    countyName: 'New Castle County',
    num: 'H1',
  },
  {
    state: 'DE',
    stateFips: '10',
    countyFips: '005',
    countyName: 'Sussex County',
    num: 'H1',
  },
  {
    state: 'DC',
    stateFips: '11',
    countyFips: '001',
    countyName: 'District of Columbia',
    num: 'H6',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '001',
    countyName: 'Alachua County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '003',
    countyName: 'Baker County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '005',
    countyName: 'Bay County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '007',
    countyName: 'Bradford County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '009',
    countyName: 'Brevard County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '011',
    countyName: 'Broward County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '013',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '015',
    countyName: 'Charlotte County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '017',
    countyName: 'Citrus County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '019',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '021',
    countyName: 'Collier County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '023',
    countyName: 'Columbia County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '027',
    countyName: 'DeSoto County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '029',
    countyName: 'Dixie County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '031',
    countyName: 'Duval County',
    num: 'H6',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '033',
    countyName: 'Escambia County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '035',
    countyName: 'Flagler County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '037',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '039',
    countyName: 'Gadsden County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '041',
    countyName: 'Gilchrist County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '043',
    countyName: 'Glades County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '045',
    countyName: 'Gulf County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '047',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '049',
    countyName: 'Hardee County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '051',
    countyName: 'Hendry County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '053',
    countyName: 'Hernando County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '055',
    countyName: 'Highlands County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '057',
    countyName: 'Hillsborough County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '059',
    countyName: 'Holmes County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '061',
    countyName: 'Indian River County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '063',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '065',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '067',
    countyName: 'Lafayette County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '069',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '071',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '073',
    countyName: 'Leon County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '075',
    countyName: 'Levy County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '077',
    countyName: 'Liberty County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '079',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '081',
    countyName: 'Manatee County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '083',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '085',
    countyName: 'Martin County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '086',
    countyName: 'Miami-Dade County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '087',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '089',
    countyName: 'Nassau County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '091',
    countyName: 'Okaloosa County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '093',
    countyName: 'Okeechobee County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '095',
    countyName: 'Orange County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '097',
    countyName: 'Osceola County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '099',
    countyName: 'Palm Beach County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '101',
    countyName: 'Pasco County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '103',
    countyName: 'Pinellas County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '105',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '107',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '109',
    countyName: 'St. Johns County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '111',
    countyName: 'St. Lucie County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '113',
    countyName: 'Santa Rosa County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '115',
    countyName: 'Sarasota County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '117',
    countyName: 'Seminole County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '119',
    countyName: 'Sumter County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '121',
    countyName: 'Suwannee County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '123',
    countyName: 'Taylor County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '125',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '127',
    countyName: 'Volusia County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '129',
    countyName: 'Wakulla County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '131',
    countyName: 'Walton County',
    num: 'H1',
  },
  {
    state: 'FL',
    stateFips: '12',
    countyFips: '133',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '001',
    countyName: 'Appling County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '003',
    countyName: 'Atkinson County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '005',
    countyName: 'Bacon County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '007',
    countyName: 'Baker County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '009',
    countyName: 'Baldwin County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '011',
    countyName: 'Banks County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '013',
    countyName: 'Barrow County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '015',
    countyName: 'Bartow County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '017',
    countyName: 'Ben Hill County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '019',
    countyName: 'Berrien County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '021',
    countyName: 'Bibb County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '023',
    countyName: 'Bleckley County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '025',
    countyName: 'Brantley County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '027',
    countyName: 'Brooks County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '029',
    countyName: 'Bryan County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '031',
    countyName: 'Bulloch County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '033',
    countyName: 'Burke County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '035',
    countyName: 'Butts County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '037',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '039',
    countyName: 'Camden County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '043',
    countyName: 'Candler County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '045',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '047',
    countyName: 'Catoosa County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '049',
    countyName: 'Charlton County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '051',
    countyName: 'Chatham County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '053',
    countyName: 'Chattahoochee County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '055',
    countyName: 'Chattooga County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '057',
    countyName: 'Cherokee County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '059',
    countyName: 'Clarke County',
    num: 'H6',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '061',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '063',
    countyName: 'Clayton County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '065',
    countyName: 'Clinch County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '067',
    countyName: 'Cobb County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '069',
    countyName: 'Coffee County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '071',
    countyName: 'Colquitt County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '073',
    countyName: 'Columbia County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '075',
    countyName: 'Cook County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '077',
    countyName: 'Coweta County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '079',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '081',
    countyName: 'Crisp County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '083',
    countyName: 'Dade County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '085',
    countyName: 'Dawson County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '087',
    countyName: 'Decatur County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '089',
    countyName: 'DeKalb County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '091',
    countyName: 'Dodge County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '093',
    countyName: 'Dooly County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '095',
    countyName: 'Dougherty County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '097',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '099',
    countyName: 'Early County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '101',
    countyName: 'Echols County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '103',
    countyName: 'Effingham County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '105',
    countyName: 'Elbert County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '107',
    countyName: 'Emanuel County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '109',
    countyName: 'Evans County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '111',
    countyName: 'Fannin County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '113',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '115',
    countyName: 'Floyd County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '117',
    countyName: 'Forsyth County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '119',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '121',
    countyName: 'Fulton County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '123',
    countyName: 'Gilmer County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '125',
    countyName: 'Glascock County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '127',
    countyName: 'Glynn County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '129',
    countyName: 'Gordon County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '131',
    countyName: 'Grady County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '133',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '135',
    countyName: 'Gwinnett County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '137',
    countyName: 'Habersham County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '139',
    countyName: 'Hall County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '141',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '143',
    countyName: 'Haralson County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '145',
    countyName: 'Harris County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '147',
    countyName: 'Hart County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '149',
    countyName: 'Heard County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '151',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '153',
    countyName: 'Houston County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '155',
    countyName: 'Irwin County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '157',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '159',
    countyName: 'Jasper County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '161',
    countyName: 'Jeff Davis County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '163',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '165',
    countyName: 'Jenkins County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '167',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '169',
    countyName: 'Jones County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '171',
    countyName: 'Lamar County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '173',
    countyName: 'Lanier County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '175',
    countyName: 'Laurens County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '177',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '179',
    countyName: 'Liberty County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '181',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '183',
    countyName: 'Long County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '185',
    countyName: 'Lowndes County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '187',
    countyName: 'Lumpkin County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '189',
    countyName: 'McDuffie County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '191',
    countyName: 'McIntosh County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '193',
    countyName: 'Macon County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '195',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '197',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '199',
    countyName: 'Meriwether County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '201',
    countyName: 'Miller County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '205',
    countyName: 'Mitchell County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '207',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '209',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '211',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '213',
    countyName: 'Murray County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '215',
    countyName: 'Muscogee County',
    num: 'H6',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '217',
    countyName: 'Newton County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '219',
    countyName: 'Oconee County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '221',
    countyName: 'Oglethorpe County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '223',
    countyName: 'Paulding County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '225',
    countyName: 'Peach County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '227',
    countyName: 'Pickens County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '229',
    countyName: 'Pierce County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '231',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '233',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '235',
    countyName: 'Pulaski County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '237',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '239',
    countyName: 'Quitman County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '241',
    countyName: 'Rabun County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '243',
    countyName: 'Randolph County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '245',
    countyName: 'Richmond County',
    num: 'H6',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '247',
    countyName: 'Rockdale County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '249',
    countyName: 'Schley County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '251',
    countyName: 'Screven County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '253',
    countyName: 'Seminole County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '255',
    countyName: 'Spalding County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '257',
    countyName: 'Stephens County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '259',
    countyName: 'Stewart County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '261',
    countyName: 'Sumter County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '263',
    countyName: 'Talbot County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '265',
    countyName: 'Taliaferro County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '267',
    countyName: 'Tattnall County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '269',
    countyName: 'Taylor County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '271',
    countyName: 'Telfair County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '273',
    countyName: 'Terrell County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '275',
    countyName: 'Thomas County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '277',
    countyName: 'Tift County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '279',
    countyName: 'Toombs County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '281',
    countyName: 'Towns County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '283',
    countyName: 'Treutlen County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '285',
    countyName: 'Troup County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '287',
    countyName: 'Turner County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '289',
    countyName: 'Twiggs County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '291',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '293',
    countyName: 'Upson County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '295',
    countyName: 'Walker County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '297',
    countyName: 'Walton County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '299',
    countyName: 'Ware County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '301',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '303',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '305',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '307',
    countyName: 'Webster County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '309',
    countyName: 'Wheeler County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '311',
    countyName: 'White County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '313',
    countyName: 'Whitfield County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '315',
    countyName: 'Wilcox County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '317',
    countyName: 'Wilkes County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '319',
    countyName: 'Wilkinson County',
    num: 'H1',
  },
  {
    state: 'GA',
    stateFips: '13',
    countyFips: '321',
    countyName: 'Worth County',
    num: 'H1',
  },
  {
    state: 'HI',
    stateFips: '15',
    countyFips: '001',
    countyName: 'Hawaii County',
    num: 'H1',
  },
  {
    state: 'HI',
    stateFips: '15',
    countyFips: '003',
    countyName: 'Honolulu County',
    num: 'H1',
  },
  {
    state: 'HI',
    stateFips: '15',
    countyFips: '005',
    countyName: 'Kalawao County',
    num: 'H4',
  },
  {
    state: 'HI',
    stateFips: '15',
    countyFips: '007',
    countyName: 'Kauai County',
    num: 'H1',
  },
  {
    state: 'HI',
    stateFips: '15',
    countyFips: '009',
    countyName: 'Maui County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '001',
    countyName: 'Ada County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '003',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '005',
    countyName: 'Bannock County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '007',
    countyName: 'Bear Lake County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '009',
    countyName: 'Benewah County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '011',
    countyName: 'Bingham County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '013',
    countyName: 'Blaine County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '015',
    countyName: 'Boise County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '017',
    countyName: 'Bonner County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '019',
    countyName: 'Bonneville County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '021',
    countyName: 'Boundary County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '023',
    countyName: 'Butte County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '025',
    countyName: 'Camas County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '027',
    countyName: 'Canyon County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '029',
    countyName: 'Caribou County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '031',
    countyName: 'Cassia County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '033',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '035',
    countyName: 'Clearwater County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '037',
    countyName: 'Custer County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '039',
    countyName: 'Elmore County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '041',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '043',
    countyName: 'Fremont County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '045',
    countyName: 'Gem County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '047',
    countyName: 'Gooding County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '049',
    countyName: 'Idaho County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '051',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '053',
    countyName: 'Jerome County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '055',
    countyName: 'Kootenai County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '057',
    countyName: 'Latah County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '059',
    countyName: 'Lemhi County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '061',
    countyName: 'Lewis County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '063',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '065',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '067',
    countyName: 'Minidoka County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '069',
    countyName: 'Nez Perce County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '071',
    countyName: 'Oneida County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '073',
    countyName: 'Owyhee County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '075',
    countyName: 'Payette County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '077',
    countyName: 'Power County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '079',
    countyName: 'Shoshone County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '081',
    countyName: 'Teton County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '083',
    countyName: 'Twin Falls County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '085',
    countyName: 'Valley County',
    num: 'H1',
  },
  {
    state: 'ID',
    stateFips: '16',
    countyFips: '087',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '003',
    countyName: 'Alexander County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '005',
    countyName: 'Bond County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '007',
    countyName: 'Boone County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '009',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '011',
    countyName: 'Bureau County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '013',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '015',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '017',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '019',
    countyName: 'Champaign County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '021',
    countyName: 'Christian County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '023',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '025',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '027',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '029',
    countyName: 'Coles County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '031',
    countyName: 'Cook County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '033',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '035',
    countyName: 'Cumberland County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '037',
    countyName: 'DeKalb County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '039',
    countyName: 'De Witt County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '041',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '043',
    countyName: 'DuPage County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '045',
    countyName: 'Edgar County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '047',
    countyName: 'Edwards County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '049',
    countyName: 'Effingham County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '051',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '053',
    countyName: 'Ford County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '055',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '057',
    countyName: 'Fulton County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '059',
    countyName: 'Gallatin County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '061',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '063',
    countyName: 'Grundy County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '065',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '067',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '069',
    countyName: 'Hardin County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '071',
    countyName: 'Henderson County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '073',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '075',
    countyName: 'Iroquois County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '077',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '079',
    countyName: 'Jasper County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '081',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '083',
    countyName: 'Jersey County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '085',
    countyName: 'Jo Daviess County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '087',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '089',
    countyName: 'Kane County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '091',
    countyName: 'Kankakee County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '093',
    countyName: 'Kendall County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '095',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '097',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '099',
    countyName: 'LaSalle County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '101',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '103',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '105',
    countyName: 'Livingston County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '107',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '109',
    countyName: 'McDonough County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '111',
    countyName: 'McHenry County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '113',
    countyName: 'McLean County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '115',
    countyName: 'Macon County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '117',
    countyName: 'Macoupin County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '119',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '121',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '123',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '125',
    countyName: 'Mason County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '127',
    countyName: 'Massac County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '129',
    countyName: 'Menard County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '131',
    countyName: 'Mercer County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '133',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '135',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '137',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '139',
    countyName: 'Moultrie County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '141',
    countyName: 'Ogle County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '143',
    countyName: 'Peoria County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '145',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '147',
    countyName: 'Piatt County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '149',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '151',
    countyName: 'Pope County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '153',
    countyName: 'Pulaski County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '155',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '157',
    countyName: 'Randolph County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '159',
    countyName: 'Richland County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '161',
    countyName: 'Rock Island County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '163',
    countyName: 'St. Clair County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '165',
    countyName: 'Saline County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '167',
    countyName: 'Sangamon County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '169',
    countyName: 'Schuyler County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '171',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '173',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '175',
    countyName: 'Stark County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '177',
    countyName: 'Stephenson County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '179',
    countyName: 'Tazewell County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '181',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '183',
    countyName: 'Vermilion County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '185',
    countyName: 'Wabash County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '187',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '189',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '191',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '193',
    countyName: 'White County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '195',
    countyName: 'Whiteside County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '197',
    countyName: 'Will County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '199',
    countyName: 'Williamson County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '201',
    countyName: 'Winnebago County',
    num: 'H1',
  },
  {
    state: 'IL',
    stateFips: '17',
    countyFips: '203',
    countyName: 'Woodford County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '003',
    countyName: 'Allen County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '005',
    countyName: 'Bartholomew County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '007',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '009',
    countyName: 'Blackford County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '011',
    countyName: 'Boone County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '013',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '015',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '017',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '019',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '021',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '023',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '025',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '027',
    countyName: 'Daviess County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '029',
    countyName: 'Dearborn County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '031',
    countyName: 'Decatur County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '033',
    countyName: 'DeKalb County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '035',
    countyName: 'Delaware County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '037',
    countyName: 'Dubois County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '039',
    countyName: 'Elkhart County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '041',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '043',
    countyName: 'Floyd County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '045',
    countyName: 'Fountain County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '047',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '049',
    countyName: 'Fulton County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '051',
    countyName: 'Gibson County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '053',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '055',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '057',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '059',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '061',
    countyName: 'Harrison County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '063',
    countyName: 'Hendricks County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '065',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '067',
    countyName: 'Howard County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '069',
    countyName: 'Huntington County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '071',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '073',
    countyName: 'Jasper County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '075',
    countyName: 'Jay County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '077',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '079',
    countyName: 'Jennings County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '081',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '083',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '085',
    countyName: 'Kosciusko County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '087',
    countyName: 'LaGrange County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '089',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '091',
    countyName: 'LaPorte County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '093',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '095',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '097',
    countyName: 'Marion County',
    num: 'H6',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '099',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '101',
    countyName: 'Martin County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '103',
    countyName: 'Miami County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '105',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '107',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '109',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '111',
    countyName: 'Newton County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '113',
    countyName: 'Noble County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '115',
    countyName: 'Ohio County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '117',
    countyName: 'Orange County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '119',
    countyName: 'Owen County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '121',
    countyName: 'Parke County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '123',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '125',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '127',
    countyName: 'Porter County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '129',
    countyName: 'Posey County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '131',
    countyName: 'Pulaski County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '133',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '135',
    countyName: 'Randolph County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '137',
    countyName: 'Ripley County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '139',
    countyName: 'Rush County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '141',
    countyName: 'St. Joseph County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '143',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '145',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '147',
    countyName: 'Spencer County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '149',
    countyName: 'Starke County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '151',
    countyName: 'Steuben County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '153',
    countyName: 'Sullivan County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '155',
    countyName: 'Switzerland County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '157',
    countyName: 'Tippecanoe County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '159',
    countyName: 'Tipton County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '161',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '163',
    countyName: 'Vanderburgh County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '165',
    countyName: 'Vermillion County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '167',
    countyName: 'Vigo County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '169',
    countyName: 'Wabash County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '171',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '173',
    countyName: 'Warrick County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '175',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '177',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '179',
    countyName: 'Wells County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '181',
    countyName: 'White County',
    num: 'H1',
  },
  {
    state: 'IN',
    stateFips: '18',
    countyFips: '183',
    countyName: 'Whitley County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '001',
    countyName: 'Adair County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '003',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '005',
    countyName: 'Allamakee County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '007',
    countyName: 'Appanoose County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '009',
    countyName: 'Audubon County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '011',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '013',
    countyName: 'Black Hawk County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '015',
    countyName: 'Boone County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '017',
    countyName: 'Bremer County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '019',
    countyName: 'Buchanan County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '021',
    countyName: 'Buena Vista County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '023',
    countyName: 'Butler County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '025',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '027',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '029',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '031',
    countyName: 'Cedar County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '033',
    countyName: 'Cerro Gordo County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '035',
    countyName: 'Cherokee County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '037',
    countyName: 'Chickasaw County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '039',
    countyName: 'Clarke County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '041',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '043',
    countyName: 'Clayton County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '045',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '047',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '049',
    countyName: 'Dallas County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '051',
    countyName: 'Davis County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '053',
    countyName: 'Decatur County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '055',
    countyName: 'Delaware County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '057',
    countyName: 'Des Moines County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '059',
    countyName: 'Dickinson County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '061',
    countyName: 'Dubuque County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '063',
    countyName: 'Emmet County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '065',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '067',
    countyName: 'Floyd County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '069',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '071',
    countyName: 'Fremont County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '073',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '075',
    countyName: 'Grundy County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '077',
    countyName: 'Guthrie County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '079',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '081',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '083',
    countyName: 'Hardin County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '085',
    countyName: 'Harrison County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '087',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '089',
    countyName: 'Howard County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '091',
    countyName: 'Humboldt County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '093',
    countyName: 'Ida County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '095',
    countyName: 'Iowa County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '097',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '099',
    countyName: 'Jasper County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '101',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '103',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '105',
    countyName: 'Jones County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '107',
    countyName: 'Keokuk County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '109',
    countyName: 'Kossuth County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '111',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '113',
    countyName: 'Linn County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '115',
    countyName: 'Louisa County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '117',
    countyName: 'Lucas County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '119',
    countyName: 'Lyon County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '121',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '123',
    countyName: 'Mahaska County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '125',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '127',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '129',
    countyName: 'Mills County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '131',
    countyName: 'Mitchell County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '133',
    countyName: 'Monona County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '135',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '137',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '139',
    countyName: 'Muscatine County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '141',
    countyName: "O'Brien County",
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '143',
    countyName: 'Osceola County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '145',
    countyName: 'Page County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '147',
    countyName: 'Palo Alto County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '149',
    countyName: 'Plymouth County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '151',
    countyName: 'Pocahontas County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '153',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '155',
    countyName: 'Pottawattamie County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '157',
    countyName: 'Poweshiek County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '159',
    countyName: 'Ringgold County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '161',
    countyName: 'Sac County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '163',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '165',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '167',
    countyName: 'Sioux County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '169',
    countyName: 'Story County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '171',
    countyName: 'Tama County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '173',
    countyName: 'Taylor County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '175',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '177',
    countyName: 'Van Buren County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '179',
    countyName: 'Wapello County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '181',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '183',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '185',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '187',
    countyName: 'Webster County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '189',
    countyName: 'Winnebago County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '191',
    countyName: 'Winneshiek County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '193',
    countyName: 'Woodbury County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '195',
    countyName: 'Worth County',
    num: 'H1',
  },
  {
    state: 'IA',
    stateFips: '19',
    countyFips: '197',
    countyName: 'Wright County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '001',
    countyName: 'Allen County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '003',
    countyName: 'Anderson County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '005',
    countyName: 'Atchison County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '007',
    countyName: 'Barber County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '009',
    countyName: 'Barton County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '011',
    countyName: 'Bourbon County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '013',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '015',
    countyName: 'Butler County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '017',
    countyName: 'Chase County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '019',
    countyName: 'Chautauqua County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '021',
    countyName: 'Cherokee County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '023',
    countyName: 'Cheyenne County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '025',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '027',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '029',
    countyName: 'Cloud County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '031',
    countyName: 'Coffey County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '033',
    countyName: 'Comanche County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '035',
    countyName: 'Cowley County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '037',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '039',
    countyName: 'Decatur County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '041',
    countyName: 'Dickinson County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '043',
    countyName: 'Doniphan County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '045',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '047',
    countyName: 'Edwards County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '049',
    countyName: 'Elk County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '051',
    countyName: 'Ellis County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '053',
    countyName: 'Ellsworth County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '055',
    countyName: 'Finney County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '057',
    countyName: 'Ford County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '059',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '061',
    countyName: 'Geary County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '063',
    countyName: 'Gove County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '065',
    countyName: 'Graham County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '067',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '069',
    countyName: 'Gray County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '071',
    countyName: 'Greeley County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '073',
    countyName: 'Greenwood County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '075',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '077',
    countyName: 'Harper County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '079',
    countyName: 'Harvey County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '081',
    countyName: 'Haskell County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '083',
    countyName: 'Hodgeman County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '085',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '087',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '089',
    countyName: 'Jewell County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '091',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '093',
    countyName: 'Kearny County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '095',
    countyName: 'Kingman County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '097',
    countyName: 'Kiowa County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '099',
    countyName: 'Labette County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '101',
    countyName: 'Lane County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '103',
    countyName: 'Leavenworth County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '105',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '107',
    countyName: 'Linn County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '109',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '111',
    countyName: 'Lyon County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '113',
    countyName: 'McPherson County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '115',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '117',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '119',
    countyName: 'Meade County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '121',
    countyName: 'Miami County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '123',
    countyName: 'Mitchell County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '125',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '127',
    countyName: 'Morris County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '129',
    countyName: 'Morton County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '131',
    countyName: 'Nemaha County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '133',
    countyName: 'Neosho County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '135',
    countyName: 'Ness County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '137',
    countyName: 'Norton County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '139',
    countyName: 'Osage County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '141',
    countyName: 'Osborne County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '143',
    countyName: 'Ottawa County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '145',
    countyName: 'Pawnee County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '147',
    countyName: 'Phillips County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '149',
    countyName: 'Pottawatomie County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '151',
    countyName: 'Pratt County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '153',
    countyName: 'Rawlins County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '155',
    countyName: 'Reno County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '157',
    countyName: 'Republic County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '159',
    countyName: 'Rice County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '161',
    countyName: 'Riley County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '163',
    countyName: 'Rooks County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '165',
    countyName: 'Rush County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '167',
    countyName: 'Russell County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '169',
    countyName: 'Saline County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '171',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '173',
    countyName: 'Sedgwick County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '175',
    countyName: 'Seward County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '177',
    countyName: 'Shawnee County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '179',
    countyName: 'Sheridan County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '181',
    countyName: 'Sherman County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '183',
    countyName: 'Smith County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '185',
    countyName: 'Stafford County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '187',
    countyName: 'Stanton County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '189',
    countyName: 'Stevens County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '191',
    countyName: 'Sumner County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '193',
    countyName: 'Thomas County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '195',
    countyName: 'Trego County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '197',
    countyName: 'Wabaunsee County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '199',
    countyName: 'Wallace County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '201',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '203',
    countyName: 'Wichita County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '205',
    countyName: 'Wilson County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '207',
    countyName: 'Woodson County',
    num: 'H1',
  },
  {
    state: 'KS',
    stateFips: '20',
    countyFips: '209',
    countyName: 'Wyandotte County',
    num: 'H6',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '001',
    countyName: 'Adair County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '003',
    countyName: 'Allen County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '005',
    countyName: 'Anderson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '007',
    countyName: 'Ballard County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '009',
    countyName: 'Barren County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '011',
    countyName: 'Bath County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '013',
    countyName: 'Bell County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '015',
    countyName: 'Boone County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '017',
    countyName: 'Bourbon County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '019',
    countyName: 'Boyd County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '021',
    countyName: 'Boyle County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '023',
    countyName: 'Bracken County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '025',
    countyName: 'Breathitt County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '027',
    countyName: 'Breckinridge County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '029',
    countyName: 'Bullitt County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '031',
    countyName: 'Butler County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '033',
    countyName: 'Caldwell County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '035',
    countyName: 'Calloway County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '037',
    countyName: 'Campbell County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '039',
    countyName: 'Carlisle County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '041',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '043',
    countyName: 'Carter County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '045',
    countyName: 'Casey County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '047',
    countyName: 'Christian County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '049',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '051',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '053',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '055',
    countyName: 'Crittenden County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '057',
    countyName: 'Cumberland County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '059',
    countyName: 'Daviess County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '061',
    countyName: 'Edmonson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '063',
    countyName: 'Elliott County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '065',
    countyName: 'Estill County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '067',
    countyName: 'Fayette County',
    num: 'H6',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '069',
    countyName: 'Fleming County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '071',
    countyName: 'Floyd County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '073',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '075',
    countyName: 'Fulton County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '077',
    countyName: 'Gallatin County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '079',
    countyName: 'Garrard County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '081',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '083',
    countyName: 'Graves County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '085',
    countyName: 'Grayson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '087',
    countyName: 'Green County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '089',
    countyName: 'Greenup County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '091',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '093',
    countyName: 'Hardin County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '095',
    countyName: 'Harlan County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '097',
    countyName: 'Harrison County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '099',
    countyName: 'Hart County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '101',
    countyName: 'Henderson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '103',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '105',
    countyName: 'Hickman County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '107',
    countyName: 'Hopkins County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '109',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '111',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '113',
    countyName: 'Jessamine County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '115',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '117',
    countyName: 'Kenton County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '119',
    countyName: 'Knott County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '121',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '123',
    countyName: 'Larue County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '125',
    countyName: 'Laurel County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '127',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '129',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '131',
    countyName: 'Leslie County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '133',
    countyName: 'Letcher County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '135',
    countyName: 'Lewis County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '137',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '139',
    countyName: 'Livingston County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '141',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '143',
    countyName: 'Lyon County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '145',
    countyName: 'McCracken County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '147',
    countyName: 'McCreary County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '149',
    countyName: 'McLean County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '151',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '153',
    countyName: 'Magoffin County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '155',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '157',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '159',
    countyName: 'Martin County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '161',
    countyName: 'Mason County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '163',
    countyName: 'Meade County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '165',
    countyName: 'Menifee County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '167',
    countyName: 'Mercer County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '169',
    countyName: 'Metcalfe County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '171',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '173',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '175',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '177',
    countyName: 'Muhlenberg County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '179',
    countyName: 'Nelson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '181',
    countyName: 'Nicholas County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '183',
    countyName: 'Ohio County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '185',
    countyName: 'Oldham County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '187',
    countyName: 'Owen County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '189',
    countyName: 'Owsley County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '191',
    countyName: 'Pendleton County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '193',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '195',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '197',
    countyName: 'Powell County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '199',
    countyName: 'Pulaski County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '201',
    countyName: 'Robertson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '203',
    countyName: 'Rockcastle County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '205',
    countyName: 'Rowan County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '207',
    countyName: 'Russell County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '209',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '211',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '213',
    countyName: 'Simpson County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '215',
    countyName: 'Spencer County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '217',
    countyName: 'Taylor County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '219',
    countyName: 'Todd County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '221',
    countyName: 'Trigg County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '223',
    countyName: 'Trimble County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '225',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '227',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '229',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '231',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '233',
    countyName: 'Webster County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '235',
    countyName: 'Whitley County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '237',
    countyName: 'Wolfe County',
    num: 'H1',
  },
  {
    state: 'KY',
    stateFips: '21',
    countyFips: '239',
    countyName: 'Woodford County',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '001',
    countyName: 'Acadia Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '003',
    countyName: 'Allen Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '005',
    countyName: 'Ascension Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '007',
    countyName: 'Assumption Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '009',
    countyName: 'Avoyelles Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '011',
    countyName: 'Beauregard Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '013',
    countyName: 'Bienville Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '015',
    countyName: 'Bossier Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '017',
    countyName: 'Caddo Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '019',
    countyName: 'Calcasieu Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '021',
    countyName: 'Caldwell Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '023',
    countyName: 'Cameron Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '025',
    countyName: 'Catahoula Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '027',
    countyName: 'Claiborne Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '029',
    countyName: 'Concordia Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '031',
    countyName: 'De Soto Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '033',
    countyName: 'East Baton Rouge Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '035',
    countyName: 'East Carroll Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '037',
    countyName: 'East Feliciana Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '039',
    countyName: 'Evangeline Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '041',
    countyName: 'Franklin Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '043',
    countyName: 'Grant Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '045',
    countyName: 'Iberia Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '047',
    countyName: 'Iberville Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '049',
    countyName: 'Jackson Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '051',
    countyName: 'Jefferson Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '053',
    countyName: 'Jefferson Davis Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '055',
    countyName: 'Lafayette Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '057',
    countyName: 'Lafourche Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '059',
    countyName: 'La Salle Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '061',
    countyName: 'Lincoln Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '063',
    countyName: 'Livingston Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '065',
    countyName: 'Madison Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '067',
    countyName: 'Morehouse Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '069',
    countyName: 'Natchitoches Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '071',
    countyName: 'Orleans Parish',
    num: 'H6',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '073',
    countyName: 'Ouachita Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '075',
    countyName: 'Plaquemines Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '077',
    countyName: 'Pointe Coupee Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '079',
    countyName: 'Rapides Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '081',
    countyName: 'Red River Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '083',
    countyName: 'Richland Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '085',
    countyName: 'Sabine Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '087',
    countyName: 'St. Bernard Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '089',
    countyName: 'St. Charles Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '091',
    countyName: 'St. Helena Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '093',
    countyName: 'St. James Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '095',
    countyName: 'St. John the Baptist Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '097',
    countyName: 'St. Landry Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '099',
    countyName: 'St. Martin Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '101',
    countyName: 'St. Mary Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '103',
    countyName: 'St. Tammany Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '105',
    countyName: 'Tangipahoa Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '107',
    countyName: 'Tensas Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '109',
    countyName: 'Terrebonne Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '111',
    countyName: 'Union Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '113',
    countyName: 'Vermilion Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '115',
    countyName: 'Vernon Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '117',
    countyName: 'Washington Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '119',
    countyName: 'Webster Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '121',
    countyName: 'West Baton Rouge Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '123',
    countyName: 'West Carroll Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '125',
    countyName: 'West Feliciana Parish',
    num: 'H1',
  },
  {
    state: 'LA',
    stateFips: '22',
    countyFips: '127',
    countyName: 'Winn Parish',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '001',
    countyName: 'Androscoggin County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '003',
    countyName: 'Aroostook County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '005',
    countyName: 'Cumberland County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '007',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '009',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '011',
    countyName: 'Kennebec County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '013',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '015',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '017',
    countyName: 'Oxford County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '019',
    countyName: 'Penobscot County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '021',
    countyName: 'Piscataquis County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '023',
    countyName: 'Sagadahoc County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '025',
    countyName: 'Somerset County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '027',
    countyName: 'Waldo County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '029',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'ME',
    stateFips: '23',
    countyFips: '031',
    countyName: 'York County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '001',
    countyName: 'Allegany County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '003',
    countyName: 'Anne Arundel County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '005',
    countyName: 'Baltimore County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '009',
    countyName: 'Calvert County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '011',
    countyName: 'Caroline County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '013',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '015',
    countyName: 'Cecil County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '017',
    countyName: 'Charles County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '019',
    countyName: 'Dorchester County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '021',
    countyName: 'Frederick County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '023',
    countyName: 'Garrett County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '025',
    countyName: 'Harford County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '027',
    countyName: 'Howard County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '029',
    countyName: 'Kent County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '031',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '033',
    countyName: "Prince George's County",
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '035',
    countyName: "Queen Anne's County",
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '037',
    countyName: "St. Mary's County",
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '039',
    countyName: 'Somerset County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '041',
    countyName: 'Talbot County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '043',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '045',
    countyName: 'Wicomico County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '047',
    countyName: 'Worcester County',
    num: 'H1',
  },
  {
    state: 'MD',
    stateFips: '24',
    countyFips: '510',
    countyName: 'Baltimore city',
    num: 'C7',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '001',
    countyName: 'Barnstable County',
    num: 'H1',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '003',
    countyName: 'Berkshire County',
    num: 'H4',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '005',
    countyName: 'Bristol County',
    num: 'H1',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '007',
    countyName: 'Dukes County',
    num: 'H1',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '009',
    countyName: 'Essex County',
    num: 'H4',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '011',
    countyName: 'Franklin County',
    num: 'H4',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '013',
    countyName: 'Hampden County',
    num: 'H4',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '015',
    countyName: 'Hampshire County',
    num: 'H4',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '017',
    countyName: 'Middlesex County',
    num: 'H4',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '019',
    countyName: 'Nantucket County',
    num: 'H4',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '021',
    countyName: 'Norfolk County',
    num: 'H1',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '023',
    countyName: 'Plymouth County',
    num: 'H1',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '025',
    countyName: 'Suffolk County',
    num: 'H4',
  },
  {
    state: 'MA',
    stateFips: '25',
    countyFips: '027',
    countyName: 'Worcester County',
    num: 'H4',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '001',
    countyName: 'Alcona County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '003',
    countyName: 'Alger County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '005',
    countyName: 'Allegan County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '007',
    countyName: 'Alpena County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '009',
    countyName: 'Antrim County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '011',
    countyName: 'Arenac County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '013',
    countyName: 'Baraga County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '015',
    countyName: 'Barry County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '017',
    countyName: 'Bay County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '019',
    countyName: 'Benzie County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '021',
    countyName: 'Berrien County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '023',
    countyName: 'Branch County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '025',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '027',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '029',
    countyName: 'Charlevoix County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '031',
    countyName: 'Cheboygan County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '033',
    countyName: 'Chippewa County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '035',
    countyName: 'Clare County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '037',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '039',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '041',
    countyName: 'Delta County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '043',
    countyName: 'Dickinson County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '045',
    countyName: 'Eaton County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '047',
    countyName: 'Emmet County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '049',
    countyName: 'Genesee County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '051',
    countyName: 'Gladwin County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '053',
    countyName: 'Gogebic County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '055',
    countyName: 'Grand Traverse County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '057',
    countyName: 'Gratiot County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '059',
    countyName: 'Hillsdale County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '061',
    countyName: 'Houghton County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '063',
    countyName: 'Huron County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '065',
    countyName: 'Ingham County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '067',
    countyName: 'Ionia County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '069',
    countyName: 'Iosco County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '071',
    countyName: 'Iron County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '073',
    countyName: 'Isabella County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '075',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '077',
    countyName: 'Kalamazoo County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '079',
    countyName: 'Kalkaska County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '081',
    countyName: 'Kent County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '083',
    countyName: 'Keweenaw County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '085',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '087',
    countyName: 'Lapeer County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '089',
    countyName: 'Leelanau County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '091',
    countyName: 'Lenawee County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '093',
    countyName: 'Livingston County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '095',
    countyName: 'Luce County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '097',
    countyName: 'Mackinac County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '099',
    countyName: 'Macomb County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '101',
    countyName: 'Manistee County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '103',
    countyName: 'Marquette County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '105',
    countyName: 'Mason County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '107',
    countyName: 'Mecosta County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '109',
    countyName: 'Menominee County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '111',
    countyName: 'Midland County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '113',
    countyName: 'Missaukee County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '115',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '117',
    countyName: 'Montcalm County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '119',
    countyName: 'Montmorency County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '121',
    countyName: 'Muskegon County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '123',
    countyName: 'Newaygo County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '125',
    countyName: 'Oakland County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '127',
    countyName: 'Oceana County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '129',
    countyName: 'Ogemaw County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '131',
    countyName: 'Ontonagon County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '133',
    countyName: 'Osceola County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '135',
    countyName: 'Oscoda County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '137',
    countyName: 'Otsego County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '139',
    countyName: 'Ottawa County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '141',
    countyName: 'Presque Isle County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '143',
    countyName: 'Roscommon County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '145',
    countyName: 'Saginaw County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '147',
    countyName: 'St. Clair County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '149',
    countyName: 'St. Joseph County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '151',
    countyName: 'Sanilac County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '153',
    countyName: 'Schoolcraft County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '155',
    countyName: 'Shiawassee County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '157',
    countyName: 'Tuscola County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '159',
    countyName: 'Van Buren County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '161',
    countyName: 'Washtenaw County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '163',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'MI',
    stateFips: '26',
    countyFips: '165',
    countyName: 'Wexford County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '001',
    countyName: 'Aitkin County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '003',
    countyName: 'Anoka County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '005',
    countyName: 'Becker County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '007',
    countyName: 'Beltrami County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '009',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '011',
    countyName: 'Big Stone County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '013',
    countyName: 'Blue Earth County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '015',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '017',
    countyName: 'Carlton County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '019',
    countyName: 'Carver County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '021',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '023',
    countyName: 'Chippewa County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '025',
    countyName: 'Chisago County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '027',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '029',
    countyName: 'Clearwater County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '031',
    countyName: 'Cook County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '033',
    countyName: 'Cottonwood County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '035',
    countyName: 'Crow Wing County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '037',
    countyName: 'Dakota County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '039',
    countyName: 'Dodge County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '041',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '043',
    countyName: 'Faribault County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '045',
    countyName: 'Fillmore County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '047',
    countyName: 'Freeborn County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '049',
    countyName: 'Goodhue County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '051',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '053',
    countyName: 'Hennepin County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '055',
    countyName: 'Houston County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '057',
    countyName: 'Hubbard County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '059',
    countyName: 'Isanti County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '061',
    countyName: 'Itasca County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '063',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '065',
    countyName: 'Kanabec County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '067',
    countyName: 'Kandiyohi County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '069',
    countyName: 'Kittson County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '071',
    countyName: 'Koochiching County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '073',
    countyName: 'Lac qui Parle County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '075',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '077',
    countyName: 'Lake of the Woods County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '079',
    countyName: 'Le Sueur County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '081',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '083',
    countyName: 'Lyon County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '085',
    countyName: 'McLeod County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '087',
    countyName: 'Mahnomen County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '089',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '091',
    countyName: 'Martin County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '093',
    countyName: 'Meeker County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '095',
    countyName: 'Mille Lacs County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '097',
    countyName: 'Morrison County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '099',
    countyName: 'Mower County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '101',
    countyName: 'Murray County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '103',
    countyName: 'Nicollet County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '105',
    countyName: 'Nobles County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '107',
    countyName: 'Norman County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '109',
    countyName: 'Olmsted County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '111',
    countyName: 'Otter Tail County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '113',
    countyName: 'Pennington County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '115',
    countyName: 'Pine County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '117',
    countyName: 'Pipestone County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '119',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '121',
    countyName: 'Pope County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '123',
    countyName: 'Ramsey County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '125',
    countyName: 'Red Lake County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '127',
    countyName: 'Redwood County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '129',
    countyName: 'Renville County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '131',
    countyName: 'Rice County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '133',
    countyName: 'Rock County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '135',
    countyName: 'Roseau County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '137',
    countyName: 'St. Louis County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '139',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '141',
    countyName: 'Sherburne County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '143',
    countyName: 'Sibley County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '145',
    countyName: 'Stearns County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '147',
    countyName: 'Steele County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '149',
    countyName: 'Stevens County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '151',
    countyName: 'Swift County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '153',
    countyName: 'Todd County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '155',
    countyName: 'Traverse County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '157',
    countyName: 'Wabasha County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '159',
    countyName: 'Wadena County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '161',
    countyName: 'Waseca County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '163',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '165',
    countyName: 'Watonwan County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '167',
    countyName: 'Wilkin County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '169',
    countyName: 'Winona County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '171',
    countyName: 'Wright County',
    num: 'H1',
  },
  {
    state: 'MN',
    stateFips: '27',
    countyFips: '173',
    countyName: 'Yellow Medicine County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '003',
    countyName: 'Alcorn County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '005',
    countyName: 'Amite County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '007',
    countyName: 'Attala County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '009',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '011',
    countyName: 'Bolivar County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '013',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '015',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '017',
    countyName: 'Chickasaw County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '019',
    countyName: 'Choctaw County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '021',
    countyName: 'Claiborne County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '023',
    countyName: 'Clarke County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '025',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '027',
    countyName: 'Coahoma County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '029',
    countyName: 'Copiah County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '031',
    countyName: 'Covington County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '033',
    countyName: 'DeSoto County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '035',
    countyName: 'Forrest County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '037',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '039',
    countyName: 'George County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '041',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '043',
    countyName: 'Grenada County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '045',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '047',
    countyName: 'Harrison County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '049',
    countyName: 'Hinds County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '051',
    countyName: 'Holmes County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '053',
    countyName: 'Humphreys County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '055',
    countyName: 'Issaquena County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '057',
    countyName: 'Itawamba County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '059',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '061',
    countyName: 'Jasper County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '063',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '065',
    countyName: 'Jefferson Davis County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '067',
    countyName: 'Jones County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '069',
    countyName: 'Kemper County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '071',
    countyName: 'Lafayette County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '073',
    countyName: 'Lamar County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '075',
    countyName: 'Lauderdale County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '077',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '079',
    countyName: 'Leake County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '081',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '083',
    countyName: 'Leflore County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '085',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '087',
    countyName: 'Lowndes County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '089',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '091',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '093',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '095',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '097',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '099',
    countyName: 'Neshoba County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '101',
    countyName: 'Newton County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '103',
    countyName: 'Noxubee County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '105',
    countyName: 'Oktibbeha County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '107',
    countyName: 'Panola County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '109',
    countyName: 'Pearl River County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '111',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '113',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '115',
    countyName: 'Pontotoc County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '117',
    countyName: 'Prentiss County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '119',
    countyName: 'Quitman County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '121',
    countyName: 'Rankin County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '123',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '125',
    countyName: 'Sharkey County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '127',
    countyName: 'Simpson County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '129',
    countyName: 'Smith County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '131',
    countyName: 'Stone County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '133',
    countyName: 'Sunflower County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '135',
    countyName: 'Tallahatchie County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '137',
    countyName: 'Tate County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '139',
    countyName: 'Tippah County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '141',
    countyName: 'Tishomingo County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '143',
    countyName: 'Tunica County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '145',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '147',
    countyName: 'Walthall County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '149',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '151',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '153',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '155',
    countyName: 'Webster County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '157',
    countyName: 'Wilkinson County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '159',
    countyName: 'Winston County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '161',
    countyName: 'Yalobusha County',
    num: 'H1',
  },
  {
    state: 'MS',
    stateFips: '28',
    countyFips: '163',
    countyName: 'Yazoo County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '001',
    countyName: 'Adair County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '003',
    countyName: 'Andrew County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '005',
    countyName: 'Atchison County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '007',
    countyName: 'Audrain County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '009',
    countyName: 'Barry County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '011',
    countyName: 'Barton County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '013',
    countyName: 'Bates County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '015',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '017',
    countyName: 'Bollinger County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '019',
    countyName: 'Boone County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '021',
    countyName: 'Buchanan County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '023',
    countyName: 'Butler County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '025',
    countyName: 'Caldwell County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '027',
    countyName: 'Callaway County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '029',
    countyName: 'Camden County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '031',
    countyName: 'Cape Girardeau County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '033',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '035',
    countyName: 'Carter County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '037',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '039',
    countyName: 'Cedar County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '041',
    countyName: 'Chariton County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '043',
    countyName: 'Christian County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '045',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '047',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '049',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '051',
    countyName: 'Cole County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '053',
    countyName: 'Cooper County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '055',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '057',
    countyName: 'Dade County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '059',
    countyName: 'Dallas County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '061',
    countyName: 'Daviess County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '063',
    countyName: 'DeKalb County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '065',
    countyName: 'Dent County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '067',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '069',
    countyName: 'Dunklin County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '071',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '073',
    countyName: 'Gasconade County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '075',
    countyName: 'Gentry County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '077',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '079',
    countyName: 'Grundy County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '081',
    countyName: 'Harrison County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '083',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '085',
    countyName: 'Hickory County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '087',
    countyName: 'Holt County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '089',
    countyName: 'Howard County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '091',
    countyName: 'Howell County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '093',
    countyName: 'Iron County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '095',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '097',
    countyName: 'Jasper County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '099',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '101',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '103',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '105',
    countyName: 'Laclede County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '107',
    countyName: 'Lafayette County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '109',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '111',
    countyName: 'Lewis County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '113',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '115',
    countyName: 'Linn County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '117',
    countyName: 'Livingston County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '119',
    countyName: 'McDonald County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '121',
    countyName: 'Macon County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '123',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '125',
    countyName: 'Maries County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '127',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '129',
    countyName: 'Mercer County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '131',
    countyName: 'Miller County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '133',
    countyName: 'Mississippi County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '135',
    countyName: 'Moniteau County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '137',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '139',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '141',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '143',
    countyName: 'New Madrid County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '145',
    countyName: 'Newton County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '147',
    countyName: 'Nodaway County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '149',
    countyName: 'Oregon County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '151',
    countyName: 'Osage County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '153',
    countyName: 'Ozark County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '155',
    countyName: 'Pemiscot County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '157',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '159',
    countyName: 'Pettis County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '161',
    countyName: 'Phelps County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '163',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '165',
    countyName: 'Platte County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '167',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '169',
    countyName: 'Pulaski County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '171',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '173',
    countyName: 'Ralls County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '175',
    countyName: 'Randolph County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '177',
    countyName: 'Ray County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '179',
    countyName: 'Reynolds County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '181',
    countyName: 'Ripley County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '183',
    countyName: 'St. Charles County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '185',
    countyName: 'St. Clair County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '186',
    countyName: 'Ste. Genevieve County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '187',
    countyName: 'St. Francois County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '189',
    countyName: 'St. Louis County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '195',
    countyName: 'Saline County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '197',
    countyName: 'Schuyler County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '199',
    countyName: 'Scotland County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '201',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '203',
    countyName: 'Shannon County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '205',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '207',
    countyName: 'Stoddard County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '209',
    countyName: 'Stone County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '211',
    countyName: 'Sullivan County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '213',
    countyName: 'Taney County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '215',
    countyName: 'Texas County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '217',
    countyName: 'Vernon County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '219',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '221',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '223',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '225',
    countyName: 'Webster County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '227',
    countyName: 'Worth County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '229',
    countyName: 'Wright County',
    num: 'H1',
  },
  {
    state: 'MO',
    stateFips: '29',
    countyFips: '510',
    countyName: 'St. Louis city',
    num: 'C7',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '001',
    countyName: 'Beaverhead County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '003',
    countyName: 'Big Horn County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '005',
    countyName: 'Blaine County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '007',
    countyName: 'Broadwater County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '009',
    countyName: 'Carbon County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '011',
    countyName: 'Carter County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '013',
    countyName: 'Cascade County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '015',
    countyName: 'Chouteau County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '017',
    countyName: 'Custer County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '019',
    countyName: 'Daniels County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '021',
    countyName: 'Dawson County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '023',
    countyName: 'Deer Lodge County',
    num: 'H6',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '025',
    countyName: 'Fallon County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '027',
    countyName: 'Fergus County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '029',
    countyName: 'Flathead County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '031',
    countyName: 'Gallatin County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '033',
    countyName: 'Garfield County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '035',
    countyName: 'Glacier County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '037',
    countyName: 'Golden Valley County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '039',
    countyName: 'Granite County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '041',
    countyName: 'Hill County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '043',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '045',
    countyName: 'Judith Basin County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '047',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '049',
    countyName: 'Lewis and Clark County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '051',
    countyName: 'Liberty County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '053',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '055',
    countyName: 'McCone County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '057',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '059',
    countyName: 'Meagher County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '061',
    countyName: 'Mineral County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '063',
    countyName: 'Missoula County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '065',
    countyName: 'Musselshell County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '067',
    countyName: 'Park County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '069',
    countyName: 'Petroleum County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '071',
    countyName: 'Phillips County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '073',
    countyName: 'Pondera County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '075',
    countyName: 'Powder River County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '077',
    countyName: 'Powell County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '079',
    countyName: 'Prairie County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '081',
    countyName: 'Ravalli County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '083',
    countyName: 'Richland County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '085',
    countyName: 'Roosevelt County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '087',
    countyName: 'Rosebud County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '089',
    countyName: 'Sanders County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '091',
    countyName: 'Sheridan County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '093',
    countyName: 'Silver Bow County',
    num: 'H6',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '095',
    countyName: 'Stillwater County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '097',
    countyName: 'Sweet Grass County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '099',
    countyName: 'Teton County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '101',
    countyName: 'Toole County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '103',
    countyName: 'Treasure County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '105',
    countyName: 'Valley County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '107',
    countyName: 'Wheatland County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '109',
    countyName: 'Wibaux County',
    num: 'H1',
  },
  {
    state: 'MT',
    stateFips: '30',
    countyFips: '111',
    countyName: 'Yellowstone County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '003',
    countyName: 'Antelope County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '005',
    countyName: 'Arthur County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '007',
    countyName: 'Banner County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '009',
    countyName: 'Blaine County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '011',
    countyName: 'Boone County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '013',
    countyName: 'Box Butte County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '015',
    countyName: 'Boyd County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '017',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '019',
    countyName: 'Buffalo County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '021',
    countyName: 'Burt County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '023',
    countyName: 'Butler County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '025',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '027',
    countyName: 'Cedar County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '029',
    countyName: 'Chase County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '031',
    countyName: 'Cherry County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '033',
    countyName: 'Cheyenne County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '035',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '037',
    countyName: 'Colfax County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '039',
    countyName: 'Cuming County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '041',
    countyName: 'Custer County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '043',
    countyName: 'Dakota County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '045',
    countyName: 'Dawes County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '047',
    countyName: 'Dawson County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '049',
    countyName: 'Deuel County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '051',
    countyName: 'Dixon County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '053',
    countyName: 'Dodge County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '055',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '057',
    countyName: 'Dundy County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '059',
    countyName: 'Fillmore County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '061',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '063',
    countyName: 'Frontier County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '065',
    countyName: 'Furnas County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '067',
    countyName: 'Gage County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '069',
    countyName: 'Garden County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '071',
    countyName: 'Garfield County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '073',
    countyName: 'Gosper County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '075',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '077',
    countyName: 'Greeley County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '079',
    countyName: 'Hall County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '081',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '083',
    countyName: 'Harlan County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '085',
    countyName: 'Hayes County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '087',
    countyName: 'Hitchcock County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '089',
    countyName: 'Holt County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '091',
    countyName: 'Hooker County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '093',
    countyName: 'Howard County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '095',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '097',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '099',
    countyName: 'Kearney County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '101',
    countyName: 'Keith County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '103',
    countyName: 'Keya Paha County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '105',
    countyName: 'Kimball County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '107',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '109',
    countyName: 'Lancaster County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '111',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '113',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '115',
    countyName: 'Loup County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '117',
    countyName: 'McPherson County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '119',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '121',
    countyName: 'Merrick County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '123',
    countyName: 'Morrill County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '125',
    countyName: 'Nance County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '127',
    countyName: 'Nemaha County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '129',
    countyName: 'Nuckolls County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '131',
    countyName: 'Otoe County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '133',
    countyName: 'Pawnee County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '135',
    countyName: 'Perkins County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '137',
    countyName: 'Phelps County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '139',
    countyName: 'Pierce County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '141',
    countyName: 'Platte County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '143',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '145',
    countyName: 'Red Willow County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '147',
    countyName: 'Richardson County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '149',
    countyName: 'Rock County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '151',
    countyName: 'Saline County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '153',
    countyName: 'Sarpy County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '155',
    countyName: 'Saunders County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '157',
    countyName: 'Scotts Bluff County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '159',
    countyName: 'Seward County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '161',
    countyName: 'Sheridan County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '163',
    countyName: 'Sherman County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '165',
    countyName: 'Sioux County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '167',
    countyName: 'Stanton County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '169',
    countyName: 'Thayer County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '171',
    countyName: 'Thomas County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '173',
    countyName: 'Thurston County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '175',
    countyName: 'Valley County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '177',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '179',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '181',
    countyName: 'Webster County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '183',
    countyName: 'Wheeler County',
    num: 'H1',
  },
  {
    state: 'NE',
    stateFips: '31',
    countyFips: '185',
    countyName: 'York County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '001',
    countyName: 'Churchill County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '003',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '005',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '007',
    countyName: 'Elko County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '009',
    countyName: 'Esmeralda County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '011',
    countyName: 'Eureka County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '013',
    countyName: 'Humboldt County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '015',
    countyName: 'Lander County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '017',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '019',
    countyName: 'Lyon County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '021',
    countyName: 'Mineral County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '023',
    countyName: 'Nye County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '027',
    countyName: 'Pershing County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '029',
    countyName: 'Storey County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '031',
    countyName: 'Washoe County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '033',
    countyName: 'White Pine County',
    num: 'H1',
  },
  {
    state: 'NV',
    stateFips: '32',
    countyFips: '510',
    countyName: 'Carson City',
    num: 'C7',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '001',
    countyName: 'Belknap County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '003',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '005',
    countyName: 'Cheshire County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '007',
    countyName: 'Coos County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '009',
    countyName: 'Grafton County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '011',
    countyName: 'Hillsborough County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '013',
    countyName: 'Merrimack County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '015',
    countyName: 'Rockingham County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '017',
    countyName: 'Strafford County',
    num: 'H1',
  },
  {
    state: 'NH',
    stateFips: '33',
    countyFips: '019',
    countyName: 'Sullivan County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '001',
    countyName: 'Atlantic County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '003',
    countyName: 'Bergen County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '005',
    countyName: 'Burlington County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '007',
    countyName: 'Camden County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '009',
    countyName: 'Cape May County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '011',
    countyName: 'Cumberland County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '013',
    countyName: 'Essex County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '015',
    countyName: 'Gloucester County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '017',
    countyName: 'Hudson County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '019',
    countyName: 'Hunterdon County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '021',
    countyName: 'Mercer County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '023',
    countyName: 'Middlesex County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '025',
    countyName: 'Monmouth County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '027',
    countyName: 'Morris County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '029',
    countyName: 'Ocean County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '031',
    countyName: 'Passaic County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '033',
    countyName: 'Salem County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '035',
    countyName: 'Somerset County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '037',
    countyName: 'Sussex County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '039',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'NJ',
    stateFips: '34',
    countyFips: '041',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '001',
    countyName: 'Bernalillo County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '003',
    countyName: 'Catron County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '005',
    countyName: 'Chaves County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '006',
    countyName: 'Cibola County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '007',
    countyName: 'Colfax County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '009',
    countyName: 'Curry County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '011',
    countyName: 'De Baca County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '013',
    countyName: 'Dona Ana County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '015',
    countyName: 'Eddy County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '017',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '019',
    countyName: 'Guadalupe County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '021',
    countyName: 'Harding County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '023',
    countyName: 'Hidalgo County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '025',
    countyName: 'Lea County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '027',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '028',
    countyName: 'Los Alamos County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '029',
    countyName: 'Luna County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '031',
    countyName: 'McKinley County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '033',
    countyName: 'Mora County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '035',
    countyName: 'Otero County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '037',
    countyName: 'Quay County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '039',
    countyName: 'Rio Arriba County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '041',
    countyName: 'Roosevelt County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '043',
    countyName: 'Sandoval County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '045',
    countyName: 'San Juan County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '047',
    countyName: 'San Miguel County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '049',
    countyName: 'Santa Fe County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '051',
    countyName: 'Sierra County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '053',
    countyName: 'Socorro County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '055',
    countyName: 'Taos County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '057',
    countyName: 'Torrance County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '059',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'NM',
    stateFips: '35',
    countyFips: '061',
    countyName: 'Valencia County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '001',
    countyName: 'Albany County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '003',
    countyName: 'Allegany County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '005',
    countyName: 'Bronx County',
    num: 'H6',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '007',
    countyName: 'Broome County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '009',
    countyName: 'Cattaraugus County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '011',
    countyName: 'Cayuga County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '013',
    countyName: 'Chautauqua County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '015',
    countyName: 'Chemung County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '017',
    countyName: 'Chenango County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '019',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '021',
    countyName: 'Columbia County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '023',
    countyName: 'Cortland County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '025',
    countyName: 'Delaware County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '027',
    countyName: 'Dutchess County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '029',
    countyName: 'Erie County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '031',
    countyName: 'Essex County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '033',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '035',
    countyName: 'Fulton County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '037',
    countyName: 'Genesee County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '039',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '041',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '043',
    countyName: 'Herkimer County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '045',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '047',
    countyName: 'Kings County',
    num: 'H6',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '049',
    countyName: 'Lewis County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '051',
    countyName: 'Livingston County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '053',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '055',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '057',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '059',
    countyName: 'Nassau County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '061',
    countyName: 'New York County',
    num: 'H6',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '063',
    countyName: 'Niagara County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '065',
    countyName: 'Oneida County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '067',
    countyName: 'Onondaga County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '069',
    countyName: 'Ontario County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '071',
    countyName: 'Orange County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '073',
    countyName: 'Orleans County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '075',
    countyName: 'Oswego County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '077',
    countyName: 'Otsego County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '079',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '081',
    countyName: 'Queens County',
    num: 'H6',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '083',
    countyName: 'Rensselaer County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '085',
    countyName: 'Richmond County',
    num: 'H6',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '087',
    countyName: 'Rockland County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '089',
    countyName: 'St. Lawrence County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '091',
    countyName: 'Saratoga County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '093',
    countyName: 'Schenectady County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '095',
    countyName: 'Schoharie County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '097',
    countyName: 'Schuyler County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '099',
    countyName: 'Seneca County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '101',
    countyName: 'Steuben County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '103',
    countyName: 'Suffolk County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '105',
    countyName: 'Sullivan County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '107',
    countyName: 'Tioga County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '109',
    countyName: 'Tompkins County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '111',
    countyName: 'Ulster County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '113',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '115',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '117',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '119',
    countyName: 'Westchester County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '121',
    countyName: 'Wyoming County',
    num: 'H1',
  },
  {
    state: 'NY',
    stateFips: '36',
    countyFips: '123',
    countyName: 'Yates County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '001',
    countyName: 'Alamance County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '003',
    countyName: 'Alexander County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '005',
    countyName: 'Alleghany County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '007',
    countyName: 'Anson County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '009',
    countyName: 'Ashe County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '011',
    countyName: 'Avery County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '013',
    countyName: 'Beaufort County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '015',
    countyName: 'Bertie County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '017',
    countyName: 'Bladen County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '019',
    countyName: 'Brunswick County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '021',
    countyName: 'Buncombe County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '023',
    countyName: 'Burke County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '025',
    countyName: 'Cabarrus County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '027',
    countyName: 'Caldwell County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '029',
    countyName: 'Camden County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '031',
    countyName: 'Carteret County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '033',
    countyName: 'Caswell County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '035',
    countyName: 'Catawba County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '037',
    countyName: 'Chatham County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '039',
    countyName: 'Cherokee County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '041',
    countyName: 'Chowan County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '043',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '045',
    countyName: 'Cleveland County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '047',
    countyName: 'Columbus County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '049',
    countyName: 'Craven County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '051',
    countyName: 'Cumberland County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '053',
    countyName: 'Currituck County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '055',
    countyName: 'Dare County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '057',
    countyName: 'Davidson County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '059',
    countyName: 'Davie County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '061',
    countyName: 'Duplin County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '063',
    countyName: 'Durham County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '065',
    countyName: 'Edgecombe County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '067',
    countyName: 'Forsyth County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '069',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '071',
    countyName: 'Gaston County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '073',
    countyName: 'Gates County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '075',
    countyName: 'Graham County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '077',
    countyName: 'Granville County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '079',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '081',
    countyName: 'Guilford County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '083',
    countyName: 'Halifax County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '085',
    countyName: 'Harnett County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '087',
    countyName: 'Haywood County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '089',
    countyName: 'Henderson County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '091',
    countyName: 'Hertford County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '093',
    countyName: 'Hoke County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '095',
    countyName: 'Hyde County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '097',
    countyName: 'Iredell County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '099',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '101',
    countyName: 'Johnston County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '103',
    countyName: 'Jones County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '105',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '107',
    countyName: 'Lenoir County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '109',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '111',
    countyName: 'McDowell County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '113',
    countyName: 'Macon County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '115',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '117',
    countyName: 'Martin County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '119',
    countyName: 'Mecklenburg County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '121',
    countyName: 'Mitchell County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '123',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '125',
    countyName: 'Moore County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '127',
    countyName: 'Nash County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '129',
    countyName: 'New Hanover County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '131',
    countyName: 'Northampton County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '133',
    countyName: 'Onslow County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '135',
    countyName: 'Orange County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '137',
    countyName: 'Pamlico County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '139',
    countyName: 'Pasquotank County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '141',
    countyName: 'Pender County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '143',
    countyName: 'Perquimans County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '145',
    countyName: 'Person County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '147',
    countyName: 'Pitt County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '149',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '151',
    countyName: 'Randolph County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '153',
    countyName: 'Richmond County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '155',
    countyName: 'Robeson County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '157',
    countyName: 'Rockingham County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '159',
    countyName: 'Rowan County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '161',
    countyName: 'Rutherford County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '163',
    countyName: 'Sampson County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '165',
    countyName: 'Scotland County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '167',
    countyName: 'Stanly County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '169',
    countyName: 'Stokes County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '171',
    countyName: 'Surry County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '173',
    countyName: 'Swain County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '175',
    countyName: 'Transylvania County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '177',
    countyName: 'Tyrrell County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '179',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '181',
    countyName: 'Vance County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '183',
    countyName: 'Wake County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '185',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '187',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '189',
    countyName: 'Watauga County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '191',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '193',
    countyName: 'Wilkes County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '195',
    countyName: 'Wilson County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '197',
    countyName: 'Yadkin County',
    num: 'H1',
  },
  {
    state: 'NC',
    stateFips: '37',
    countyFips: '199',
    countyName: 'Yancey County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '003',
    countyName: 'Barnes County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '005',
    countyName: 'Benson County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '007',
    countyName: 'Billings County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '009',
    countyName: 'Bottineau County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '011',
    countyName: 'Bowman County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '013',
    countyName: 'Burke County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '015',
    countyName: 'Burleigh County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '017',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '019',
    countyName: 'Cavalier County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '021',
    countyName: 'Dickey County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '023',
    countyName: 'Divide County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '025',
    countyName: 'Dunn County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '027',
    countyName: 'Eddy County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '029',
    countyName: 'Emmons County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '031',
    countyName: 'Foster County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '033',
    countyName: 'Golden Valley County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '035',
    countyName: 'Grand Forks County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '037',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '039',
    countyName: 'Griggs County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '041',
    countyName: 'Hettinger County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '043',
    countyName: 'Kidder County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '045',
    countyName: 'LaMoure County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '047',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '049',
    countyName: 'McHenry County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '051',
    countyName: 'McIntosh County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '053',
    countyName: 'McKenzie County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '055',
    countyName: 'McLean County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '057',
    countyName: 'Mercer County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '059',
    countyName: 'Morton County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '061',
    countyName: 'Mountrail County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '063',
    countyName: 'Nelson County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '065',
    countyName: 'Oliver County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '067',
    countyName: 'Pembina County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '069',
    countyName: 'Pierce County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '071',
    countyName: 'Ramsey County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '073',
    countyName: 'Ransom County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '075',
    countyName: 'Renville County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '077',
    countyName: 'Richland County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '079',
    countyName: 'Rolette County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '081',
    countyName: 'Sargent County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '083',
    countyName: 'Sheridan County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '085',
    countyName: 'Sioux County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '087',
    countyName: 'Slope County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '089',
    countyName: 'Stark County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '091',
    countyName: 'Steele County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '093',
    countyName: 'Stutsman County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '095',
    countyName: 'Towner County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '097',
    countyName: 'Traill County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '099',
    countyName: 'Walsh County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '101',
    countyName: 'Ward County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '103',
    countyName: 'Wells County',
    num: 'H1',
  },
  {
    state: 'ND',
    stateFips: '38',
    countyFips: '105',
    countyName: 'Williams County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '003',
    countyName: 'Allen County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '005',
    countyName: 'Ashland County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '007',
    countyName: 'Ashtabula County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '009',
    countyName: 'Athens County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '011',
    countyName: 'Auglaize County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '013',
    countyName: 'Belmont County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '015',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '017',
    countyName: 'Butler County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '019',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '021',
    countyName: 'Champaign County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '023',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '025',
    countyName: 'Clermont County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '027',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '029',
    countyName: 'Columbiana County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '031',
    countyName: 'Coshocton County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '033',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '035',
    countyName: 'Cuyahoga County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '037',
    countyName: 'Darke County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '039',
    countyName: 'Defiance County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '041',
    countyName: 'Delaware County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '043',
    countyName: 'Erie County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '045',
    countyName: 'Fairfield County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '047',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '049',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '051',
    countyName: 'Fulton County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '053',
    countyName: 'Gallia County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '055',
    countyName: 'Geauga County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '057',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '059',
    countyName: 'Guernsey County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '061',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '063',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '065',
    countyName: 'Hardin County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '067',
    countyName: 'Harrison County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '069',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '071',
    countyName: 'Highland County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '073',
    countyName: 'Hocking County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '075',
    countyName: 'Holmes County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '077',
    countyName: 'Huron County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '079',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '081',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '083',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '085',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '087',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '089',
    countyName: 'Licking County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '091',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '093',
    countyName: 'Lorain County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '095',
    countyName: 'Lucas County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '097',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '099',
    countyName: 'Mahoning County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '101',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '103',
    countyName: 'Medina County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '105',
    countyName: 'Meigs County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '107',
    countyName: 'Mercer County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '109',
    countyName: 'Miami County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '111',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '113',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '115',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '117',
    countyName: 'Morrow County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '119',
    countyName: 'Muskingum County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '121',
    countyName: 'Noble County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '123',
    countyName: 'Ottawa County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '125',
    countyName: 'Paulding County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '127',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '129',
    countyName: 'Pickaway County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '131',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '133',
    countyName: 'Portage County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '135',
    countyName: 'Preble County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '137',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '139',
    countyName: 'Richland County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '141',
    countyName: 'Ross County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '143',
    countyName: 'Sandusky County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '145',
    countyName: 'Scioto County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '147',
    countyName: 'Seneca County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '149',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '151',
    countyName: 'Stark County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '153',
    countyName: 'Summit County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '155',
    countyName: 'Trumbull County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '157',
    countyName: 'Tuscarawas County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '159',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '161',
    countyName: 'Van Wert County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '163',
    countyName: 'Vinton County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '165',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '167',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '169',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '171',
    countyName: 'Williams County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '173',
    countyName: 'Wood County',
    num: 'H1',
  },
  {
    state: 'OH',
    stateFips: '39',
    countyFips: '175',
    countyName: 'Wyandot County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '001',
    countyName: 'Adair County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '003',
    countyName: 'Alfalfa County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '005',
    countyName: 'Atoka County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '007',
    countyName: 'Beaver County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '009',
    countyName: 'Beckham County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '011',
    countyName: 'Blaine County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '013',
    countyName: 'Bryan County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '015',
    countyName: 'Caddo County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '017',
    countyName: 'Canadian County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '019',
    countyName: 'Carter County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '021',
    countyName: 'Cherokee County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '023',
    countyName: 'Choctaw County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '025',
    countyName: 'Cimarron County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '027',
    countyName: 'Cleveland County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '029',
    countyName: 'Coal County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '031',
    countyName: 'Comanche County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '033',
    countyName: 'Cotton County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '035',
    countyName: 'Craig County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '037',
    countyName: 'Creek County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '039',
    countyName: 'Custer County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '041',
    countyName: 'Delaware County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '043',
    countyName: 'Dewey County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '045',
    countyName: 'Ellis County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '047',
    countyName: 'Garfield County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '049',
    countyName: 'Garvin County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '051',
    countyName: 'Grady County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '053',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '055',
    countyName: 'Greer County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '057',
    countyName: 'Harmon County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '059',
    countyName: 'Harper County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '061',
    countyName: 'Haskell County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '063',
    countyName: 'Hughes County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '065',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '067',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '069',
    countyName: 'Johnston County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '071',
    countyName: 'Kay County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '073',
    countyName: 'Kingfisher County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '075',
    countyName: 'Kiowa County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '077',
    countyName: 'Latimer County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '079',
    countyName: 'Le Flore County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '081',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '083',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '085',
    countyName: 'Love County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '087',
    countyName: 'McClain County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '089',
    countyName: 'McCurtain County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '091',
    countyName: 'McIntosh County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '093',
    countyName: 'Major County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '095',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '097',
    countyName: 'Mayes County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '099',
    countyName: 'Murray County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '101',
    countyName: 'Muskogee County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '103',
    countyName: 'Noble County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '105',
    countyName: 'Nowata County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '107',
    countyName: 'Okfuskee County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '109',
    countyName: 'Oklahoma County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '111',
    countyName: 'Okmulgee County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '113',
    countyName: 'Osage County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '115',
    countyName: 'Ottawa County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '117',
    countyName: 'Pawnee County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '119',
    countyName: 'Payne County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '121',
    countyName: 'Pittsburg County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '123',
    countyName: 'Pontotoc County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '125',
    countyName: 'Pottawatomie County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '127',
    countyName: 'Pushmataha County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '129',
    countyName: 'Roger Mills County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '131',
    countyName: 'Rogers County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '133',
    countyName: 'Seminole County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '135',
    countyName: 'Sequoyah County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '137',
    countyName: 'Stephens County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '139',
    countyName: 'Texas County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '141',
    countyName: 'Tillman County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '143',
    countyName: 'Tulsa County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '145',
    countyName: 'Wagoner County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '147',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '149',
    countyName: 'Washita County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '151',
    countyName: 'Woods County',
    num: 'H1',
  },
  {
    state: 'OK',
    stateFips: '40',
    countyFips: '153',
    countyName: 'Woodward County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '001',
    countyName: 'Baker County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '003',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '005',
    countyName: 'Clackamas County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '007',
    countyName: 'Clatsop County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '009',
    countyName: 'Columbia County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '011',
    countyName: 'Coos County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '013',
    countyName: 'Crook County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '015',
    countyName: 'Curry County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '017',
    countyName: 'Deschutes County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '019',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '021',
    countyName: 'Gilliam County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '023',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '025',
    countyName: 'Harney County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '027',
    countyName: 'Hood River County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '029',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '031',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '033',
    countyName: 'Josephine County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '035',
    countyName: 'Klamath County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '037',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '039',
    countyName: 'Lane County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '041',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '043',
    countyName: 'Linn County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '045',
    countyName: 'Malheur County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '047',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '049',
    countyName: 'Morrow County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '051',
    countyName: 'Multnomah County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '053',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '055',
    countyName: 'Sherman County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '057',
    countyName: 'Tillamook County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '059',
    countyName: 'Umatilla County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '061',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '063',
    countyName: 'Wallowa County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '065',
    countyName: 'Wasco County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '067',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '069',
    countyName: 'Wheeler County',
    num: 'H1',
  },
  {
    state: 'OR',
    stateFips: '41',
    countyFips: '071',
    countyName: 'Yamhill County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '003',
    countyName: 'Allegheny County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '005',
    countyName: 'Armstrong County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '007',
    countyName: 'Beaver County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '009',
    countyName: 'Bedford County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '011',
    countyName: 'Berks County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '013',
    countyName: 'Blair County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '015',
    countyName: 'Bradford County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '017',
    countyName: 'Bucks County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '019',
    countyName: 'Butler County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '021',
    countyName: 'Cambria County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '023',
    countyName: 'Cameron County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '025',
    countyName: 'Carbon County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '027',
    countyName: 'Centre County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '029',
    countyName: 'Chester County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '031',
    countyName: 'Clarion County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '033',
    countyName: 'Clearfield County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '035',
    countyName: 'Clinton County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '037',
    countyName: 'Columbia County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '039',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '041',
    countyName: 'Cumberland County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '043',
    countyName: 'Dauphin County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '045',
    countyName: 'Delaware County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '047',
    countyName: 'Elk County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '049',
    countyName: 'Erie County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '051',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '053',
    countyName: 'Forest County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '055',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '057',
    countyName: 'Fulton County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '059',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '061',
    countyName: 'Huntingdon County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '063',
    countyName: 'Indiana County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '065',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '067',
    countyName: 'Juniata County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '069',
    countyName: 'Lackawanna County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '071',
    countyName: 'Lancaster County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '073',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '075',
    countyName: 'Lebanon County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '077',
    countyName: 'Lehigh County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '079',
    countyName: 'Luzerne County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '081',
    countyName: 'Lycoming County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '083',
    countyName: 'McKean County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '085',
    countyName: 'Mercer County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '087',
    countyName: 'Mifflin County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '089',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '091',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '093',
    countyName: 'Montour County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '095',
    countyName: 'Northampton County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '097',
    countyName: 'Northumberland County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '099',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '101',
    countyName: 'Philadelphia County',
    num: 'H6',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '103',
    countyName: 'Pike County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '105',
    countyName: 'Potter County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '107',
    countyName: 'Schuylkill County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '109',
    countyName: 'Snyder County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '111',
    countyName: 'Somerset County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '113',
    countyName: 'Sullivan County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '115',
    countyName: 'Susquehanna County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '117',
    countyName: 'Tioga County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '119',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '121',
    countyName: 'Venango County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '123',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '125',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '127',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '129',
    countyName: 'Westmoreland County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '131',
    countyName: 'Wyoming County',
    num: 'H1',
  },
  {
    state: 'PA',
    stateFips: '42',
    countyFips: '133',
    countyName: 'York County',
    num: 'H1',
  },
  {
    state: 'RI',
    stateFips: '44',
    countyFips: '001',
    countyName: 'Bristol County',
    num: 'H4',
  },
  {
    state: 'RI',
    stateFips: '44',
    countyFips: '003',
    countyName: 'Kent County',
    num: 'H4',
  },
  {
    state: 'RI',
    stateFips: '44',
    countyFips: '005',
    countyName: 'Newport County',
    num: 'H4',
  },
  {
    state: 'RI',
    stateFips: '44',
    countyFips: '007',
    countyName: 'Providence County',
    num: 'H4',
  },
  {
    state: 'RI',
    stateFips: '44',
    countyFips: '009',
    countyName: 'Washington County',
    num: 'H4',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '001',
    countyName: 'Abbeville County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '003',
    countyName: 'Aiken County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '005',
    countyName: 'Allendale County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '007',
    countyName: 'Anderson County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '009',
    countyName: 'Bamberg County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '011',
    countyName: 'Barnwell County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '013',
    countyName: 'Beaufort County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '015',
    countyName: 'Berkeley County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '017',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '019',
    countyName: 'Charleston County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '021',
    countyName: 'Cherokee County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '023',
    countyName: 'Chester County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '025',
    countyName: 'Chesterfield County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '027',
    countyName: 'Clarendon County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '029',
    countyName: 'Colleton County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '031',
    countyName: 'Darlington County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '033',
    countyName: 'Dillon County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '035',
    countyName: 'Dorchester County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '037',
    countyName: 'Edgefield County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '039',
    countyName: 'Fairfield County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '041',
    countyName: 'Florence County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '043',
    countyName: 'Georgetown County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '045',
    countyName: 'Greenville County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '047',
    countyName: 'Greenwood County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '049',
    countyName: 'Hampton County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '051',
    countyName: 'Horry County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '053',
    countyName: 'Jasper County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '055',
    countyName: 'Kershaw County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '057',
    countyName: 'Lancaster County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '059',
    countyName: 'Laurens County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '061',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '063',
    countyName: 'Lexington County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '065',
    countyName: 'McCormick County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '067',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '069',
    countyName: 'Marlboro County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '071',
    countyName: 'Newberry County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '073',
    countyName: 'Oconee County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '075',
    countyName: 'Orangeburg County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '077',
    countyName: 'Pickens County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '079',
    countyName: 'Richland County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '081',
    countyName: 'Saluda County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '083',
    countyName: 'Spartanburg County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '085',
    countyName: 'Sumter County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '087',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '089',
    countyName: 'Williamsburg County',
    num: 'H1',
  },
  {
    state: 'SC',
    stateFips: '45',
    countyFips: '091',
    countyName: 'York County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '003',
    countyName: 'Aurora County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '005',
    countyName: 'Beadle County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '007',
    countyName: 'Bennett County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '009',
    countyName: 'Bon Homme County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '011',
    countyName: 'Brookings County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '013',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '015',
    countyName: 'Brule County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '017',
    countyName: 'Buffalo County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '019',
    countyName: 'Butte County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '021',
    countyName: 'Campbell County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '023',
    countyName: 'Charles Mix County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '025',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '027',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '029',
    countyName: 'Codington County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '031',
    countyName: 'Corson County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '033',
    countyName: 'Custer County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '035',
    countyName: 'Davison County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '037',
    countyName: 'Day County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '039',
    countyName: 'Deuel County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '041',
    countyName: 'Dewey County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '043',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '045',
    countyName: 'Edmunds County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '047',
    countyName: 'Fall River County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '049',
    countyName: 'Faulk County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '051',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '053',
    countyName: 'Gregory County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '055',
    countyName: 'Haakon County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '057',
    countyName: 'Hamlin County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '059',
    countyName: 'Hand County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '061',
    countyName: 'Hanson County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '063',
    countyName: 'Harding County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '065',
    countyName: 'Hughes County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '067',
    countyName: 'Hutchinson County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '069',
    countyName: 'Hyde County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '071',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '073',
    countyName: 'Jerauld County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '075',
    countyName: 'Jones County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '077',
    countyName: 'Kingsbury County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '079',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '081',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '083',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '085',
    countyName: 'Lyman County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '087',
    countyName: 'McCook County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '089',
    countyName: 'McPherson County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '091',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '093',
    countyName: 'Meade County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '095',
    countyName: 'Mellette County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '097',
    countyName: 'Miner County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '099',
    countyName: 'Minnehaha County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '101',
    countyName: 'Moody County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '103',
    countyName: 'Pennington County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '105',
    countyName: 'Perkins County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '107',
    countyName: 'Potter County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '109',
    countyName: 'Roberts County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '111',
    countyName: 'Sanborn County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '113',
    countyName: 'Shannon County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '115',
    countyName: 'Spink County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '117',
    countyName: 'Stanley County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '119',
    countyName: 'Sully County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '121',
    countyName: 'Todd County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '123',
    countyName: 'Tripp County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '125',
    countyName: 'Turner County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '127',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '129',
    countyName: 'Walworth County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '135',
    countyName: 'Yankton County',
    num: 'H1',
  },
  {
    state: 'SD',
    stateFips: '46',
    countyFips: '137',
    countyName: 'Ziebach County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '001',
    countyName: 'Anderson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '003',
    countyName: 'Bedford County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '005',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '007',
    countyName: 'Bledsoe County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '009',
    countyName: 'Blount County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '011',
    countyName: 'Bradley County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '013',
    countyName: 'Campbell County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '015',
    countyName: 'Cannon County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '017',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '019',
    countyName: 'Carter County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '021',
    countyName: 'Cheatham County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '023',
    countyName: 'Chester County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '025',
    countyName: 'Claiborne County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '027',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '029',
    countyName: 'Cocke County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '031',
    countyName: 'Coffee County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '033',
    countyName: 'Crockett County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '035',
    countyName: 'Cumberland County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '037',
    countyName: 'Davidson County',
    num: 'H6',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '039',
    countyName: 'Decatur County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '041',
    countyName: 'DeKalb County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '043',
    countyName: 'Dickson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '045',
    countyName: 'Dyer County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '047',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '049',
    countyName: 'Fentress County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '051',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '053',
    countyName: 'Gibson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '055',
    countyName: 'Giles County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '057',
    countyName: 'Grainger County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '059',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '061',
    countyName: 'Grundy County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '063',
    countyName: 'Hamblen County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '065',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '067',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '069',
    countyName: 'Hardeman County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '071',
    countyName: 'Hardin County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '073',
    countyName: 'Hawkins County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '075',
    countyName: 'Haywood County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '077',
    countyName: 'Henderson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '079',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '081',
    countyName: 'Hickman County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '083',
    countyName: 'Houston County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '085',
    countyName: 'Humphreys County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '087',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '089',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '091',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '093',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '095',
    countyName: 'Lake County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '097',
    countyName: 'Lauderdale County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '099',
    countyName: 'Lawrence County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '101',
    countyName: 'Lewis County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '103',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '105',
    countyName: 'Loudon County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '107',
    countyName: 'McMinn County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '109',
    countyName: 'McNairy County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '111',
    countyName: 'Macon County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '113',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '115',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '117',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '119',
    countyName: 'Maury County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '121',
    countyName: 'Meigs County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '123',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '125',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '127',
    countyName: 'Moore County',
    num: 'H6',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '129',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '131',
    countyName: 'Obion County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '133',
    countyName: 'Overton County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '135',
    countyName: 'Perry County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '137',
    countyName: 'Pickett County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '139',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '141',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '143',
    countyName: 'Rhea County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '145',
    countyName: 'Roane County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '147',
    countyName: 'Robertson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '149',
    countyName: 'Rutherford County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '151',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '153',
    countyName: 'Sequatchie County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '155',
    countyName: 'Sevier County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '157',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '159',
    countyName: 'Smith County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '161',
    countyName: 'Stewart County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '163',
    countyName: 'Sullivan County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '165',
    countyName: 'Sumner County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '167',
    countyName: 'Tipton County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '169',
    countyName: 'Trousdale County',
    num: 'H6',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '171',
    countyName: 'Unicoi County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '173',
    countyName: 'Union County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '175',
    countyName: 'Van Buren County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '177',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '179',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '181',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '183',
    countyName: 'Weakley County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '185',
    countyName: 'White County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '187',
    countyName: 'Williamson County',
    num: 'H1',
  },
  {
    state: 'TN',
    stateFips: '47',
    countyFips: '189',
    countyName: 'Wilson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '001',
    countyName: 'Anderson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '003',
    countyName: 'Andrews County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '005',
    countyName: 'Angelina County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '007',
    countyName: 'Aransas County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '009',
    countyName: 'Archer County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '011',
    countyName: 'Armstrong County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '013',
    countyName: 'Atascosa County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '015',
    countyName: 'Austin County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '017',
    countyName: 'Bailey County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '019',
    countyName: 'Bandera County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '021',
    countyName: 'Bastrop County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '023',
    countyName: 'Baylor County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '025',
    countyName: 'Bee County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '027',
    countyName: 'Bell County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '029',
    countyName: 'Bexar County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '031',
    countyName: 'Blanco County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '033',
    countyName: 'Borden County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '035',
    countyName: 'Bosque County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '037',
    countyName: 'Bowie County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '039',
    countyName: 'Brazoria County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '041',
    countyName: 'Brazos County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '043',
    countyName: 'Brewster County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '045',
    countyName: 'Briscoe County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '047',
    countyName: 'Brooks County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '049',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '051',
    countyName: 'Burleson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '053',
    countyName: 'Burnet County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '055',
    countyName: 'Caldwell County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '057',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '059',
    countyName: 'Callahan County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '061',
    countyName: 'Cameron County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '063',
    countyName: 'Camp County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '065',
    countyName: 'Carson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '067',
    countyName: 'Cass County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '069',
    countyName: 'Castro County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '071',
    countyName: 'Chambers County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '073',
    countyName: 'Cherokee County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '075',
    countyName: 'Childress County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '077',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '079',
    countyName: 'Cochran County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '081',
    countyName: 'Coke County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '083',
    countyName: 'Coleman County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '085',
    countyName: 'Collin County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '087',
    countyName: 'Collingsworth County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '089',
    countyName: 'Colorado County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '091',
    countyName: 'Comal County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '093',
    countyName: 'Comanche County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '095',
    countyName: 'Concho County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '097',
    countyName: 'Cooke County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '099',
    countyName: 'Coryell County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '101',
    countyName: 'Cottle County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '103',
    countyName: 'Crane County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '105',
    countyName: 'Crockett County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '107',
    countyName: 'Crosby County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '109',
    countyName: 'Culberson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '111',
    countyName: 'Dallam County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '113',
    countyName: 'Dallas County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '115',
    countyName: 'Dawson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '117',
    countyName: 'Deaf Smith County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '119',
    countyName: 'Delta County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '121',
    countyName: 'Denton County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '123',
    countyName: 'DeWitt County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '125',
    countyName: 'Dickens County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '127',
    countyName: 'Dimmit County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '129',
    countyName: 'Donley County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '131',
    countyName: 'Duval County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '133',
    countyName: 'Eastland County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '135',
    countyName: 'Ector County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '137',
    countyName: 'Edwards County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '139',
    countyName: 'Ellis County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '141',
    countyName: 'El Paso County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '143',
    countyName: 'Erath County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '145',
    countyName: 'Falls County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '147',
    countyName: 'Fannin County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '149',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '151',
    countyName: 'Fisher County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '153',
    countyName: 'Floyd County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '155',
    countyName: 'Foard County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '157',
    countyName: 'Fort Bend County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '159',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '161',
    countyName: 'Freestone County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '163',
    countyName: 'Frio County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '165',
    countyName: 'Gaines County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '167',
    countyName: 'Galveston County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '169',
    countyName: 'Garza County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '171',
    countyName: 'Gillespie County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '173',
    countyName: 'Glasscock County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '175',
    countyName: 'Goliad County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '177',
    countyName: 'Gonzales County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '179',
    countyName: 'Gray County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '181',
    countyName: 'Grayson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '183',
    countyName: 'Gregg County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '185',
    countyName: 'Grimes County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '187',
    countyName: 'Guadalupe County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '189',
    countyName: 'Hale County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '191',
    countyName: 'Hall County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '193',
    countyName: 'Hamilton County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '195',
    countyName: 'Hansford County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '197',
    countyName: 'Hardeman County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '199',
    countyName: 'Hardin County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '201',
    countyName: 'Harris County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '203',
    countyName: 'Harrison County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '205',
    countyName: 'Hartley County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '207',
    countyName: 'Haskell County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '209',
    countyName: 'Hays County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '211',
    countyName: 'Hemphill County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '213',
    countyName: 'Henderson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '215',
    countyName: 'Hidalgo County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '217',
    countyName: 'Hill County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '219',
    countyName: 'Hockley County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '221',
    countyName: 'Hood County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '223',
    countyName: 'Hopkins County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '225',
    countyName: 'Houston County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '227',
    countyName: 'Howard County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '229',
    countyName: 'Hudspeth County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '231',
    countyName: 'Hunt County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '233',
    countyName: 'Hutchinson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '235',
    countyName: 'Irion County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '237',
    countyName: 'Jack County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '239',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '241',
    countyName: 'Jasper County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '243',
    countyName: 'Jeff Davis County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '245',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '247',
    countyName: 'Jim Hogg County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '249',
    countyName: 'Jim Wells County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '251',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '253',
    countyName: 'Jones County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '255',
    countyName: 'Karnes County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '257',
    countyName: 'Kaufman County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '259',
    countyName: 'Kendall County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '261',
    countyName: 'Kenedy County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '263',
    countyName: 'Kent County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '265',
    countyName: 'Kerr County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '267',
    countyName: 'Kimble County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '269',
    countyName: 'King County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '271',
    countyName: 'Kinney County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '273',
    countyName: 'Kleberg County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '275',
    countyName: 'Knox County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '277',
    countyName: 'Lamar County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '279',
    countyName: 'Lamb County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '281',
    countyName: 'Lampasas County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '283',
    countyName: 'La Salle County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '285',
    countyName: 'Lavaca County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '287',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '289',
    countyName: 'Leon County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '291',
    countyName: 'Liberty County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '293',
    countyName: 'Limestone County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '295',
    countyName: 'Lipscomb County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '297',
    countyName: 'Live Oak County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '299',
    countyName: 'Llano County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '301',
    countyName: 'Loving County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '303',
    countyName: 'Lubbock County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '305',
    countyName: 'Lynn County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '307',
    countyName: 'McCulloch County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '309',
    countyName: 'McLennan County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '311',
    countyName: 'McMullen County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '313',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '315',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '317',
    countyName: 'Martin County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '319',
    countyName: 'Mason County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '321',
    countyName: 'Matagorda County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '323',
    countyName: 'Maverick County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '325',
    countyName: 'Medina County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '327',
    countyName: 'Menard County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '329',
    countyName: 'Midland County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '331',
    countyName: 'Milam County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '333',
    countyName: 'Mills County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '335',
    countyName: 'Mitchell County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '337',
    countyName: 'Montague County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '339',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '341',
    countyName: 'Moore County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '343',
    countyName: 'Morris County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '345',
    countyName: 'Motley County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '347',
    countyName: 'Nacogdoches County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '349',
    countyName: 'Navarro County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '351',
    countyName: 'Newton County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '353',
    countyName: 'Nolan County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '355',
    countyName: 'Nueces County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '357',
    countyName: 'Ochiltree County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '359',
    countyName: 'Oldham County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '361',
    countyName: 'Orange County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '363',
    countyName: 'Palo Pinto County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '365',
    countyName: 'Panola County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '367',
    countyName: 'Parker County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '369',
    countyName: 'Parmer County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '371',
    countyName: 'Pecos County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '373',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '375',
    countyName: 'Potter County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '377',
    countyName: 'Presidio County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '379',
    countyName: 'Rains County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '381',
    countyName: 'Randall County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '383',
    countyName: 'Reagan County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '385',
    countyName: 'Real County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '387',
    countyName: 'Red River County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '389',
    countyName: 'Reeves County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '391',
    countyName: 'Refugio County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '393',
    countyName: 'Roberts County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '395',
    countyName: 'Robertson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '397',
    countyName: 'Rockwall County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '399',
    countyName: 'Runnels County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '401',
    countyName: 'Rusk County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '403',
    countyName: 'Sabine County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '405',
    countyName: 'San Augustine County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '407',
    countyName: 'San Jacinto County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '409',
    countyName: 'San Patricio County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '411',
    countyName: 'San Saba County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '413',
    countyName: 'Schleicher County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '415',
    countyName: 'Scurry County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '417',
    countyName: 'Shackelford County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '419',
    countyName: 'Shelby County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '421',
    countyName: 'Sherman County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '423',
    countyName: 'Smith County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '425',
    countyName: 'Somervell County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '427',
    countyName: 'Starr County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '429',
    countyName: 'Stephens County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '431',
    countyName: 'Sterling County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '433',
    countyName: 'Stonewall County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '435',
    countyName: 'Sutton County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '437',
    countyName: 'Swisher County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '439',
    countyName: 'Tarrant County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '441',
    countyName: 'Taylor County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '443',
    countyName: 'Terrell County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '445',
    countyName: 'Terry County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '447',
    countyName: 'Throckmorton County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '449',
    countyName: 'Titus County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '451',
    countyName: 'Tom Green County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '453',
    countyName: 'Travis County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '455',
    countyName: 'Trinity County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '457',
    countyName: 'Tyler County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '459',
    countyName: 'Upshur County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '461',
    countyName: 'Upton County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '463',
    countyName: 'Uvalde County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '465',
    countyName: 'Val Verde County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '467',
    countyName: 'Van Zandt County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '469',
    countyName: 'Victoria County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '471',
    countyName: 'Walker County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '473',
    countyName: 'Waller County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '475',
    countyName: 'Ward County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '477',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '479',
    countyName: 'Webb County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '481',
    countyName: 'Wharton County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '483',
    countyName: 'Wheeler County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '485',
    countyName: 'Wichita County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '487',
    countyName: 'Wilbarger County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '489',
    countyName: 'Willacy County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '491',
    countyName: 'Williamson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '493',
    countyName: 'Wilson County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '495',
    countyName: 'Winkler County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '497',
    countyName: 'Wise County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '499',
    countyName: 'Wood County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '501',
    countyName: 'Yoakum County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '503',
    countyName: 'Young County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '505',
    countyName: 'Zapata County',
    num: 'H1',
  },
  {
    state: 'TX',
    stateFips: '48',
    countyFips: '507',
    countyName: 'Zavala County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '001',
    countyName: 'Beaver County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '003',
    countyName: 'Box Elder County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '005',
    countyName: 'Cache County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '007',
    countyName: 'Carbon County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '009',
    countyName: 'Daggett County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '011',
    countyName: 'Davis County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '013',
    countyName: 'Duchesne County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '015',
    countyName: 'Emery County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '017',
    countyName: 'Garfield County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '019',
    countyName: 'Grand County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '021',
    countyName: 'Iron County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '023',
    countyName: 'Juab County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '025',
    countyName: 'Kane County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '027',
    countyName: 'Millard County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '029',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '031',
    countyName: 'Piute County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '033',
    countyName: 'Rich County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '035',
    countyName: 'Salt Lake County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '037',
    countyName: 'San Juan County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '039',
    countyName: 'Sanpete County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '041',
    countyName: 'Sevier County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '043',
    countyName: 'Summit County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '045',
    countyName: 'Tooele County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '047',
    countyName: 'Uintah County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '049',
    countyName: 'Utah County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '051',
    countyName: 'Wasatch County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '053',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '055',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'UT',
    stateFips: '49',
    countyFips: '057',
    countyName: 'Weber County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '001',
    countyName: 'Addison County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '003',
    countyName: 'Bennington County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '005',
    countyName: 'Caledonia County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '007',
    countyName: 'Chittenden County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '009',
    countyName: 'Essex County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '011',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '013',
    countyName: 'Grand Isle County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '015',
    countyName: 'Lamoille County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '017',
    countyName: 'Orange County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '019',
    countyName: 'Orleans County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '021',
    countyName: 'Rutland County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '023',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '025',
    countyName: 'Windham County',
    num: 'H1',
  },
  {
    state: 'VT',
    stateFips: '50',
    countyFips: '027',
    countyName: 'Windsor County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '001',
    countyName: 'Accomack County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '003',
    countyName: 'Albemarle County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '005',
    countyName: 'Alleghany County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '007',
    countyName: 'Amelia County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '009',
    countyName: 'Amherst County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '011',
    countyName: 'Appomattox County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '013',
    countyName: 'Arlington County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '015',
    countyName: 'Augusta County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '017',
    countyName: 'Bath County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '019',
    countyName: 'Bedford County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '021',
    countyName: 'Bland County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '023',
    countyName: 'Botetourt County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '025',
    countyName: 'Brunswick County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '027',
    countyName: 'Buchanan County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '029',
    countyName: 'Buckingham County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '031',
    countyName: 'Campbell County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '033',
    countyName: 'Caroline County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '035',
    countyName: 'Carroll County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '036',
    countyName: 'Charles City County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '037',
    countyName: 'Charlotte County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '041',
    countyName: 'Chesterfield County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '043',
    countyName: 'Clarke County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '045',
    countyName: 'Craig County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '047',
    countyName: 'Culpeper County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '049',
    countyName: 'Cumberland County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '051',
    countyName: 'Dickenson County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '053',
    countyName: 'Dinwiddie County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '057',
    countyName: 'Essex County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '059',
    countyName: 'Fairfax County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '061',
    countyName: 'Fauquier County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '063',
    countyName: 'Floyd County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '065',
    countyName: 'Fluvanna County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '067',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '069',
    countyName: 'Frederick County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '071',
    countyName: 'Giles County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '073',
    countyName: 'Gloucester County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '075',
    countyName: 'Goochland County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '077',
    countyName: 'Grayson County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '079',
    countyName: 'Greene County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '081',
    countyName: 'Greensville County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '083',
    countyName: 'Halifax County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '085',
    countyName: 'Hanover County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '087',
    countyName: 'Henrico County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '089',
    countyName: 'Henry County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '091',
    countyName: 'Highland County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '093',
    countyName: 'Isle of Wight County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '095',
    countyName: 'James City County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '097',
    countyName: 'King and Queen County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '099',
    countyName: 'King George County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '101',
    countyName: 'King William County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '103',
    countyName: 'Lancaster County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '105',
    countyName: 'Lee County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '107',
    countyName: 'Loudoun County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '109',
    countyName: 'Louisa County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '111',
    countyName: 'Lunenburg County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '113',
    countyName: 'Madison County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '115',
    countyName: 'Mathews County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '117',
    countyName: 'Mecklenburg County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '119',
    countyName: 'Middlesex County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '121',
    countyName: 'Montgomery County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '125',
    countyName: 'Nelson County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '127',
    countyName: 'New Kent County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '131',
    countyName: 'Northampton County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '133',
    countyName: 'Northumberland County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '135',
    countyName: 'Nottoway County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '137',
    countyName: 'Orange County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '139',
    countyName: 'Page County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '141',
    countyName: 'Patrick County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '143',
    countyName: 'Pittsylvania County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '145',
    countyName: 'Powhatan County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '147',
    countyName: 'Prince Edward County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '149',
    countyName: 'Prince George County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '153',
    countyName: 'Prince William County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '155',
    countyName: 'Pulaski County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '157',
    countyName: 'Rappahannock County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '159',
    countyName: 'Richmond County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '161',
    countyName: 'Roanoke County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '163',
    countyName: 'Rockbridge County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '165',
    countyName: 'Rockingham County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '167',
    countyName: 'Russell County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '169',
    countyName: 'Scott County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '171',
    countyName: 'Shenandoah County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '173',
    countyName: 'Smyth County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '175',
    countyName: 'Southampton County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '177',
    countyName: 'Spotsylvania County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '179',
    countyName: 'Stafford County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '181',
    countyName: 'Surry County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '183',
    countyName: 'Sussex County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '185',
    countyName: 'Tazewell County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '187',
    countyName: 'Warren County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '191',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '193',
    countyName: 'Westmoreland County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '195',
    countyName: 'Wise County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '197',
    countyName: 'Wythe County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '199',
    countyName: 'York County',
    num: 'H1',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '510',
    countyName: 'Alexandria city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '515',
    countyName: 'Bedford city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '520',
    countyName: 'Bristol city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '530',
    countyName: 'Buena Vista city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '540',
    countyName: 'Charlottesville city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '550',
    countyName: 'Chesapeake city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '570',
    countyName: 'Colonial Heights city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '580',
    countyName: 'Covington city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '590',
    countyName: 'Danville city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '595',
    countyName: 'Emporia city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '600',
    countyName: 'Fairfax city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '610',
    countyName: 'Falls Church city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '620',
    countyName: 'Franklin city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '630',
    countyName: 'Fredericksburg city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '640',
    countyName: 'Galax city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '650',
    countyName: 'Hampton city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '660',
    countyName: 'Harrisonburg city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '670',
    countyName: 'Hopewell city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '678',
    countyName: 'Lexington city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '680',
    countyName: 'Lynchburg city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '683',
    countyName: 'Manassas city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '685',
    countyName: 'Manassas Park city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '690',
    countyName: 'Martinsville city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '700',
    countyName: 'Newport News city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '710',
    countyName: 'Norfolk city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '720',
    countyName: 'Norton city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '730',
    countyName: 'Petersburg city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '735',
    countyName: 'Poquoson city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '740',
    countyName: 'Portsmouth city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '750',
    countyName: 'Radford city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '760',
    countyName: 'Richmond city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '770',
    countyName: 'Roanoke city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '775',
    countyName: 'Salem city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '790',
    countyName: 'Staunton city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '800',
    countyName: 'Suffolk city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '810',
    countyName: 'Virginia Beach city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '820',
    countyName: 'Waynesboro city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '830',
    countyName: 'Williamsburg city',
    num: 'C7',
  },
  {
    state: 'VA',
    stateFips: '51',
    countyFips: '840',
    countyName: 'Winchester city',
    num: 'C7',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '003',
    countyName: 'Asotin County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '005',
    countyName: 'Benton County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '007',
    countyName: 'Chelan County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '009',
    countyName: 'Clallam County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '011',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '013',
    countyName: 'Columbia County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '015',
    countyName: 'Cowlitz County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '017',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '019',
    countyName: 'Ferry County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '021',
    countyName: 'Franklin County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '023',
    countyName: 'Garfield County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '025',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '027',
    countyName: 'Grays Harbor County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '029',
    countyName: 'Island County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '031',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '033',
    countyName: 'King County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '035',
    countyName: 'Kitsap County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '037',
    countyName: 'Kittitas County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '039',
    countyName: 'Klickitat County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '041',
    countyName: 'Lewis County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '043',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '045',
    countyName: 'Mason County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '047',
    countyName: 'Okanogan County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '049',
    countyName: 'Pacific County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '051',
    countyName: 'Pend Oreille County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '053',
    countyName: 'Pierce County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '055',
    countyName: 'San Juan County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '057',
    countyName: 'Skagit County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '059',
    countyName: 'Skamania County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '061',
    countyName: 'Snohomish County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '063',
    countyName: 'Spokane County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '065',
    countyName: 'Stevens County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '067',
    countyName: 'Thurston County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '069',
    countyName: 'Wahkiakum County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '071',
    countyName: 'Walla Walla County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '073',
    countyName: 'Whatcom County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '075',
    countyName: 'Whitman County',
    num: 'H1',
  },
  {
    state: 'WA',
    stateFips: '53',
    countyFips: '077',
    countyName: 'Yakima County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '001',
    countyName: 'Barbour County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '003',
    countyName: 'Berkeley County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '005',
    countyName: 'Boone County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '007',
    countyName: 'Braxton County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '009',
    countyName: 'Brooke County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '011',
    countyName: 'Cabell County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '013',
    countyName: 'Calhoun County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '015',
    countyName: 'Clay County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '017',
    countyName: 'Doddridge County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '019',
    countyName: 'Fayette County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '021',
    countyName: 'Gilmer County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '023',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '025',
    countyName: 'Greenbrier County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '027',
    countyName: 'Hampshire County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '029',
    countyName: 'Hancock County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '031',
    countyName: 'Hardy County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '033',
    countyName: 'Harrison County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '035',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '037',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '039',
    countyName: 'Kanawha County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '041',
    countyName: 'Lewis County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '043',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '045',
    countyName: 'Logan County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '047',
    countyName: 'McDowell County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '049',
    countyName: 'Marion County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '051',
    countyName: 'Marshall County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '053',
    countyName: 'Mason County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '055',
    countyName: 'Mercer County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '057',
    countyName: 'Mineral County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '059',
    countyName: 'Mingo County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '061',
    countyName: 'Monongalia County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '063',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '065',
    countyName: 'Morgan County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '067',
    countyName: 'Nicholas County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '069',
    countyName: 'Ohio County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '071',
    countyName: 'Pendleton County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '073',
    countyName: 'Pleasants County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '075',
    countyName: 'Pocahontas County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '077',
    countyName: 'Preston County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '079',
    countyName: 'Putnam County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '081',
    countyName: 'Raleigh County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '083',
    countyName: 'Randolph County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '085',
    countyName: 'Ritchie County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '087',
    countyName: 'Roane County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '089',
    countyName: 'Summers County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '091',
    countyName: 'Taylor County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '093',
    countyName: 'Tucker County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '095',
    countyName: 'Tyler County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '097',
    countyName: 'Upshur County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '099',
    countyName: 'Wayne County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '101',
    countyName: 'Webster County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '103',
    countyName: 'Wetzel County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '105',
    countyName: 'Wirt County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '107',
    countyName: 'Wood County',
    num: 'H1',
  },
  {
    state: 'WV',
    stateFips: '54',
    countyFips: '109',
    countyName: 'Wyoming County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '001',
    countyName: 'Adams County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '003',
    countyName: 'Ashland County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '005',
    countyName: 'Barron County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '007',
    countyName: 'Bayfield County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '009',
    countyName: 'Brown County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '011',
    countyName: 'Buffalo County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '013',
    countyName: 'Burnett County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '015',
    countyName: 'Calumet County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '017',
    countyName: 'Chippewa County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '019',
    countyName: 'Clark County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '021',
    countyName: 'Columbia County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '023',
    countyName: 'Crawford County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '025',
    countyName: 'Dane County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '027',
    countyName: 'Dodge County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '029',
    countyName: 'Door County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '031',
    countyName: 'Douglas County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '033',
    countyName: 'Dunn County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '035',
    countyName: 'Eau Claire County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '037',
    countyName: 'Florence County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '039',
    countyName: 'Fond du Lac County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '041',
    countyName: 'Forest County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '043',
    countyName: 'Grant County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '045',
    countyName: 'Green County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '047',
    countyName: 'Green Lake County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '049',
    countyName: 'Iowa County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '051',
    countyName: 'Iron County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '053',
    countyName: 'Jackson County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '055',
    countyName: 'Jefferson County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '057',
    countyName: 'Juneau County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '059',
    countyName: 'Kenosha County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '061',
    countyName: 'Kewaunee County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '063',
    countyName: 'La Crosse County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '065',
    countyName: 'Lafayette County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '067',
    countyName: 'Langlade County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '069',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '071',
    countyName: 'Manitowoc County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '073',
    countyName: 'Marathon County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '075',
    countyName: 'Marinette County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '077',
    countyName: 'Marquette County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '078',
    countyName: 'Menominee County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '079',
    countyName: 'Milwaukee County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '081',
    countyName: 'Monroe County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '083',
    countyName: 'Oconto County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '085',
    countyName: 'Oneida County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '087',
    countyName: 'Outagamie County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '089',
    countyName: 'Ozaukee County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '091',
    countyName: 'Pepin County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '093',
    countyName: 'Pierce County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '095',
    countyName: 'Polk County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '097',
    countyName: 'Portage County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '099',
    countyName: 'Price County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '101',
    countyName: 'Racine County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '103',
    countyName: 'Richland County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '105',
    countyName: 'Rock County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '107',
    countyName: 'Rusk County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '109',
    countyName: 'St. Croix County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '111',
    countyName: 'Sauk County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '113',
    countyName: 'Sawyer County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '115',
    countyName: 'Shawano County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '117',
    countyName: 'Sheboygan County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '119',
    countyName: 'Taylor County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '121',
    countyName: 'Trempealeau County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '123',
    countyName: 'Vernon County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '125',
    countyName: 'Vilas County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '127',
    countyName: 'Walworth County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '129',
    countyName: 'Washburn County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '131',
    countyName: 'Washington County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '133',
    countyName: 'Waukesha County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '135',
    countyName: 'Waupaca County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '137',
    countyName: 'Waushara County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '139',
    countyName: 'Winnebago County',
    num: 'H1',
  },
  {
    state: 'WI',
    stateFips: '55',
    countyFips: '141',
    countyName: 'Wood County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '001',
    countyName: 'Albany County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '003',
    countyName: 'Big Horn County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '005',
    countyName: 'Campbell County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '007',
    countyName: 'Carbon County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '009',
    countyName: 'Converse County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '011',
    countyName: 'Crook County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '013',
    countyName: 'Fremont County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '015',
    countyName: 'Goshen County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '017',
    countyName: 'Hot Springs County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '019',
    countyName: 'Johnson County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '021',
    countyName: 'Laramie County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '023',
    countyName: 'Lincoln County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '025',
    countyName: 'Natrona County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '027',
    countyName: 'Niobrara County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '029',
    countyName: 'Park County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '031',
    countyName: 'Platte County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '033',
    countyName: 'Sheridan County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '035',
    countyName: 'Sublette County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '037',
    countyName: 'Sweetwater County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '039',
    countyName: 'Teton County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '041',
    countyName: 'Uinta County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '043',
    countyName: 'Washakie County',
    num: 'H1',
  },
  {
    state: 'WY',
    stateFips: '56',
    countyFips: '045',
    countyName: 'Weston County',
    num: 'H1',
  },
  {
    state: 'AS',
    stateFips: '60',
    countyFips: '010',
    countyName: 'Eastern District',
    num: 'H1',
  },
  {
    state: 'AS',
    stateFips: '60',
    countyFips: '020',
    countyName: "Manu'a District",
    num: 'H1',
  },
  {
    state: 'AS',
    stateFips: '60',
    countyFips: '030',
    countyName: 'Rose Island',
    num: 'H4',
  },
  {
    state: 'AS',
    stateFips: '60',
    countyFips: '040',
    countyName: 'Swains Island',
    num: 'H4',
  },
  {
    state: 'AS',
    stateFips: '60',
    countyFips: '050',
    countyName: 'Western District',
    num: 'H1',
  },
  {
    state: 'GU',
    stateFips: '66',
    countyFips: '010',
    countyName: 'Guam',
    num: 'H4',
  },
  {
    state: 'MP',
    stateFips: '69',
    countyFips: '085',
    countyName: 'Northern Islands Municipality',
    num: 'H1',
  },
  {
    state: 'MP',
    stateFips: '69',
    countyFips: '100',
    countyName: 'Rota Municipality',
    num: 'H1',
  },
  {
    state: 'MP',
    stateFips: '69',
    countyFips: '110',
    countyName: 'Saipan Municipality',
    num: 'H1',
  },
  {
    state: 'MP',
    stateFips: '69',
    countyFips: '120',
    countyName: 'Tinian Municipality',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '001',
    countyName: 'Adjuntas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '003',
    countyName: 'Aguada Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '005',
    countyName: 'Aguadilla Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '007',
    countyName: 'Aguas Buenas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '009',
    countyName: 'Aibonito Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '011',
    countyName: 'Anasco Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '013',
    countyName: 'Arecibo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '015',
    countyName: 'Arroyo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '017',
    countyName: 'Barceloneta Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '019',
    countyName: 'Barranquitas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '021',
    countyName: 'Bayamon Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '023',
    countyName: 'Cabo Rojo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '025',
    countyName: 'Caguas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '027',
    countyName: 'Camuy Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '029',
    countyName: 'Canovanas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '031',
    countyName: 'Carolina Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '033',
    countyName: 'Catano Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '035',
    countyName: 'Cayey Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '037',
    countyName: 'Ceiba Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '039',
    countyName: 'Ciales Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '041',
    countyName: 'Cidra Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '043',
    countyName: 'Coamo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '045',
    countyName: 'Comerio Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '047',
    countyName: 'Corozal Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '049',
    countyName: 'Culebra Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '051',
    countyName: 'Dorado Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '053',
    countyName: 'Fajardo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '054',
    countyName: 'Florida Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '055',
    countyName: 'Guanica Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '057',
    countyName: 'Guayama Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '059',
    countyName: 'Guayanilla Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '061',
    countyName: 'Guaynabo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '063',
    countyName: 'Gurabo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '065',
    countyName: 'Hatillo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '067',
    countyName: 'Hormigueros Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '069',
    countyName: 'Humacao Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '071',
    countyName: 'Isabela Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '073',
    countyName: 'Jayuya Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '075',
    countyName: 'Juana Diaz Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '077',
    countyName: 'Juncos Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '079',
    countyName: 'Lajas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '081',
    countyName: 'Lares Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '083',
    countyName: 'Las Marias Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '085',
    countyName: 'Las Piedras Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '087',
    countyName: 'Loiza Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '089',
    countyName: 'Luquillo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '091',
    countyName: 'Manati Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '093',
    countyName: 'Maricao Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '095',
    countyName: 'Maunabo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '097',
    countyName: 'Mayaguez Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '099',
    countyName: 'Moca Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '101',
    countyName: 'Morovis Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '103',
    countyName: 'Naguabo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '105',
    countyName: 'Naranjito Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '107',
    countyName: 'Orocovis Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '109',
    countyName: 'Patillas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '111',
    countyName: 'Penuelas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '113',
    countyName: 'Ponce Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '115',
    countyName: 'Quebradillas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '117',
    countyName: 'Rincon Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '119',
    countyName: 'Rio Grande Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '121',
    countyName: 'Sabana Grande Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '123',
    countyName: 'Salinas Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '125',
    countyName: 'San German Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '127',
    countyName: 'San Juan Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '129',
    countyName: 'San Lorenzo Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '131',
    countyName: 'San Sebastian Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '133',
    countyName: 'Santa Isabel Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '135',
    countyName: 'Toa Alta Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '137',
    countyName: 'Toa Baja Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '139',
    countyName: 'Trujillo Alto Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '141',
    countyName: 'Utuado Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '143',
    countyName: 'Vega Alta Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '145',
    countyName: 'Vega Baja Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '147',
    countyName: 'Vieques Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '149',
    countyName: 'Villalba Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '151',
    countyName: 'Yabucoa Municipio',
    num: 'H1',
  },
  {
    state: 'PR',
    stateFips: '72',
    countyFips: '153',
    countyName: 'Yauco Municipio',
    num: 'H1',
  },
  {
    state: 'UM',
    stateFips: '74',
    countyFips: '300',
    countyName: 'Midway Islands',
    num: 'H4',
  },
  {
    state: 'VI',
    stateFips: '78',
    countyFips: '010',
    countyName: 'St. Croix Island',
    num: 'H4',
  },
  {
    state: 'VI',
    stateFips: '78',
    countyFips: '020',
    countyName: 'St. John Island',
    num: 'H4',
  },
  {
    state: 'VI',
    stateFips: '78',
    countyFips: '030',
    countyName: 'St. Thomas Island',
    num: 'H4',
  },
]
