const _ = require('lodash')

const categoriesTree = (categories, parent = null) => {
  const tree = []
  const expandedKeys = []
  _.forEach(categories, (category) => {
    if (category.parent === parent) {
      const cat = {
        title: category.name,
        key: category.id,
        value: category.id,
        parent: category.parent,
        categoryData: category,
      }
      const findChilds = categoriesTree(categories, category.id)
      if (findChilds.length) {
        cat.children = findChilds
      }
      tree.push(cat)
    }
    expandedKeys.push(category.id)
  })
  return tree
}

const getExpandedKeys = (categories, parent = null) => {
  const expandedKeys = []
  _.forEach(categories, (category) => {
    if (category.parent === parent) {
      categoriesTree(categories, category.id)
    }
    expandedKeys.push(category.id)
  })
  return expandedKeys
}

export { categoriesTree, getExpandedKeys }
