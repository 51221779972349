import React, { useEffect, useState } from 'react'
import { Button, Form, InputNumber, Modal, notification, Skeleton } from 'antd'
import axiosRequest from '../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../helpers/axios-error.helper'

const PropertiesContractVariablesForm = ({ visible, setVisibility }) => {
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [data, setData] = useState({})
  const [fields, setFields] = useState([])

  useEffect(() => {
    if (visible) {
      setLoading(true)
      axiosRequest(
        'api/v1/listings/contract-prices-variables',
        'get',
        null,
        true,
      )
        .then((res) => {
          if (res.data.variables) {
            const newData = {}
            const newFields = []
            res.data.variables.forEach((variable) => {
              const newVal = variable.isCents
                ? variable.value / 100
                : variable.value
              newData[variable.key] = newVal
              newFields.push({ ...variable, value: newVal })
            })
            setData(newData)
            setFields(newFields)
          }
        })
        .catch((e) => {
          axiosErrorHelper(e)
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(true)
      setData({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = (values) => {
    setBtnLoading(true)
    const data = []
    fields.forEach((field) => {
      if (values[field.key] >= 0) {
        data.push({
          id: field.id,
          variableValue: values[field.key],
        })
      }
    })
    axiosRequest(
      `api/v1/listings/contract-prices-variables`,
      'post',
      { data },
      true,
    )
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setBtnLoading(false)
        setVisibility(false)
        notification.success({
          description:
            'Properties gross profit and potential profits will be updated using new variables. It may take up to 30 minutes.',
          message: 'Variables Changed',
        })
        notification.warn({
          message: 'IMPORTANT',
          description:
            'Please, do not change properties variables during update. It may cause unexpected issues!',
          duration: 10000,
        })
      })
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisibility(false)}
      title="Contract Price Variables"
      destroyOnClose
      footer={false}
      width={970}
      centered
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
          initialValues={data}
          className="row"
        >
          <Form.Item className="col-12 mb-0">
            <h4>Variables</h4>
          </Form.Item>
          {fields.map((field) => {
            if (field.group === 'Variables') {
              return (
                <Form.Item
                  name={field.key}
                  key={field.id}
                  label={field.label}
                  className="col-12 col-md-6"
                >
                  <InputNumber min={0} step={1} className="w-100" />
                </Form.Item>
              )
            }
            return null
          })}
          <Form.Item className="col-12 mb-0">
            <h4>States</h4>
          </Form.Item>
          {fields.map((field) => {
            if (field.group === 'States') {
              return (
                <Form.Item
                  name={field.key}
                  key={field.id}
                  label={field.label}
                  className="col-12 col-md-6"
                >
                  <InputNumber min={0} step={1} className="w-100" />
                </Form.Item>
              )
            }
            return null
          })}
          <Form.Item className="mb-0">
            <Button
              htmlType="submit"
              className="btn-sm btn-primary"
              loading={btnLoading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default PropertiesContractVariablesForm
