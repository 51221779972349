import _ from 'lodash'
import moment from 'moment'

const propertyData = {
  main: {
    name: '',
    company: null,
    parcelNumber: '',
    description: null,
    country: 'United States',
    stateRegion: null,
    county: null,
    city: null,
    street: null,
    zip: null,
    lat: null,
    lng: null,
    price: null,
    contractPrice: null,
    ownerFinancePrice: null,
    processingFee: 0,
    isOwnerFinance: false,
    isPublished: true,
    isSold: false,
    featured: false,
    showcase: false,
    expiration_date: null,
  },
  info: {
    taxes: null,
    zoning: null,
    utilities: null,
    deedType: null,
    roadAccess: null,
    legal: null,
    hoa: null,
    mobilesHomesAllowed: null,
    sizeAcres: null,
    sizeSqFt: null,
    taxCollectorOffice: null,
    propertyAppraiserOffice: null,
  },
  images: [],
  categories: [],
  websites: [],
}

const buildPropertyData = (property) => {
  let data = propertyData
  const categories = []
  const websites = []
  const images = []
  if (property.images && property.images.length > 0) {
    _.forEach(property.images, (image) => {
      images.push({
        ...image,
        isMain: image.property_image.isMain,
        id: image.id,
      })
    })
  }
  if (property.categories && property.categories.length > 0) {
    _.forEach(property.categories, (category) => {
      categories.push(parseInt(category.id, 10))
    })
  }
  if (property.websites && property.websites.length > 0) {
    _.forEach(property.websites, (website) => {
      websites.push(parseInt(website.id, 10))
    })
  }
  data = {
    ...data,
    main: {
      slug: property.slug,
      name: property.name,
      company: parseInt(property.company.id, 10),
      parcelNumber: property.parcelNumber,
      description: property.description,
      ownerFinanceTerms: property.ownerFinanceTerms,
      country: property.country,
      stateRegion: property.stateRegion,
      county: property.county,
      city: property.city,
      street: property.street,
      zip: property.zip,
      lat: property.lat,
      lng: property.lng,
      price: property.price / 100,
      contractPrice: property.contractPrice / 100,
      ownerFinancePrice: property.ownerFinancePrice / 100,
      processingFee: property.processingFee / 100,
      downPaymentPrice: property.downPaymentPrice
        ? property.downPaymentPrice / 100
        : null,
      isOwnerFinance: property.isOwnerFinance,
      isPublished: property.isPublished,
      isSold: property.isSold,
      featured: property.featured,
      showcase: property.showcase,
      sale_bonus: property.sale_bonus ? property.sale_bonus / 100 : null,
      linked_buildfortune: property.linked_buildfortune,
      priority: property.priority,
      expiration_date: property.expiration_date
        ? moment(property.expiration_date)
        : null,
    },
    info: property.info ? { ...property.info } : { ...data.info },
    categories,
    websites,
    images,
  }
  return data
}

export { propertyData, buildPropertyData }
