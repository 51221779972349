import DashboardPage from '../pages/acquisition/DashboardPage'
import AcquisitionsRequestsPage from '../pages/acquisition/Requests'
import AcquisitionSettings from '../pages/acquisition/Settings'
import LeadsPoolPage from '../pages/acquisition/data-tree/LeadsPoolPage'
import ParserPage from '../pages/acquisition/data-tree/ParserPage'

const acquisitionRoutes = [
  {
    path: '/acquisition/dashboard',
    Component: DashboardPage,
  },
  {
    path: '/acquisition/requests',
    Component: AcquisitionsRequestsPage,
  },
  {
    path: '/acquisition/leads-pool',
    Component: LeadsPoolPage,
  },
  {
    path: '/acquisition/data-parser',
    Component: ParserPage,
  },
  {
    path: '/acquisition/settings',
    Component: AcquisitionSettings,
  },
]

export default acquisitionRoutes
