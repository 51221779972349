import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import PageTitle from '../../components/ui/title/PageTitle'
import axiosRequest from '../../helpers/axios-request.helper'
import convertJsonToUrlParams from '../../helpers/convert-json-to-url-params'
import axiosErrorHelper from '../../helpers/axios-error.helper'
import { Select, Skeleton } from 'antd'

import styles from './dashboard-page.module.scss'
import DefaultCard from '../../components/ui/cards/DefaultCard'
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment'
import SentReceivedChart from '../../components/pages/acquisition/dashboard/SentReceivedChart'
import ManagersSentChart from '../../components/pages/acquisition/dashboard/ManagersSentChart'
import ProvidersChart from '../../components/pages/acquisition/dashboard/ProvidersChart'
import { Link } from 'react-router-dom'

const DashboardPage = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [filters, setFilters] = useState({
    period: 14,
  })

  const getData = () => {
    setLoading(true)
    axiosRequest(
      `api/v1/acquisition/data/admin/dashboard${convertJsonToUrlParams(
        filters,
      )}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          setData(res.data.data)
        }
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [filters])

  return (
    <>
      <Helmet title="Acquisition Dashboard" />
      <div className="container-fluid">
        <PageTitle
          title="Acquisition Dashboard"
          extraButtons={
            <>
              <Select
                value={filters.period}
                onChange={(val) => {
                  setFilters({ ...filters, period: val })
                }}
              >
                <Select.Option value={7}>7 Days</Select.Option>
                <Select.Option value={14}>14 Days</Select.Option>
                <Select.Option value={30}>30 Days</Select.Option>
                <Select.Option value={60}>60 Days</Select.Option>
                <Select.Option value={90}>90 Days</Select.Option>
              </Select>
            </>
          }
        />
        {loading || !data ? (
          <Skeleton active />
        ) : (
          <div className={styles.DashboardWrapper}>
            <div className={styles.Dashboard}>
              <div className={styles.stats}>
                <div className="row">
                  <div className="col-12 mb-4">
                    <DefaultCard title="CSV Uploads">
                      {data.uploads && (
                        <div
                          className={styles.CardList}
                          style={{ overflow: 'auto', height: '320px' }}
                        >
                          <PerfectScrollbar>
                            {data.uploads.map((upload) => (
                              <div
                                className={styles.ListItem}
                                key={`csv-uploads__${upload.id}`}
                              >
                                <div className={styles.Info}>
                                  <p className="mb-1">
                                    <Link
                                      to={`/acquisition/data-parser?uploaderFile=${upload.id}`}
                                    >
                                      {upload.name.substring(0, 18) + '...'}
                                    </Link>
                                  </p>
                                  <p className="mb-0">
                                    <small>
                                      <strong>
                                        {moment(upload.created_at).format(
                                          'MM/DD/YYYY HH:mm',
                                        )}{' '}
                                        (UTC)
                                      </strong>
                                    </small>
                                  </p>
                                </div>
                                <div className={`${styles.Info} text-start`}>
                                  {upload.totalListings
                                    ? `${upload.totalListings}`
                                    : '0'}{' '}
                                  Properties found
                                </div>
                                <div className={`${styles.Info} text-start`}>
                                  {upload.uploadedListings
                                    ? `${upload.uploadedListings}`
                                    : '0'}{' '}
                                  Properties processed
                                </div>
                                <div className={`${styles.Info} text-start`}>
                                  {upload.smsSent ? `${upload.smsSent}` : '0'}{' '}
                                  SMS sent
                                </div>
                                <div className={`${styles.Info} text-start`}>
                                  {upload.emailSent
                                    ? `${upload.emailSent}`
                                    : '0'}{' '}
                                  emails sent
                                </div>
                              </div>
                            ))}
                          </PerfectScrollbar>
                        </div>
                      )}
                    </DefaultCard>
                  </div>
                  <div className="col-12 mb-4">
                    <DefaultCard title="Sent/Received Emails">
                      <SentReceivedChart data={data.email_sent_received} />
                    </DefaultCard>
                  </div>
                  <div className="col-12 mb-4">
                    <DefaultCard title="Managers Emails Sent">
                      <ManagersSentChart
                        data={data.managers_sent}
                        type="email"
                      />
                    </DefaultCard>
                  </div>
                </div>
              </div>
              <div className={styles.performance}>
                <div className="row">
                  <div className="col-12 mb-4">
                    <DefaultCard title="Sent/Received SMS">
                      <SentReceivedChart data={data.sent_received} />
                    </DefaultCard>
                  </div>
                  <div className="col-12 mb-4">
                    <DefaultCard title="Managers SMS Sent">
                      <ManagersSentChart data={data.managers_sent} />
                    </DefaultCard>
                  </div>
                  <div className="col-12 mb-4">
                    <DefaultCard title="Providers Analytics">
                      <ProvidersChart data={data.providers} />
                    </DefaultCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DashboardPage
